import React from "react";
import styles from "./styles/analytics.module.css";

const Analytics = () => {
  return (
    <div className={styles.fullW}>
      <div className="flex flex-col justify-center items-center mt-12 bg-GrayBg px-2 md:px-16 py-12 rounded-3xl">
        <div className="mb-16">
          <p className="text-center text-2xl md:text-5xl font-bold my-2 font-serif">
            Counts that Inspire.
          </p>
          <p className="text-center font-regular text-base md:text-xs text-left">
          Aspire College has made a profound impact on students from diverse countries worldwide, providing inspiration and knowledge to the learners.
          </p>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 px-4">
        <div className="border-l-4 px-2 md:px-6 flex item-center flex-col justify-center">
            <p className="font-bold text-base md:text-4xl font-semibold">
              15,000+
            </p>
            <p className="font-medium text-sm md:text-base">Alumni Graduated</p>
          </div>
          <div className="border-l-4 px-2 md:px-6 flex item-center flex-col justify-center">
            <p className="font-bold text-base md:text-4xl font-semibold">50+</p>
            <p className="font-medium text-sm md:text-base">Courses Offered</p>
          </div>
          
          <div className="border-l-4 px-2 md:px-6 flex item-center flex-col justify-center md:col-span-2">
            <p className="font-bold text-base md:text-4xl font-semibold">40+</p>
            <p className="font-medium text-sm md:text-base">Trained Faculties</p>
          </div>
          <div className="border-l-4 px-2 md:px-6 flex item-center flex-col justify-center md:col-span-2">
            <p className="font-bold text-base md:text-4xl font-semibold">25+</p>
            <p className="font-medium text-sm md:text-base">Years of Service</p>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
