import React from "react"
import Button from "../components/Button";
import Template from "../components/Template"
import styles from "../components/styles/puccourse.module.css";
const PucCourse = () => {
    return (
        <Template>
            <div className='flex justify-center item-center bg-[url("/images/course-banner.png")] bg-cover'>
                <div className={styles.fullW}>
                    <div className="flex flex-row justify-between">
                        <div>
                            <p className="text-4xl font-bold font-serif mb-16">+2 or PUC Courses</p>
                            <p className="font-title font-bold text-justify text-wrap mb-4">Bachelors in Islamic Studies</p>
                            <p className="font-base text-justify text-wrap mb-4">Aspire offers you a Comprehensive high school program where Students can save their precious time. Instead of Spending 2 years after 10th, Students now have the option to Pass 12th or PUC in One year and pursue Undergraduate Degree Program at Aspire. They’ll start learning Arabic & the Deen earlier than the others. Contact us for more information.</p>
                            <Button label="Contact Us" onClick={() => {}} />
                        </div>
                    </div>

                </div>
            </div>
            
        </Template>
    )
}

export default PucCourse;