import { GET_BLOGS, SELECTED_BLOG } from "../actions/types"


const initialState = {
    blogs: [],
    selectedBlog: {
        title: "",
        description: "",
    }
}

const BlogReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_BLOGS:
            return Object.assign({}, state, {
                blogs: action.payload
            })
        case SELECTED_BLOG:
            return Object.assign({}, state, {
                selectedBlog: action.payload
            })
        default:
            return state
    }
}

export default BlogReducer