import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Button = (props) => {
    const { onClick, label, lms=false } = props
    return (
        <div className={` ${lms ? "w-full text-center" : "w-max flex flex-row justify-between items-center" }  bg-AspireBrandBlue rounded-3xl py-3 px-4  cursor-pointer`} onClick={() => onClick()}>
           
            <p className={`text-center text-xs text-White font-semibold`}>{label}</p>
        </div>
    )
}

export default Button