import React from "react";
import Template from "../components/Template";
import styles from "../components/styles/glance.module.css";

const Glance = () => {
  window.scrollTo(0, 0);
  return (
    <Template>
      
      <div className={styles.fullW}>
        <div >
          <p className="text-2xl font-serif font-bold">Aspire at a Glance</p>
          <p>
            Aspire is managed by the Aspire social educational trust - a
            non-profit organization that is committed towards establishing an
            educational institution that will inspire individuals to excel and
            be guided by a sense of responsibility towards the Creator HIS
            Creation
          </p>
        </div>
        <div className={`grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 ${styles.imageContent}`}>
          <div className="mr-2">
            <div className="my-4">
              <p className="font-bold text-xl">Vision</p>
              <p className="text-sm font-sans text-justify">
                We Endeavor to prepare the Aspirants to excel by providing them
                an educational foundation that builds character, right attitude
                nurtured values.
              </p>
            </div>
            <div className="my-4">
              <p className="font-bold text-xl">Mission</p>
              <p className="text-sm font-sans text-justify">
                We understand that as God-Fearing parents/students, you dream
                and desire the best for yourselves in every face of your
                precious lives. Driven solely by the passion to impart an
                education for a lifetime which shapes minds and nurtures souls,
                we share & strive to achieve your life's vision with the
                Islamic principles.
              </p>
              <ul className="list-disc pl-5">
                                <li className="text-base text-justify">Our founder Shaikh Abdus Salam Madani's quest to find the ideal way of directing people to the right path, culminated in an initiative to invest his time & resources towards the establishment progressive & development of ACE.</li>
                                <li className="text-base text-justify">In a short span Aspire college of excellence has widened its scope, changed the conventional norms of teaching & brought about a truly interactive system of learning.</li>
                                <li className="text-base text-justify">It has remained true to its core strengths -scholars who inspire students to stay steadfast in the fast-changing world of today</li>
                                <li className="text-base text-justify">It began in the Aspire educational & charitable trust, a pioneering group led by Shaikh Abdus Salam Madani, who first conceived the idea and developed it into reality.</li>
                                <li className="text-base text-justify">The college offers academic excellence, Positive social values, creative freedom & self-discovery that equips every student with necessary skill sets to take his place anywhere in the world. </li>
                                <li className="text-base text-justify">At Aspire, students have experience beyond books & academics. Excursions, public speaking, creative writing , work exposure , social awareness activities are all part of a holistic education that enables students to develop into confident adults who are equipped & ready to meet the challenges of a rapidly changing and demanding world , in keeping with the shariah.</li>
                            </ul>
            </div>
          </div>
          <div className="rounded-3xl">
            <img
              className={`rounded-2xl w-full ${styles.image}`}
              src={`${process.env.PUBLIC_URL}/images/Banners-2.jpg`}
              alt="Glance"
            />
          </div>
        </div>
      </div>
    </Template>
  );
};

export default Glance;
