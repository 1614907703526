import {
  faEnvelope,
  faInbox,
  faMailBulk,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import AdmissionForm from "./AdmissionForm";
import ContactUs from "./ContactUs";
import Footer from "./Footer";
import ForgotPassword from "./ForgotPassword";
import Header from "./Header";
import Login from "./Login";
import Menu from "./Menu";
import Signup from "./Signup";
const Template = (props) => {
  const [showContactUs, setShowContactUs] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showAdmission, setShowAdmission] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const { children } = props;
  return (
    <>
      <Header setShowLogin={setShowLogin} setShowMenu={setShowMenu} />
      <div
        style={{ bottom: "20px" }}
        className="flex fixed justify-between items-center bg-AspireBrandBlue rounded-full py-3 px-4  cursor-pointer  right-[10px]  z-[100]"
      >
        <FontAwesomeIcon
          icon={faEnvelope}
          className="text-White"
          onClick={() => {
            setShowContactUs(true);
          }}
        />
      </div>
      <div className="mx-auto">{children}</div>
      <Footer setShowLogin={setShowLogin} setShowAdmission={setShowAdmission} />
      <div
        className={`fixed ${
          showContactUs ? "" : "hidden"
        } inset-0 bg-Black bg-opacity-50 overflow-y-auto h-full w-full`}
      >
        <div className="flex justify-center my-24 md:my-28">
          <ContactUs setShowContactUs={setShowContactUs} />
        </div>
      </div>
      <div
        className={`fixed ${
          showAdmission ? "" : "hidden"
        } inset-0 bg-Black bg-opacity-50 overflow-y-auto h-full w-full`}
      >
        <div className="flex justify-center my-24 md:my-28">
          {/* <AdmissionForm setShowAdmission={setShowAdmission} /> */}
        </div>
      </div>
      <div
        className={`fixed ${
          showLogin ? "" : "hidden"
        } inset-0 bg-Black bg-opacity-50 overflow-y-auto h-full w-full`}
      >
        <div className="flex justify-center my-24 md:my-28">
          <Login
            setShowLogin={setShowLogin}
            setShowForgotPassword={setShowForgotPassword}
            setShowSignup={setShowSignup}
          />
        </div>
      </div>
      <div
        className={`fixed ${
          showSignup ? "" : "hidden"
        } inset-0 bg-Black bg-opacity-50 overflow-y-auto h-full w-full`}
      >
        <div className="flex justify-center my-24 md:my-28">
          <Signup setShowSignup={setShowSignup} setShowLogin={setShowLogin} />
        </div>
      </div>
      <div
        className={`fixed ${
          showForgotPassword ? "" : "hidden"
        } inset-0 bg-Black bg-opacity-50 overflow-y-auto h-full w-full`}
      >
        <div className="flex justify-center my-24 md:my-28">
          <ForgotPassword setShowForgotPassword={setShowForgotPassword} />
        </div>
      </div>
      <div
        className={`fixed ${
          showMenu ? "" : "hidden"
        } z-[1000] bg-repeat-y inset-0 bg-Black bg-opacity-50 overflow-y-auto h-full w-full bg-[url("/images/banner.png")] bg-cover'`}
      >
        <Menu
          setShowMenu={setShowMenu}
          setShowAdmission={setShowAdmission}
          setShowLogin={setShowLogin}
          setShowContactUs={setShowContactUs}
        />
      </div>
    </>
  );
};

export default Template;
