import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import CommunitiyDetail from "../pages/CommunityDetail";
import AllCourse from "../pages/AllCourse";
import CourseDetail from "../pages/CourseDetail";
import CoursePageOne from "../pages/CoursePageOne";

import Home from "../pages/Home";
import AllEvents from "../pages/AllEvents";
import Shop from "../pages/Shop";
import ProductDetail from "../pages/ProductDetail";
import Blogs from "../pages/Blogs";
import BlogDetail from "../pages/BlogDetail";
import PucCourse from "../pages/PucCourse";
import Scholarship from "../pages/Scholarship";
import Glance from "../pages/Glance";
import Chairman from "../pages/Chairman";
import Leadership from "../pages/Leadership";
import Faculties from "../pages/Faculties";
import AspireCommunity from "../pages/AspireCommunity";
import Gallery from "../pages/Gallery";
import Community from "../pages/Community";
import CourseProgress from "../pages/CourseProgress";
import Login from "../components/Login";
import LmsLogin from "../pages/LmsLogin";
import Lms from "../pages/Lms";
import TermsConditions from "../pages/TermsConditions";

import AdmissionFormMain from "../pages/AdmissionFormMain";
import EnquiryForm from "../pages/EnquiryForm";
import { SalamCentre } from "../pages/SalamCentre";
import Magazines from "../pages/Magazines";
import Alumni from "../pages/Alumni";
import Testimonials from "../pages/Testimonials";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import  WPower  from "../pages/WPower";

const RootRouter = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/courses",
    element: <AllCourse />,
  },
  {
    path: "/course/:id",
    element: <CourseDetail />,
  },
  {
    path: "/community",
    element: <Community />,
  },
  {
    path: "/community/:id",
    element: <CommunitiyDetail />,
  },
  {
    path: "/events",
    element: <AllEvents />,
  },
  {
    path: "/shop",
    element: <Shop />,
  },
  {
    path: "/product/:id",
    element: <ProductDetail />,
  },
  {
    path: "/blogs",
    element: <Blogs />,
  },
  {
    path: "/blog/:id",
    element: <BlogDetail />,
  },
  {
    path: "/puccourse",
    element: <PucCourse />,
  },
  {
    path: "/Scholarship",
    element: <Scholarship />,
  },
  {
    path: "/glance",
    element: <Glance />,
  },
  {
    path: "/chairman",
    element: <Chairman />,
  },
  {
    path: "/leadership",
    element: <Leadership />,
  },
  {
    path: "/faculties",
    element: <Faculties />,
  },
  {
    path: "/aspirecommunity",
    element: <AspireCommunity />,
  },
  {
    path: "/gallery",
    element: <Gallery />,
  },
  {
    path: "/course/progress",
    element: <CourseProgress />,
  },
  {
    path: "/lms/login",
    element: <LmsLogin />,
  },
  {
    path: "/lms/:id",
    element: <Lms />,
  },
  {
    path: "/admission-form",
    element: <AdmissionFormMain />,
  },
  {
    path: "/enquiry-form",
    element: <EnquiryForm />,
  },
  {
    path: "/salam-centre-franchise",
    element: <SalamCentre />,
  },
  {
    path: "/terms-conditions",
    element: <TermsConditions />,
  },
  {
    path: "/magazines",
    element: <Magazines />,
  },
  {
    path: "/alumni",
    element: <Alumni />,
  },
  {
    path: "/testimonials",
    element: <Testimonials />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/w-power",
    element: <WPower />,
  },
]);

export default RootRouter;
