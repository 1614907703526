import React, { useState } from "react";
import Button from "../components/Button";
import CourseCard from "../components/CourseCard";
import Template from "../components/Template";
import { useSelector } from "react-redux";
import styles from "../components/styles/Allcourse.module.css";
import Courses from "../components/Courses";
import coursessImage from "../static/images/courses.png";

const AllCourse = () => {
  window.scrollTo(0, 0);
  const [active, setActive] = useState("All Courses");
  const courses = useSelector((state) => state.Courses.courses);
  const purchsedCourse = useSelector((state) => state.LMS.courses);
  const courseData = courses.map((course) => {
    return {
      id: course.id,
      title: course.title,
      duration: "36 Months (3 Yrs)",
      location: "Chennai | Bengaluru",
      language: "",
      offer: "Financial Support",
      image: "coursessImage",
    };
  });
  return (
    <Template>
      <div className='flex justify-center item-center bg-[url("/images/course-banner.png")] bg-cover'>
        <div className={styles.fullW}>
          <div className="flex flex-row justify-between">
            <div className="w-full md:w-2/5">
              <p className="text-4xl font-bold font-serif mb-16">All Courses</p>
              <p className="font-title text-justify text-wrap">
                Experience a world of learning at Aspire College of Excellence.
                From Bachelor to Master's in Islamic Studies, explore the depths
                of Tawheed, Quranic Sciences, Shariah Law, and Comparative
                Religion. Dive into the complexities of the human mind with a
                B.Sc. in Psychology, integrating Islamic values. High school
                students can fast-track to PUC or 12th Std in just one year. We
                also offer Diploma Programs and Free courses, meticulously
                crafted to provide in-depth knowledge and practical skills in
                specific subjects. Join us on a journey of knowledge and growth.
              </p>
              <div className="mt-16">
                {/* <Button label="Explore Courses" onClick={() => {}} /> */}
              </div>
            </div>
            <div className="w-2/5 hidden md:block">
              <img
                className="rounded-2xl "
                src={`${process.env.PUBLIC_URL}/images/courses.png`}
              />
            </div>
          </div>
        </div>
      </div>
      <Courses />
    </Template>
  );
};

export default AllCourse;
