import Api from '../../lib/api'
const { AUTH_SUCCESS, AUTH_FAILURE, REGISTER_SUCCESS, REGISTER_FAILURE, LOGOUT } = require("./types");

export const authenticate = (body) => async dispatch => {
    try {
        const response = await Api.post(body, '/auth/user/login');
        if (response.success) {
            dispatch({
                type: AUTH_SUCCESS,
                payload: response.data
            })
        } else {
            dispatch({
                type: AUTH_FAILURE,
                payload: response.data
            })
        }
    } catch (e) {
        dispatch({
            type: AUTH_FAILURE,
            payload: e.message
        })
    }

}

export const doSignup = (body, cb) => async dispatch => {
    try {
        const response = await Api.post(body, '/auth/user/signup');
        if (response.success) {
            dispatch({
                type: REGISTER_SUCCESS,
                payload: response.data
            })
            cb();
        } else {
            dispatch({
                type: REGISTER_FAILURE,
                payload: response.data
            })
        }
    } catch (e) {
        dispatch({
            type: REGISTER_FAILURE,
            payload: e.message
        })
    }

}

export const doLogout = () => dispatch =>{
    dispatch({
        type: LOGOUT,
    })
}