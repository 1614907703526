import React from "react";
import Events from "../components/Events";
import Template from "../components/Template";
import styles from "../components/styles/Leadership.module.css";
const Leadership = () => {
    window.scrollTo(0, 0);
    return (
        <Template>
            <div className={styles.fullW}>
                <div className="mr-8">
                    <p className="text-2xl font-serif font-bold">Aspire Leadership</p>
                    <div className="grid gap-4">
                        <div className="mt-8 ">
                            <p className="font-bold text-lg">Message from the chairman</p>
                            <p className="text-base text-justify">
                                Our forward-looking leadership team is made up of dedicated, focused and experienced individuals. Their knowledge and experience come together to make a difference for all our students & parents.
                            </p><br/>
                            <p className="text-base text-justify">
                                Aspire is managed by the Aspire social & educational trust - a non- profit organization that is committed towards establishing an educational institution that will inspire individuals to excel and be guided by a sense of responsibility towards the Creator & HIS Creation
                            </p>
                        </div>
                        <div className="hidden md:block">
                            <h3 className="text-center text-2xl font-bold">Office Bearers</h3>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                            <div className="bg-GrayBg p-3 grid grid-cols-2 md:col-span-1 md:grid-cols-1 flex justify-between md:justify-center item-center mt-8 rounded-xl -fit">
                                <img src={`${process.env.PUBLIC_URL}/images/chairman.jpg`} className={"w-[100px] h-[100px] md:w-[280px] md:h-[280px]"} />
                                <div>
                                    <p className="text-base font-bold text-left md:text-center">Shk. Abdus Salam Al Madani</p>
                                    <p className="text-small text-lightText text-left md:text-center">Chairman</p>
                                    <p className="text-small text-lightText text-left md:text-center">Aspire social and Educational Trust</p>
                                </div>
                            </div>
                            <div className="bg-GrayBg p-3 grid grid-cols-2 md:col-span-1 md:grid-cols-1 flex justify-between md:justify-center item-center mt-8 rounded-xl -fit">
                                <img src={`${process.env.PUBLIC_URL}/images/chairman.jpg`} className={"w-[100px] h-[100px] md:w-[280px] md:h-[280px]"} />
                                <div>
                                    <p className="text-base font-bold text-left md:text-center">Mr. Farzan Moulana</p>
                                    <p className="text-small text-lightText text-left md:text-center">Managing Trustee & Secretary, ASET. </p>
                                    <p className="text-small text-lightText text-left md:text-center">Managing Director, ACPEOPLE Pvt. Ltd</p>
                                </div>
                            </div>
                            <div className="bg-GrayBg p-3 grid grid-cols-2 md:col-span-1 md:grid-cols-1 flex justify-between md:justify-center item-center mt-8 rounded-xl -fit">
                                <img src={`${process.env.PUBLIC_URL}/images/chairman.jpg`} className={"w-[100px] h-[100px] md:w-[280px] md:h-[280px]"} />
                                <div>
                                    <p className="text-base font-bold text-left md:text-center">Mohamed Farhan</p>
                                    <p className="text-small text-lightText text-left md:text-center">Treasurer, ASET. Managing Director, </p>
                                    <p className="text-small text-lightText text-left md:text-center">EverStone Minerals LLP. ,Chennai</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Template>
    )
}

export default Leadership;