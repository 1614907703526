import { faEnvelope, faInbox, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Button from "./Button";
import TextInput from "./TextInput";

const ForgotPassword = (props) => {


    return (
        <div className="bg-GrayBg  rounded-2xl w-full md:w-2/5 p-4 fixed z-[120px]">
            <div className="grid grid-cols-4">
                <div className="cursor-pointer col-span-3">
                    <img src={`${process.env.PUBLIC_URL}/images/logo-dark.png`} style={{ width: '150px' }} />
                </div>
                <div className="cursor-pointer col-span-1" onClick={() => props.setShowForgotPassword(false)}>
                    <p className="text-right">X</p>
                </div>
            </div>
            <div className="my-2">
                <p className="text-xl font-bold font-serif my-2">Forgot Password</p>
                <div>
                    <TextInput name="email" label="Email" />
                </div>

            </div>
            <div className="flex flex-row w-full justify-center items-center  my-2">
                <div className="flex justify-center items-center">
                    <Button label="Recover Password" onClick={() => { }} />
                </div>
            </div>
        </div>
    )

}

export default ForgotPassword