import React, { useState, useEffect, useRef } from "react";
import styles from "./styles/comunities.module.css";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import image1 from "../static/images/salam-centre.jpg";
import image2 from "../static/images/wpower.png";
import image3 from "../static/images/forum.jpg";
import image4 from "../static/images/crowd.jpg";
import image5 from "../static/images/magazines-thumb.jpg";

const ImageCard = ({ imageUrl, title, externalUrl }) => {
  const navigate = useNavigate();

  const openExternalUrlInNewTab = () => {
    window.open(externalUrl, "_blank");
  };

  return (
    <div
      className="w-1/5 p-2 rounded-xl overflow-hidden hover-shadow"
      onClick={(e) => {
        e.preventDefault();
        openExternalUrlInNewTab();
      }}
    >
      <a href="#">
        <img src={imageUrl} alt={title} className="w-full h-auto rounded-xl" />
      </a>
      <div className="mt-2 text-center">
        <p className="text-lg font-semibold">{title}</p>
      </div>
    </div>
  );
};

const Communities = () => {
  const [isMobileView, setIsMobileView] = useState(
    window.innerWidth <= 768 // Adjust the breakpoint as needed
  );
  const [currentSlide, setCurrentSlide] = useState(0);


  const imageCards = [
    {
      imageUrl: image1,
      title: "Aspire Salam Centre Franchise",
      externalUrl: "/salam-centre-franchise",
    },
    {
      imageUrl: image2,
      title: "W Power",
      externalUrl: "/w-power",
    },
    {
      imageUrl: image3,
      title: "Forum",
      externalUrl: "https://lms.aspirecollege.edu.in/forums",
    },
    // {
    //   imageUrl: image4,
    //   title: "Crowd Funding",
    //   externalUrl: "#",
    // },
    {
      imageUrl: image5,
      title: "Magazines",
      externalUrl: "/magazines",
    },
  ];

  const navigate = useNavigate();
  const carouselRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Function to advance the carousel to the next slide
  const autoSlide = () => {
    if (carouselRef.current) {
      const nextSlide = (currentSlide + 1) % imageCards.length;
      setCurrentSlide(nextSlide);
      carouselRef.current.select(nextSlide);
    }
  };

   useEffect(() => {
    if (isMobileView) {
      // Set an interval to auto slide the carousel every 2 seconds in mobile view
      const interval = setInterval(autoSlide, 2000);

      return () => {
        // Clear the interval when the component unmounts or when not in mobile view
        clearInterval(interval);
      };
    }
  }, [isMobileView, currentSlide]);

  return (
    <div className={styles.fullW} style={{ padding: "50px 0" }}>
      <div>
        <p className="text-2xl font-serif font-bold">Aspire Community</p>
      </div>
      {isMobileView ? (
        <Carousel showThumbs={false} showStatus={false} ref={carouselRef}>
          {imageCards.map((card, index) => (
            <ImageCard
              key={index}
              imageUrl={card.imageUrl}
              title={card.title}
              externalUrl={card.externalUrl}
            />)
          )}
        </Carousel>
      ) : (
        <div className="flex justify-center">
          {imageCards.map((card, index) => (
            <ImageCard
              key={index}
              imageUrl={card.imageUrl}
              title={card.title}
              externalUrl={card.externalUrl}
            />)
          )}
        </div>
      )}
    </div>
  );
};

export default Communities;
