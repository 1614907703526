import React, { useState, useCallback } from "react";
import Template from "../components/Template";

import YEAR22A from "../static/images/January 2022.jpg";
import YEAR22B from "../static/images/February 2022.jpg";
import YEAR22C from "../static/images/March 2022.jpg";
import YEAR22D from "../static/images/April 2022.jpg";
import YEAR22E from "../static/images/May 2022.jpg";
import YEAR22F from "../static/images/June 2022.jpg";
import YEAR22G from "../static/images/July 2022.jpg";
import YEAR22H from "../static/images/August 2022.jpg";
import YEAR22I from "../static/images/September 2022.jpg";
import YEAR22J from "../static/images/October 2022.jpg";
import YEAR22K from "../static/images/November 2022.jpg";
import YEAR22L from "../static/images/December 2022.jpg";


import YEAR23A from "../static/images/January 2023.jpg";
import YEAR23B from "../static/images/February 2023.jpg";
import YEAR23C from "../static/images/March 2023.jpg";
import YEAR23D from "../static/images/April 2023.jpg";
import YEAR23E from "../static/images/May 2023.jpg";
import YEAR23F from "../static/images/June 2023.jpg";
import YEAR23G from "../static/images/July 2023.jpg";
import YEAR23H from "../static/images/August 2023.jpg";
import YEAR23I from "../static/images/September 2023.jpg";
import YEAR23J from "../static/images/October 2023.jpg";
// import YEAR23K from "../static/images/November 2023.jpg";
// import YEAR23L from "../static/images/December 2023.jpg";




import PDF_YEAR22A from "../static/pdf/magazines/Aspire-ations-January-2022.pdf";
import PDF_YEAR22B from "../static/pdf/magazines/Aspire-ations February 2022.pdf";
import PDF_YEAR22C from "../static/pdf/magazines/Aspire-ations March 2022.pdf";
import PDF_YEAR22D from "../static/pdf/magazines/Aspire-ations April 2022.pdf";
import PDF_YEAR22E from "../static/pdf/magazines/Aspire-ations May 2022.pdf";
import PDF_YEAR22F from "../static/pdf/magazines/Aspire-ations June 2022.pdf";
import PDF_YEAR22G from "../static/pdf/magazines/Aspire-ations July 2022.pdf";
import PDF_YEAR22H from "../static/pdf/magazines/Aspire-ations August 2022.pdf";
import PDF_YEAR22I from "../static/pdf/magazines/Aspire-ations September 2022.pdf";
import PDF_YEAR22J from "../static/pdf/magazines/Aspire-ations October 2022.pdf";
import PDF_YEAR22K from "../static/pdf/magazines/Aspire-ations November 2022.pdf";
import PDF_YEAR22L from "../static/pdf/magazines/Aspire-ations December 2022.pdf";


import PDF_YEAR23A from "../static/pdf/magazines/2023/Aspire-ations-January-2023.pdf";
import PDF_YEAR23B from "../static/pdf/magazines/2023/Aspire-ations February 2023.pdf";
import PDF_YEAR23C from "../static/pdf/magazines/2023/Aspire-ations March 2023.pdf";
import PDF_YEAR23D from "../static/pdf/magazines/2023/Aspire-ations April 2023.pdf";
import PDF_YEAR23E from "../static/pdf/magazines/2023/Aspire-ations May 2023.pdf";
import PDF_YEAR23F from "../static/pdf/magazines/2023/Aspire-ations June 2023.pdf";
import PDF_YEAR23G from "../static/pdf/magazines/2023/Aspire-ations July 2023.pdf";
import PDF_YEAR23H from "../static/pdf/magazines/2023/Aspire-ations August 2023.pdf";
import PDF_YEAR23I from "../static/pdf/magazines/2023/Aspire-ations September 2023.pdf";
import PDF_YEAR23J from "../static/pdf/magazines/2023/Aspire-ations October 2023.pdf";
// import PDF_YEAR23K from "../static/pdf/magazines/2023/Aspire-ations November 2023.pdf";
// import PDF_YEAR23L from "../static/pdf/magazines/2023/Aspire-ations December 2023.pdf";




const Magazines = () => {
  const [selectedYear, setSelectedYear] = useState(2022);
  window.scrollTo(0, 0);
  const imagesAndPDFs = [
    {
      imageSrc: YEAR22A,
      pdfSrc: "https://aspirestatic.s3.ap-south-1.amazonaws.com/Aspire-ations-January-2022.pdf",
      alt: "Image 1",
    },
    {
      imageSrc: YEAR22B,
      pdfSrc: "https://aspirestatic.s3.ap-south-1.amazonaws.com/Aspire-ations+February+2022.pdf",
      alt: "Image 2",
    },
    {
      imageSrc: YEAR22C,
      pdfSrc: "https://aspirestatic.s3.ap-south-1.amazonaws.com/Aspire-ations+March+2022.pdf",
      alt: "Image 3",
    },
    {
      imageSrc: YEAR22D,
      pdfSrc: "https://aspirestatic.s3.ap-south-1.amazonaws.com/Aspire-ations+April+2022.pdf",
      alt: "Image 4",
    },
    {
      imageSrc: YEAR22E,
      pdfSrc: "https://aspirestatic.s3.ap-south-1.amazonaws.com/Aspire-ations+May+2022.pdf",
      alt: "Image 5",
    },
    {
      imageSrc: YEAR22F,
      pdfSrc: "https://aspirestatic.s3.ap-south-1.amazonaws.com/Aspire-ations+June+2022.pdf",
      alt: "Image 6",
    },
    {
      imageSrc: YEAR22G,
      pdfSrc: "https://aspirestatic.s3.ap-south-1.amazonaws.com/Aspire-ations+July+2022.pdf",
      alt: "Image 7",
    },
    {
      imageSrc: YEAR22H,
      pdfSrc: "https://aspirestatic.s3.ap-south-1.amazonaws.com/Aspire-ations+August+2022.pdf",
      alt: "Image 8",
    },
    {
      imageSrc: YEAR22I,
      pdfSrc: "https://aspirestatic.s3.ap-south-1.amazonaws.com/Aspire-ations+September+2022.pdf",
      alt: "Image 9",
    },
    {
      imageSrc: YEAR22J,
      pdfSrc: "https://aspirestatic.s3.ap-south-1.amazonaws.com/Aspire-ations+October+2022.pdf",
      alt: "Image 10",
    },
    {
      imageSrc: YEAR22K,
      pdfSrc: "https://aspirestatic.s3.ap-south-1.amazonaws.com/Aspire-ations+November+2022.pdf",
      alt: "Image 11",
    },
    {
      imageSrc: YEAR22L,
      pdfSrc: "https://aspirestatic.s3.ap-south-1.amazonaws.com/Aspire-ations+December+2022.pdf",
      alt: "Image 12",
    },



    {
      imageSrc: YEAR23A,
      pdfSrc: PDF_YEAR23A,
      alt: "Image 1",
    },
    {
      imageSrc: YEAR23B,
      pdfSrc: "https://aspirestatic.s3.ap-south-1.amazonaws.com/2023/Aspire-ations+February+2023.pdf",
      alt: "Image 2",
    },
    {
      imageSrc: YEAR23C,
      pdfSrc: "https://aspirestatic.s3.ap-south-1.amazonaws.com/2023/Aspire-ations+March+2023.pdf",
      alt: "Image 3",
    },
    {
      imageSrc: YEAR23D,
      pdfSrc: "https://aspirestatic.s3.ap-south-1.amazonaws.com/2023/Aspire-ations+April+2023.pdf",
      alt: "Image 4",
    },
    {
      imageSrc: YEAR23E,
      pdfSrc: "https://aspirestatic.s3.ap-south-1.amazonaws.com/2023/Aspire-ations+May+2023.pdf",
      alt: "Image 5",
    },
    {
      imageSrc: YEAR23F,
      pdfSrc: "https://aspirestatic.s3.ap-south-1.amazonaws.com/2023/Aspire-ations+June+2023.pdf",
      alt: "Image 6",
    },
    {
      imageSrc: YEAR23G,
      pdfSrc: "https://aspirestatic.s3.ap-south-1.amazonaws.com/2023/Aspire-ations+July+2023.pdf",
      alt: "Image 7",
    },
    {
      imageSrc: YEAR23H,
      pdfSrc: "https://aspirestatic.s3.ap-south-1.amazonaws.com/2023/Aspire-ations+August+2023.pdf",
      alt: "Image 8",
    },
    {
      imageSrc: YEAR23I,
      pdfSrc: "https://aspirestatic.s3.ap-south-1.amazonaws.com/2023/Aspire-ations+September+2023.pdf",
      alt: "Image 9",
    },
    {
      imageSrc: YEAR23J,
      pdfSrc: "https://aspirestatic.s3.ap-south-1.amazonaws.com/2023/Aspire-ations+October+2023.pdf",
      alt: "Image 10",
    },
   
    
  ];
  

  const openPdfInNewTab = (pdfSrc) => {
    window.open(pdfSrc, "_blank");
  };

  const filteredImagesAndPDFs = imagesAndPDFs.filter((item) => {
    const year = item.pdfSrc.includes(selectedYear.toString());
    return year;
  });

  return (
    <Template>
      <div className="container mx-auto p-6 mb-10">
        <h1 className="font-bold text-2xl text-center mt-10">Aspire Magazines</h1>

        <div className="text-center mt-4">
          <button
            className={`mr-4 p-2 rounded-lg ${
              selectedYear === 2022 ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-600"
            }`}
            onClick={() => setSelectedYear(2022)}
          >
            2022
          </button>
          <button
            className={`p-2 rounded-lg ${
              selectedYear === 2023 ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-600"
            }`}
            onClick={() => setSelectedYear(2023)}
          >
            2023
          </button>
        </div>

        <div className="grid grid-cols-4 gap-4 mt-6">
          {filteredImagesAndPDFs.map((item, index) => (
            <div
              key={index}
              className="cursor-pointer"
              onClick={() => openPdfInNewTab(item.pdfSrc)}
            >
              <img src={item.imageSrc} alt={item.alt} className="w-350 h-350 object-cover" />
            </div>
          ))}
        </div>
      </div>
    </Template>
  );
};

export default Magazines;






