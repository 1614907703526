const { AUTH_SUCCESS, AUTH_FAILURE, UPDATE_AUTH_TOKEN, REGISTER_FAILURE, REGISTER_SUCCESS, LOGOUT } = require("../actions/types")

const initialState = {
    accessToken: "",
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    authError: "",
    registerError: ""
}

const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_SUCCESS:
            return Object.assign({}, state, {
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                email: action.payload.email,
                mobileNumber: action.payload.mobileNumber,
                authError: "",
                registerError: "",
            })
        case AUTH_FAILURE:
            return Object.assign({}, state, {
                authError: action.payload.error || action.payload.message,
            })
        case REGISTER_SUCCESS:
            return Object.assign({}, state, {
                authError: "",
                registerError: ""
            })
        case REGISTER_FAILURE:
            return Object.assign({}, state, {
                registerError: action.payload.error || action.payload.message,
            })
        case UPDATE_AUTH_TOKEN:
            return Object.assign({}, state, {
                accessToken: action.payload.accessToken,
            })
        case LOGOUT:
            return Object.assign({}, state, {
                accessToken: "",
            })
        default:
            return state
    }
}

export default AuthReducer