import React, { useState, useCallback } from "react";
import Template from "../components/Template";
import ImageViewer from "react-simple-image-viewer";

import Testimonials1 from "../static/images/testimonials/testimonials_1.jpg";
import Testimonials2 from "../static/images/testimonials/testimonials_2.jpg";
import Testimonials3 from "../static/images/testimonials/testimonials_3.jpg";
import Testimonials4 from "../static/images/testimonials/testimonials_4.jpg";
import Testimonials5 from "../static/images/testimonials/testimonials_5.jpg";
import Testimonials6 from "../static/images/testimonials/testimonials_6.jpg";
import Testimonials7 from "../static/images/testimonials/testimonials_7.jpg";
import Testimonials8 from "../static/images/testimonials/testimonials_8.jpg";
import Testimonials9 from "../static/images/testimonials/testimonials_9.jpg";
import Testimonials10 from "../static/images/testimonials/testimonials_10.jpg";
import Testimonials11 from "../static/images/testimonials/testimonials_11.jpg";
import Testimonials12 from "../static/images/testimonials/testimonials_12.jpg";
import Testimonials13 from "../static/images/testimonials/testimonials_13.jpg";



const Testimonials = () => {
  window.scrollTo(0, 0);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const images = [
    {
      src: Testimonials1,
    },
    {
      src: Testimonials2,
    },
    {
      src: Testimonials3,
    },
    {
      src: Testimonials4,
    },
    {
      src: Testimonials5,
    },
    {
      src: Testimonials6,
    },
    {
      src: Testimonials7,
    },
    {
      src: Testimonials8,
    },
    {
      src: Testimonials9,
    },
    {
      src: Testimonials10,
    },
    {
      src: Testimonials11,
    },
    {
      src: Testimonials12,
    },
    {
      src: Testimonials13,
    },
 
  ];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <div>
      <Template>
      <div className="">
        <h1 className="font-bold text-2xl text-center mt-6">Testimonials</h1><br/>
      </div>
      <div className="flex justify-center">
        <div>
          <div className="grid grid-cols-3 gap-12">
            {images.map((img, index) => (
              <img
                src={img.src}
                onClick={() => openImageViewer(index)}
                width="400"
                key={index}
                style={{ margin: "2px" }}
                alt=""
              />
            ))}
          </div>
        </div>
      </div>

      {isViewerOpen && (
        <ImageViewer
          src={images.map((img) => img.src)}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={true}
        />
      )}
      </Template>
    </div>
  );
};

export default Testimonials;
