import { ADD_TO_CART, RAZORPAY_ORDER, VIEW_CART } from "../actions/types"

const initialState  = {
    items: [],
    order: {
        id: "",
        entity: "",
        amount:"" , 
        currency: "", 
        receipt: "", 
        status: ""
    }
}

const CartReducer = (state = initialState, action) => {
    switch (action.type) {
        case VIEW_CART:
            return Object.assign({}, state, {
                items: action.payload
            })
        case RAZORPAY_ORDER:
            return Object.assign({}, state, {
                order: action.payload
            })
        default:
            return state
    }
}

export default CartReducer