export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAILURE = 'AUTH_FAILURE'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAILURE = 'REGISTER_FAILURE'
export const LOGOUT = 'LOGOUT'
export const TOGGLE_LOADER = 'TOGGLE_LOADER'
export const UPDATE_AUTH_TOKEN = 'UPDATE_AUTH_TOKEN'
export const UPDATE_REFRESH_TOKE = 'UPDATE_REFRESH_TOKEN'
export const ADD_PRODUCT = 'ADD_PRODUCT'
export const ADD_PRODUCT_FAILURE = 'ADD_PRODUCT_FAILURE'
export const GET_PRODUCT = 'GET_PRODUCT'
export const GET_PRODUCT_FAILURE = 'GET_PRODUCT_FAILURE'
export const SELECTED_PRODUCT = 'SELECTED_PRODUCT'
export const SELECTED_PRODUCT_FAILURE = 'SELECTED_PRODUCT_FAILURE'
export const GET_CUSTOMERS = 'GET_CUSTOMERS'
export const GET_CUSTOMERS_FAILURE = 'GET_CUSTOMERS_FAILURE'
export const ADD_TO_CART = 'ADD_TO_CART'
export const ADD_TO_CART_FAILURE = 'ADD_TO_CART_FAILURE'
export const REMOVE_CART = 'REMOVE_CART'
export const REMOVE_CART_FAILURE = 'REMOVE_CART_FAILURE'
export const UPDATE_CART = 'UPDATE_CART'
export const UPDATE_CART_FAILURE = 'UPDATE_CART_FAILURE'
export const VIEW_CART = 'VIEW_CART'
export const VIEW_CART_FAILURE = 'VIEW_CART_FAILURE'
export const RAZORPAY_ORDER = 'RAZORPAY_ORDER'
export const CLEAR_CART = 'CLEAR_CART'
export const CLEAR_CART_FAILURE = "CLEAR_CART_FAILURE"
export const RAZORPAY_ORDER_FAILURE = 'RAZORPAY_ORDER_FAILURE'
export const PLACE_ORDER = 'PLACE_ORDER'
export const PLACE_ORDER_FAILURE = 'PLACE_ORDER_FAILURE'

export const ADD_EVENTS = "ADD_EVENTS"
export const ADD_EVENTS_FAILURE = "ADD_EVENTS_FAILURE"
export const GET_EVENTS = "GET_EVENTS"
export const GET_EVENTS_FAILURE = "GET_EVENTS_FAILURE"
export const EDIT_EVENTS = "EDIT_EVENTS"
export const EDIT_EVENTS_FAILURE = "EDIT_EVENTS_FAILURE"
export const DELETE_EVENTS = "DELETE_EVENTS"
export const DELETE_EVENTS_FAILURE = "DELETE_EVENTS_FAILURE"
export const SELECTED_EVENT = "SELECTED_EVENT"
export const SELECTED_EVENT_FAILURE = "SELECTED_EVENT_FAILURE"


export const ADD_ADMISSIONS = "ADD_ADMISSIONS"
export const ADD_ADMISSIONS_FAILURE = "ADD_ADMISSIONS_FAILURE"
export const GET_ADMISSIONS = "GET_ADMISSIONS"
export const GET_ADMISSIONS_FAILURE = "GET_ADMISSIONS_FAILURE"
export const EDIT_ADMISSIONS = "EDIT_ADMISSIONS"
export const EDIT_ADMISSIONS_FAILURE = "EDIT_ADMISSIONS_FAILURE"
export const DELETE_ADMISSIONS = "DELETE_ADMISSIONS"
export const DELETE_ADMISSIONS_FAILURE = "DELETE_ADMISSIONS_FAILURE"
export const SELECTED_ADMISSION = "SELECTED_ADMISSION"


export const ADD_BLOGS = "ADD_BLOGS"
export const ADD_BLOGS_FAILURE = "ADD_BLOGS_FAILURE"
export const GET_BLOGS = "GET_BLOGS"
export const GET_BLOGS_FAILURE = "GET_BLOGS_FAILURE"
export const EDIT_BLOGS = "EDIT_BLOGS"
export const EDIT_BLOGS_FAILURE = "EDIT_BLOGS_FAILURE"
export const DELETE_BLOGS = "DELETE_BLOGS"
export const DELETE_BLOGS_FAILURE = "DELETE_BLOGS_FAILURE"
export const SELECTED_BLOG = "SELECTED_BLOG"
export const SELECTED_BLOG_FAILURE = "SELECTED_BLOG_FAILURE"

export const GET_COURSES = "GET_COURSES"
export const GET_COURSES_FAILURE = "GET_COURSES_FAILURE"

export const GET_COURSE_DETAIL = "GET_COURSE_DETAIL"
export const GET_COURSE_DETAIL_FAILURE = "GET_COURSE_DETAIL_FAILURE"

export const LMS_AUTH = 'LMS_AUTH'
export const LMS_AUTH_FAILURE = 'LMS_AUTH_FAILURE'

export const LMS_COURSE = 'LMS_COURSE'
export const LMS_COURSE_FAILURE = 'LMS_COURSE_FAILURE'

export const LMS_COURSE_DETAIL = 'LMS_COURSE_DETAIL'
export const LMS_COURSE_DETAIL_FAILURE = 'LMS_COURSE_DETAIL_FAILURE'

export const LMS_GET_PROGRESS = 'LMS_GET_PROGRESS'
export const LMS_GET_PROGRESS_FAILURE = 'LMS_GET_PROGRESS_FAILURE'

export const LMS_GET_CERTIFICATE = 'LMS_GET_CERTIFICATE'
export const LMS_GET_CERTIFICATE_FAILURE = 'LMS_GET_CERTIFICATE_FAILURE'

export const LMS_GET_COURSE_CONTENT = 'LMS_GET_COURSE_CONTENT'
export const LMS_GET_COURSE_CONTENT_FAILURE = 'LMS_GET_COURSE_CONTENT_FAILURE'