import React from "react";
import Events from "../components/Events";
import Template from "../components/Template";
import styles from "../components/styles/Chairman.module.css"
const Chairman = () => {
    window.scrollTo(0, 0);
    return (
        <Template>
            <div className={styles.fullW}>
                <div className="mr-8 mt-12">
                    {/* <p className="text-2xl font-serif font-bold">Aspire Chairman</p> */}
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                        <div className="bg-GrayBg p-3 grid grid-cols-2 col-span-4 md:col-span-1 md:grid-cols-1 flex justify-between md:justify-center item-center mt-8 rounded-xl -fit">
                            <img src={`${process.env.PUBLIC_URL}/images/chairman.jpg`} className={"w-[100px] h-[100px] md:w-[280px] md:h-[280px]"} />
                            <div>
                                <p className="text-base font-bold text-left md:text-center">Sheikh Abdus Salam Madani</p>
                                <p className="text-small text-lightText text-left md:text-center">Chairman</p>
                                <p className="text-small text-lightText text-left md:text-center">Aspire social and Educational Trust</p>
                            </div>
                        </div>
                        <div className="mt-8 col-span-4 md:col-span-3">
                            <p className="font-bold">Message from the chairman</p><br/>
                            <p className="text-base text-justify">
                                Education is the basis of all progress. It is for this very reason that we established Aspire College of Excellence, I’m of the firm belief that progress is possible only, if men and women are equally well-educated.
                            </p><br/>
                            <p className="text-base text-justify">
                                The entire purpose of education is not to restrict the students to bookish knowledge only but also to inculcate humanitarian values like wisdom, compassion, courage, humility, integrity and reliability in a student.
                            </p><br/>
                            <p className="text-base text-justify">We, just like you, believe in holistic education for your child, encompassing – academics, co-curricular activities, sports and life-skills learning. Our endeavor is to strike a balance between state-of-the-art infrastructure and an internationally acceptable education.</p><br/>
                            <p className="text-base text-justify">At Aspire College of Excellence our hand-picked staff impart education that is based on conscience and rear a breed of young minds that are bustling with self-confidence, motivation and ever ready to take up challenges. Staffs are trained not only to teach well but are also expected to inspire confidence and trust in their students and become role models. Further, the college inculcates in the students a respect for tradition and ensures discipline and good manners. The campus environment, academic facilities all bear testimony to this effort.</p><br/>
                            <p className="text-base text-justify">Aspire College of Excellence encourages students to learn about themselves and their constantly changing environment, while at the same time offering support and guidance as they practice decision- making and social skills.</p><br/>
                            <p className="text-base text-justify">Once again, I welcome you and sincerely hope that our website will rouse your interest and encourage you to take a step towards making one more positive difference in your life.</p><br/>
                            <p className="text-base text-justify">Though there are lot of challenges that lie ahead, we will leave no stone unturned to further enrich the rich legacy of this esteemed institution. May the Almighty shower His blessings on us all and give us the strength to face all the challenges that come our way.</p><br/>
                        </div>
                    </div>
                </div>
            </div>
        </Template>
    )
}

export default Chairman;