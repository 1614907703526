import React from "react";
import Events from "../components/Events";
import Template from "../components/Template";
import styles from "../components/styles/Faculties.module.css";

const Faculties = () => {
  window.scrollTo(0, 0);
  return (
    <Template>
      <div className={styles.fullW}>
        <div className="mr-8">
          <p className="text-2xl font-serif font-bold">Faculties</p>
          <div className="grid gap-4">
            <div className=" ">
              <p className="text-base text-justify">
                The core of the identity of aspire has always been the scholar,
                the teacher who inspires to innovate, to think, to learn, to
                help, to live. We believe that successful learning by the
                student is the result of how engaged the student is by the
                learning exercises, & the progress in his personality. Our
                scholars focus on helping students to think creatively & reason
                analytically, giving them ample opportunities.
              </p>
            </div>
            <div className="hidden md:block">
              <p className="text-center font-bold text-2xl">Our faculties</p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-4">
              <div className="bg-GrayBg px-4 py-4 grid grid-cols-2 col-span-4 md:col-span-1 md:grid-cols-2 flex justify-between md:justify-center item-center rounded-xl">
                <div
                  className="flex justify-center items-center order-1 md:order-1"
                  style={{ width: "100%", height: "100%" }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/chairman.jpg`}
                    className={"w-[100%] h-[100%] md:w-[100%] md:h-[100%]"}
                  />
                </div>
                <div
                  className="order-2 md:order-2"
                  style={{ paddingLeft: "30px" }}
                >
                  <p className="text-base font-bold">
                    Sheikh Abdus Salam Madani
                  </p>
                  <ul className="list-disc">
                    <li className="text-sm text-lightText my-2">
                      BA in Hadeeth, Islamic University of Madina, KSA.
                    </li>
                    <li className="text-sm text-lightText my-2">
                      MA in Aqeedah, Islamic University of Madina, KSA.
                    </li>
                    <li className="text-sm text-lightText my-2">
                      MA in Modern Arabic, Islamic University of Madina, KSA.
                    </li>
                    <li className="text-sm text-lightText my-2">
                      18 years of experience in West Asian Countries as Daee,
                      Translator, Lecturer, Interpreter, Khateeb and Consultant.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="bg-GrayBg px-4 py-4 grid grid-cols-2 col-span-4 md:col-span-1 md:grid-cols-2 flex justify-between md:justify-center item-center rounded-xl">
                <div
                  className="flex justify-center items-center order-1 md:order-1"
                  style={{ width: "100%", height: "100%" }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/1. Ashiq Ahamed.png`}
                    className={"w-[100%] h-[100%] md:w-[100%] md:h-[100%]"}
                  />
                </div>
                <div
                  className="order-2 md:order-2"
                  style={{ paddingLeft: "30px" }}
                >
                  <p className="text-base font-bold">
                    Sheikh Ashiq Ahamed Al Madani
                  </p>
                  <ul className="list-disc">
                    <li className="text-sm text-lightText my-2">
                      Specialization in Aqeedah and Islamic History
                    </li>
                    <li className="text-sm text-lightText my-2">
                      B.A Theology in Islamic University of Madina
                    </li>
                    <li className="text-sm text-lightText my-2">
                      M.A Arabic (University of Madras)
                    </li>
                    <li className="text-sm text-lightText my-2">
                      Arabic Teacher Training in Malaysia – MS ASIA
                    </li>
                    <li className="text-sm text-lightText my-2">
                      Ijazah of Quran (Masjid An Nabavi)
                    </li>
                  </ul>
                </div>
              </div>

              <div className="bg-GrayBg px-4 py-4 grid grid-cols-2 col-span-4 md:col-span-1 md:grid-cols-2 flex justify-between md:justify-center item-center rounded-xl">
                <div
                  className="flex justify-center items-center order-1 md:order-1"
                  style={{ width: "100%", height: "100%" }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/2. Saddam Nadwi.png`}
                    className={"w-[100%] h-[100%] md:w-[100%] md:h-[100%]"}
                  />
                </div>
                <div
                  className="order-2 md:order-2"
                  style={{ paddingLeft: "30px" }}
                >
                  <p className="text-base font-bold">
                    Sheikh Saddam Hussain An Nadwi
                  </p>
                  <ul className="list-disc">
                    <li className="text-sm text-lightText my-2">
                      Qualified in M A in Modern Arabic
                    </li>
                    <li className="text-sm text-lightText my-2">
                      Specialization in Arabic language
                    </li>
                    <li className="text-sm text-lightText my-2">
                      10 years of teaching Arabic in modern easy method
                    </li>
                    <li className="text-sm text-lightText my-2">
                      Recipient of Best teacher award from lion’s club.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="bg-GrayBg px-4 py-4 grid grid-cols-2 col-span-4 md:col-span-1 md:grid-cols-2 flex justify-between md:justify-center item-center rounded-xl">
                <div
                  className="flex justify-center items-center order-1 md:order-1"
                  style={{ width: "100%", height: "100%" }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/3. Haris Madani.png`}
                    className={"w-[100%] h-[100%] md:w-[100%] md:h-[100%]"}
                  />
                </div>
                <div
                  className="order-2 md:order-2"
                  style={{ paddingLeft: "30px" }}
                >
                  <p className="text-base font-bold">
                    Sheikh Mohammad Haris Al Madani
                  </p>
                  <ul className="list-disc">
                    <li className="text-sm text-lightText my-2">
                      Studied in Haryana, Delhi and Madina Islamic University
                    </li>
                    <li className="text-sm text-lightText my-2">
                      Language Expert at Genpact Gurgaon
                    </li>
                    <li className="text-sm text-lightText my-2">
                      Ex-teacher for Noble Quran at Masjid Nabawi almost 3 years
                    </li>
                    <li className="text-sm text-lightText my-2">
                      Worked as translator from Arabic to English and Vice versa
                      in Madina
                    </li>
                  </ul>
                </div>
              </div>

              <div className="bg-GrayBg px-4 py-4 grid grid-cols-2 col-span-4 md:col-span-1 md:grid-cols-2 flex justify-between md:justify-center item-center rounded-xl">
                <div
                  className="flex justify-center items-center order-1 md:order-1"
                  style={{ width: "100%", height: "100%" }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/4. Khalid Shurfi.png`}
                    className={"w-[100%] h-[100%] md:w-[100%] md:h-[100%]"}
                  />
                </div>
                <div
                  className="order-2 md:order-2"
                  style={{ paddingLeft: "30px" }}
                >
                  <p className="text-base font-bold">
                    Sheikh Khalid Abdus Salam Shurfi
                  </p>
                  <ul className="list-disc">
                    <li className="text-sm text-lightText my-2">
                      BA in Islamic Jurisprudence, Indonesia
                    </li>
                    <li className="text-sm text-lightText my-2">
                      Graduated from Pearson Edexcel , Quran memorization from
                      IRGC India
                    </li>
                    <li className="text-sm text-lightText my-2">
                      Bachelor’s Degree from Jamia Darussalam Oomerabad
                    </li>
                    <li className="text-sm text-lightText my-2">
                      Ijazah holder of Quran and other sacred texts in a variety
                      of Islamic sciences including Fiqh, Usul ul Fiqh, Hadith
                      and Aqeedah
                    </li>
                    <li className="text-sm text-lightText my-2">
                      Actively serves as an Imam and Khateeb, guest Imam
                      (Arabic) at various mosques
                    </li>
                  </ul>
                </div>
              </div>

              <div className="bg-GrayBg px-4 py-4 grid grid-cols-2 col-span-4 md:col-span-1 md:grid-cols-2 flex justify-between md:justify-center item-center rounded-xl">
                <div
                  className="flex justify-center items-center order-1 md:order-1"
                  style={{ width: "100%", height: "100%" }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/5. Nisar.png`}
                    className={"w-[100%] h-[100%] md:w-[100%] md:h-[100%]"}
                  />
                </div>
                <div
                  className="order-2 md:order-2"
                  style={{ paddingLeft: "30px" }}
                >
                  <p className="text-base font-bold">
                    Sheikh Mohammed Nisar Ahmed
                  </p>
                  <ul className="list-disc">
                    <li className="text-sm text-lightText my-2">
                      BA in Arabic, Master of Business Administration(MBA)
                    </li>
                    <li className="text-sm text-lightText my-2">
                      Fadheela from Jamia Darussalam Oomerabad
                    </li>
                    <li className="text-sm text-lightText my-2">
                      Certification in Quality Engineering from Cambridge.
                    </li>
                    <li className="text-sm text-lightText my-2">
                      Former English Arabic project coordinator for Jamia Ummul
                      Qura, Makkah
                    </li>
                    <li className="text-sm text-lightText my-2">
                      Former English Arabic Interpreter for dawah centre in
                      Makkah
                    </li>
                    <li className="text-sm text-lightText my-2">
                      Former Research Scholar at Madina
                    </li>
                  </ul>
                </div>
              </div>

              <div className="bg-GrayBg px-4 py-4 grid grid-cols-2 col-span-4 md:col-span-1 md:grid-cols-2 flex justify-between md:justify-center item-center rounded-xl">
                <div
                  className="flex justify-center items-center order-1 md:order-1"
                  style={{ width: "100%", height: "100%" }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/6. Afsarullah.png`}
                    className={"w-[100%] h-[100%] md:w-[100%] md:h-[100%]"}
                  />
                </div>
                <div
                  className="order-2 md:order-2"
                  style={{ paddingLeft: "30px" }}
                >
                  <p className="text-base font-bold">
                    Sheikh Afsarullah Khan Umari
                  </p>
                  <ul className="list-disc">
                    <li className="text-sm text-lightText my-2">
                      B.A Islamic studies from Jamia Darussalam Oomerabad Tamil
                      nadu
                    </li>
                    <li className="text-sm text-lightText my-2">
                      Specialization in Hadeeth and its sciences from college of
                      Hadeeth and Islamic studies Bangalore.
                    </li>
                    <li className="text-sm text-lightText my-2">
                      5 years of experience in teaching Arabic, Hadeeth and
                      Aqeedah.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="bg-GrayBg px-4 py-4 grid grid-cols-2 col-span-4 md:col-span-1 md:grid-cols-2 flex justify-between md:justify-center item-center rounded-xl">
                <div
                  className="flex justify-center items-center order-1 md:order-1"
                  style={{ width: "100%", height: "100%" }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/7. Ashiqa Farzan.png`}
                    className={"w-[100%] h-[100%] md:w-[100%] md:h-[100%]"}
                  />
                </div>
                <div
                  className="order-2 md:order-2"
                  style={{ paddingLeft: "30px" }}
                >
                  <p className="text-base font-bold">Ashiqa Farzan</p>
                  <ul className="list-disc">
                    <li className="text-sm text-lightText my-2">
                      B.Sc Psychology
                    </li>
                    <li className="text-sm text-lightText my-2">
                      M.Sc Psychology in Organisational Behaviour
                    </li>
                    <li className="text-sm text-lightText my-2">
                      NLP & CBT Practitioner
                    </li>
                    <li className="text-sm text-lightText my-2">
                      Pioneer , The Department of Psychology, Aspire College of
                      Excellence
                    </li>
                    <li className="text-sm text-lightText my-2">
                      Executive Committee Member, Muslim Women's Association.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="bg-GrayBg px-4 py-4 grid grid-cols-2 col-span-4 md:col-span-1 md:grid-cols-2 flex justify-between md:justify-center item-center rounded-xl">
                <div
                  className="flex justify-center items-center order-1 md:order-1"
                  style={{ width: "100%", height: "100%" }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/7. Ashiqa Farzan.png`}
                    className={"w-[100%] h-[100%] md:w-[100%] md:h-[100%]"}
                  />
                </div>
                <div
                  className="order-2 md:order-2"
                  style={{ paddingLeft: "30px" }}
                >
                  <p className="text-base font-bold">Wafa Noor Meerlabi</p>
                  <ul className="list-disc">
                    <li className="text-sm text-lightText my-2">
                      BSc Psychology, University of Madras (Tamilnadu)
                    </li>
                    <li className="text-sm text-lightText my-2">
                      Dip. Fundamentals of Islam (IRGC)
                    </li>
                    <li className="text-sm text-lightText my-2">
                      MSc Counselling Psychology, University of
                      Madras(Tamilnadu)
                    </li>
                    <li className="text-sm text-lightText my-2">
                      BA in Islamic Studies, MS University (Tamilnadu)
                    </li>
                    <li className="text-sm text-lightText my-2">
                      6 years of experience as lecturer and counsellor.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="bg-GrayBg px-4 py-4 grid grid-cols-2 col-span-4 md:col-span-1 md:grid-cols-2 flex justify-between md:justify-center item-center rounded-xl">
                <div
                  className="flex justify-center items-center order-1 md:order-1"
                  style={{ width: "100%", height: "100%" }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/7. Ashiqa Farzan.png`}
                    className={"w-[100%] h-[100%] md:w-[100%] md:h-[100%]"}
                  />
                </div>
                <div
                  className="order-2 md:order-2"
                  style={{ paddingLeft: "30px" }}
                >
                  <p className="text-base font-bold">M. Haseebunissa</p>
                  <ul className="list-disc">
                    <li className="text-sm text-lightText my-2">
                      Qualified in M.Sc., M.Phil.
                    </li>
                    <li className="text-sm text-lightText my-2">
                      Gold Medalist - Undergraduation, Post Graduation & MPhil
                    </li>
                    <li className="text-sm text-lightText my-2">
                      Founder and Chief Counsellor – Treatwomental Therapeutic
                      Centre
                    </li>
                    <li className="text-sm text-lightText my-2">
                      Worked as a ‘Research Assistant’ for a UGC sponsored
                      research
                    </li>
                    <li className="text-sm text-lightText my-2">
                      Conducted various awareness programs for students and
                      working professionals
                    </li>
                    <li className="text-sm text-lightText my-2">
                      Specialization in Counselling Psychology
                    </li>
                  </ul>
                </div>
              </div>

              <div className="bg-GrayBg px-4 py-4 grid grid-cols-2 col-span-4 md:col-span-1 md:grid-cols-2 flex justify-between md:justify-center item-center rounded-xl">
                <div
                  className="flex justify-center items-center order-1 md:order-1"
                  style={{ width: "100%", height: "100%" }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/7. Ashiqa Farzan.png`}
                    className={"w-[100%] h-[100%] md:w-[100%] md:h-[100%]"}
                  />
                </div>
                <div
                  className="order-2 md:order-2"
                  style={{ paddingLeft: "30px" }}
                >
                  <p className="text-base font-bold">Mariya Zainab</p>
                  <ul className="list-disc">
                    <li className="text-sm text-lightText my-2">
                      BA in Psychology, Economics & Sociology
                    </li>
                    <li className="text-sm text-lightText my-2">
                      MSc in Psychology
                    </li>
                    <li className="text-sm text-lightText my-2">
                      Certified Practitioner in Trauma & Grief Therapy
                    </li>
                    <li className="text-sm text-lightText my-2">
                      Certified Practitioner in Cognitive Behavioral Therapy
                    </li>
                    <li className="text-sm text-lightText my-2">
                      Specialisation in Psychology
                    </li>
                  </ul>
                </div>
              </div>

              <div className="bg-GrayBg px-4 py-4 grid grid-cols-2 col-span-4 md:col-span-1 md:grid-cols-2 flex justify-between md:justify-center item-center rounded-xl">
                <div
                  className="flex justify-center items-center order-1 md:order-1"
                  style={{ width: "100%", height: "100%" }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/7. Ashiqa Farzan.png`}
                    className={"w-[100%] h-[100%] md:w-[100%] md:h-[100%]"}
                  />
                </div>
                <div
                  className="order-2 md:order-2"
                  style={{ paddingLeft: "30px" }}
                >
                  <p className="text-base font-bold">Madiha Zainab Fathima</p>
                  <ul className="list-disc">
                    <li className="text-sm text-lightText my-2">
                      BA Islamic Studies, Aspire College of Excellence, Chennai
                    </li>
                    <li className="text-sm text-lightText my-2">
                      B.Sc Psychology, University of Madras, Chennai
                    </li>
                    <li className="text-sm text-lightText my-2">
                      M.Sc Psychology, University of Madras, Chennai
                    </li>
                    <li className="text-sm text-lightText my-2">
                      Former Editor in Chief of Aspire-ations, Aspire College e
                      magazine
                    </li>
                    <li className="text-sm text-lightText my-2">
                      Experienced as a Tajweed teacher and Da'wah trainer
                    </li>
                  </ul>
                </div>
              </div>

              <div className="bg-GrayBg px-4 py-4 grid grid-cols-2 col-span-4 md:col-span-1 md:grid-cols-2 flex justify-between md:justify-center item-center rounded-xl">
                <div
                  className="flex justify-center items-center order-1 md:order-1"
                  style={{ width: "100%", height: "100%" }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/12. Azharuddin.png`}
                    className={"w-[100%] h-[100%] md:w-[100%] md:h-[100%]"}
                  />
                </div>
                <div
                  className="order-2 md:order-2"
                  style={{ paddingLeft: "30px" }}
                >
                  <p className="text-base font-bold">
                    Sheikh Mohammed Azharuddin An Nazeeri
                  </p>
                  <ul className="list-disc">
                    <li className="text-sm text-lightText my-2">
                      BA in Islamic Studies
                    </li>
                    <li className="text-sm text-lightText my-2">
                      BA in Hadees
                    </li>
                    <li className="text-sm text-lightText my-2">
                      Specialization in Hadees in Kullliyatul Hadees
                    </li>
                  </ul>
                </div>
              </div>

              <div className="bg-GrayBg px-4 py-4 grid grid-cols-2 col-span-4 md:col-span-1 md:grid-cols-2 flex justify-between md:justify-center item-center rounded-xl">
                <div
                  className="flex justify-center items-center order-1 md:order-1"
                  style={{ width: "100%", height: "100%" }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/13. Saddam Hasani.png`}
                    className={"w-[100%] h-[100%] md:w-[100%] md:h-[100%]"}
                  />
                </div>
                <div
                  className="order-2 md:order-2"
                  style={{ paddingLeft: "30px" }}
                >
                  <p className="text-base font-bold">
                    Sheikh Saddam Hussain Al Hasani
                  </p>
                  <ul className="list-disc">
                    <li className="text-sm text-lightText my-2">
                      Bachelor’s degree from the Jamia Uswathun Hasana
                      Pallapatti
                    </li>
                    <li className="text-sm text-lightText my-2">
                      M.A in Modern Arabic, University of Madras Chennai.
                    </li>
                    <li className="text-sm text-lightText my-2">
                      Afzalul Ulama degree from the Bharadidhasan University of
                      Trichy.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="bg-GrayBg px-4 py-4 grid grid-cols-2 col-span-4 md:col-span-1 md:grid-cols-2 flex justify-between md:justify-center item-center rounded-xl">
                <div
                  className="flex justify-center items-center order-1 md:order-1"
                  style={{ width: "100%", height: "100%" }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/14. Abullais.png`}
                    className={"w-[100%] h-[100%] md:w-[100%] md:h-[100%]"}
                  />
                </div>
                <div
                  className="order-2 md:order-2"
                  style={{ paddingLeft: "30px" }}
                >
                  <p className="text-base font-bold">
                    Sheikh Mohammed Abullais
                  </p>
                  <ul className="list-disc">
                    <li className="text-sm text-lightText my-2">
                      Alimiyah and Fazeelah from Jamia Darussalam.
                    </li>
                    <li className="text-sm text-lightText my-2">
                      BA in Arabic
                    </li>
                    <li className="text-sm text-lightText my-2">
                      Specialization in the studies of Hadith.
                    </li>
                    <li className="text-sm text-lightText my-2">
                      Specialization in Arabic language
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Template>
  );
};

export default Faculties;
