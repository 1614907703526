import React, { useState, useEffect } from "react";
import "./styles/Carousel.css";
import MobileSlider1 from "../static/images/MobileSlider1.png";
import MobileSlider2 from "../static/images/MobileSlider2.png";
import MobileSlider3 from "../static/images/MobileSlider3.png";


const Carousel = ({ images }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const isMobile = window.innerWidth < 768; // Define your mobile breakpoint here

  const mobileImages = [
    MobileSlider1,
    MobileSlider2,
    MobileSlider3,
  ];

  const nextSlide = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === (isMobile ? mobileImages.length - 1 : images.length - 1)
        ? 0
        : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0
        ? isMobile
          ? mobileImages.length - 1
          : images.length - 1
        : prevIndex - 1
    );
  };

  const goToSlide = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 4000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="carousel">
      <div
        className="carousel__slides"
        style={{ transform: `translateX(-${activeIndex * 100}%)` }}
      >
        {(isMobile ? mobileImages : images).map((image, index) => (
          <a href="/admission-form"
            className={`carousel__slide ${
              index === activeIndex ? "active" : ""
            }`}
            key={index}
          >
            <img src={image} alt={`Slide ${index}`} />
            </a>
        ))}
      </div>
      <div className="carousel__buttons">
        <button
          className="carousel__button carousel__button--prev"
          onClick={prevSlide}
        >
          &lt;
        </button>
        <button
          className="carousel__button carousel__button--next"
          onClick={nextSlide}
        >
          &gt;
        </button>
      </div>
      <div className="carousel__dots">
        {(isMobile ? mobileImages : images).map((_, index) => (
          <button
            className={`carousel__dot ${
              index === activeIndex ? "active" : ""
            }`}
            key={index}
            onClick={() => goToSlide(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
