import {
  faEnvelope,
  faInbox,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Button from "./Button";
import TextInput from "./TextInput";
import { useForm } from "@formspree/react";
import { Link } from "react-router-dom";

const ContactUs = (props) => {
  const [state, handleSubmit] = useForm("xleyjbkd");
  if (state.succeeded) {
    return (
      <div class="bg-gray-100 flex justify-center items-center h-screen">
        <div class="bg-white p-8 rounded-lg shadow-md text-center">
          <div class="text-green-500 text-4xl mb-4">
            <svg
              class="inline-block w-12 h-12"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M5 13l4 4L19 7"></path>
            </svg>
          </div>
          <h1 class="text-3xl font-semibold text-gray-800 mb-2">Thank You!</h1>
          <p class="text-lg text-gray-600">
            Your message has been successfully received.
          </p>
          <Link
            to="/"
            className="text-blue-500 hover:underline  inline-block underline text-xs"
          >
            Go to Homepage
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-GrayBg rounded-2xl w-80 md:w-1/2 p-2">
      <div className="grid grid-cols-4">
        <div className="cursor-pointer col-span-3">
          <img
            src={`${process.env.PUBLIC_URL}/images/logo-dark.png`}
            style={{ width: "150px" }}
          />
        </div>
        <div
          className="cursor-pointer col-span-1"
          onClick={() => props.setShowContactUs(false)}
        >
          <p className="text-right">X</p>
        </div>
      </div>
      <div className="my-2">
        <p className="text-xl font-bold font-serif my-2">Write to us</p>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 gap-4">
            <TextInput name="firstName"  label="First name" id="f-name" />
            <TextInput name="lastName" label="Last name" id="l-name" />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <TextInput name="email" label="Email" id="email" />
            <TextInput name="phoneNumber" label="Phone No" id="phone" />
          </div>

          <div>
            <TextInput name="subject" label="Subject" id="subject" />
          </div>

          <div className="my-2">
            <p className="text-sm my-2 px-4 font-bold">{"Message"}</p>
            <textarea
              rows={10}
              name={"message"}
              className={"rounded-2xl bg-White w-full"}
              id="message"
            />
          </div>
          <div className="flex justify-end items-center">
            <button
              type="submit"
              disabled={state.submitting}
              className=" bg-AspireBrandBlue rounded-3xl py-3 px-4  cursor-pointer text-White"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
      <div className="flex flex-row w-full justify-between my-2">
        <div>
          <div className="flex flex-row  items-center">
            <FontAwesomeIcon
              icon={faEnvelope}
              className="mr-1 md:mr-2 hover:text-White"
              size="text-base"
            />
            <span>info@aspirecollege.edu.in</span>
          </div>
          <div className="flex flex-row  items-center">
            <FontAwesomeIcon
              icon={faPhone}
              className="mr-1 md:mr-2 hover:text-White"
              size="text-base"
            />
            <span>+91 9940348823</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
