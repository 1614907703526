const { ADD_PRODUCT, GET_PRODUCT, SELECTED_PRODUCT } = require("../actions/types")

const initialState = {
    products: [],
    selectedProduct: {
        productName: '',
        productDescription: "",
        mrp: 0,
        sellingPrice: 0,
        image: [],
        activeStatus: true
    }
}

const ProductReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCT:
            return Object.assign({}, state, {
                products: action.payload
            })
        case SELECTED_PRODUCT:
            return Object.assign({}, state, {
                selectedProduct: action.payload
            })
        default:
            return state
    }
}

export default ProductReducer