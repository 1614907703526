import { LMS_AUTH, LMS_AUTH_FAILURE } from "../actions/types"

const initialState = {
    courses: [],
    selectedCourse: {

    },
    personalInfo: {},
    progress:[],
    authError: "",
}

const LmsReducer = (state = initialState, action) => {
    switch (action.type) {
        case LMS_AUTH:
            return Object.assign({}, state, {
                personalInfo: action.payload.personalInfo,
                courses: action.payload.courses,
                progress: action.payload.progress
            })
        case LMS_AUTH_FAILURE:
            return Object.assign({}, state, {
                authError: action.payload.error || action.payload.message,
            })
        default:
            return state
    }
}

export default LmsReducer