import React, { useEffect, useState } from "react";
import CommunityCard from "./CommunityCard";
import CourseCard from "./CourseCard";
import EventCard from "./EventCard";
import { useDispatch, useSelector } from "react-redux";
import { getEvents } from "../redux/actions/eventAction";
import styles from "./styles/event.module.css"
const Events = () => {
    const [active, setActive] = useState('Upcoming Events')
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getEvents())
    },[])
    const events = useSelector(state => state.Events.events)
    const courseData = [
        {
            title: "Islamic Studies",
            date: "28 Feb, 2023 - 01 Mar, 2023",
            duration: "5 PM - 6 PM (GMT +5:30)",
            location: "Chennai | Bengaluru",
            image: "https://images.pexels.com/photos/6988372/pexels-photo-6988372.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
        },
        {
            title: "Women Power 2.0",
            date: "28 Feb, 2023 - 01 Mar, 2023",
            duration: "5 PM - 6 PM (GMT +5:30)",
            location: "Chennai | Bengaluru",
            image: "https://images.pexels.com/photos/4646244/pexels-photo-4646244.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
        },
    ]
    return (
        <div className={styles.fullW}>
            <div>
                <p className="text-2xl font-serif font-bold">Events</p>
            </div>
            <div className="flex flex-row justify-between py-3">
                <div className="flex flex-row flex-shrink-0">
                    <div className={`mr-5 cursor-pointer py-3 ${active == 'Upcoming Events' ? 'text-AspireBrandBlue border-b-4' : ''}`} onClick={() => setActive('Upcoming Events')}>
                        <p className="font-medium text-xs md:text-base">Upcoming Events</p>
                    </div>
                    <div className={`mx-11 font-medium text-xs md:text-base cursor-pointer py-3 ${active == 'Past Events' ? 'text-AspireBrandBlue border-b-4' : ''}`} onClick={() => setActive('Past Events')}>
                        <p>Past Events</p>
                    </div>
                </div>
                <div className="flex justify-center item-center hidden md:block">
                    <span className="font-medium text-AspireBrandBlue cursor-pointer py-3">{"Show all events > "}</span>
                </div>
            </div>
            <div className="flex flex-row mt-10 overflow-x-auto ">
                {events.map((data, index) => (
                    <EventCard title={data.title} date={data.date} index={index} image={data.image} time={data.time} language={data.language} offer={data.offer} location={data.location} active={active} />
                ))}
            </div>
        </div>
    )
}

export default Events