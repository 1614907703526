import React from "react";
import { useNavigate } from "react-router-dom";

const ProductCard = (props) => {
    const {product} = props;
    const navigate = useNavigate()
    return(
        <div className="border-2 cursor-pointer border-Stroke  p-2 rounded-2xl" onClick={() => navigate('/product/'+product._id)}>
        <div className="flex justify-center">
        <img src={product.image[0]} className={'w-[172px] h-[172px] md:w-[285px] md:h-[285px] rounded-2xl'}  />
        </div>
        <div className="mt-2"><p className="text-base md:text-xl">{product.productName}</p></div>
      {/*  <div className="mt-2 truncate  overflow-hidden"> <p className="text-sm truncate overflow-hidden">{product.productDescription}</p></div> */}
        <div className="flex flex-row item-center mt-2">
            <p className="text-base mr-2">₹{product.sellingPrice}</p>
            <p className="text-sm text-lightText line-through mr-2">₹{product.mrp}</p>
            <p className="text-sm text-AspireBlue1 mr-2">({Math.round(((product.mrp - product.sellingPrice)/product.mrp)*100)}% OFF)</p>
        </div>
    </div>
    )
}

export default ProductCard