import React from "react";
import Communities from "../components/Communities";
import Template from "../components/Template";

const Community = () => {
    return (
        <Template>

            <div style={{ paddingTop: "100px" }} className={"hidden md:block"}>

                <Communities />
            </div>
            <div style={{ paddingTop: "30px" }} className={"md:hidden block"}>

                <Communities />
            </div>
        </Template>
    )
}

export default Community