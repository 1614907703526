import Api from "../../lib/api";
import { ADD_TO_CART, ADD_TO_CART_FAILURE, CLEAR_CART, CLEAR_CART_FAILURE, PLACE_ORDER, PLACE_ORDER_FAILURE, RAZORPAY_ORDER, RAZORPAY_ORDER_FAILURE, REMOVE_CART, REMOVE_CART_FAILURE, UPDATE_CART, UPDATE_CART_FAILURE, VIEW_CART, VIEW_CART_FAILURE } from "./types";
import Swal from "sweetalert2"

export const addToCart = (body) => async (dispatch) => {
    try {
        const response = await Api.post(body, '/cart');
        if (response.success) {
            dispatch({
                type: ADD_TO_CART,
                payload: response.data
            })
            dispatch(viewCart())
        } else {
            dispatch({
                type: ADD_TO_CART_FAILURE,
                payload: response.data
            })
        }
    } catch (e) {
        dispatch({
            type: ADD_TO_CART_FAILURE,
            payload: e.message
        })
    }
}

export const updateCart = (body) => async (dispatch) => {
    try {
        const response = await Api.post(body, '/cart/edit');
        if (response.success) {
            dispatch({
                type: UPDATE_CART,
                payload: response.data
            })
            dispatch(viewCart())
        } else {
            dispatch({
                type: UPDATE_CART_FAILURE,
                payload: response.data
            })
        }
    } catch (e) {
        dispatch({
            type: UPDATE_CART_FAILURE,
            payload: e.message
        })
    }
}

export const removeFromCart = (body) => async (dispatch) => {
    try {
        const response = await Api.post(body, '/cart/delete');
        if (response.success) {
            dispatch({
                type: REMOVE_CART,
                payload: response.data
            })
            dispatch(viewCart())
        } else {
            dispatch({
                type: REMOVE_CART_FAILURE,
                payload: response.data
            })
        }
    } catch (e) {
        dispatch({
            type: REMOVE_CART_FAILURE,
            payload: e.message
        })
    }
}

export const viewCart = () => async (dispatch) => {
    try {
        const response = await Api.get('/cart');
        if (response.success) {
            dispatch({
                type: VIEW_CART,
                payload: response.data
            })
        } else {
            dispatch({
                type: VIEW_CART_FAILURE,
                payload: response.data
            })
        }
    } catch (e) {
        dispatch({
            type: VIEW_CART_FAILURE,
            payload: e.message
        })
    }
}

export const generateRazorpayOrder =  () => async dispatch => {
    try {
        const response = await Api.get('/cart/order');
        if (response.success) {
            dispatch({
                type: RAZORPAY_ORDER,
                payload: response.data
            })
        } else {
            dispatch({
                type: RAZORPAY_ORDER_FAILURE,
                payload: response.data
            })
        }
    } catch (e) {
        dispatch({
            type: RAZORPAY_ORDER_FAILURE,
            payload: e.message
        })
    }
}

export const placeOrder = (body) => async (dispatch) => {
    try {
      const response = await Api.post(body, '/order');
      if (response.success) {
        dispatch({
          type: PLACE_ORDER,
          payload: response.data
        });
        dispatch(viewCart());
        Swal.fire({
            title: 'Order Placed Successfully.',
            html: '<p>Thank you for placing your order!</p>',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'OK',
          }).then((result) => {
            if (result.isConfirmed) {
              // Perform actions when the user clicks "OK"
              dispatch(clearCart(body));
            }
          });
      } else {
        dispatch({
          type: PLACE_ORDER_FAILURE,
          payload: response.data
        });
        Swal.fire('Order Placement Failed', response.data, 'error');
      }
    } catch (e) {
      dispatch({
        type: PLACE_ORDER_FAILURE,
        payload: e.message
      });
      Swal.fire('Order Placement Failed', e.message, 'error');
    }
  };

  export const clearCart = (body) => async (dispatch) => {
    try {
      const response = await Api.post((body), '/cart/clear');
      if (response.success) {
        dispatch(viewCart());
        dispatch({
          type: CLEAR_CART,
          payload: [] // Clear the cart items in the Redux store
        });
      } else {
        dispatch({
          type: CLEAR_CART_FAILURE,
          payload: response.data // Handle clear cart failure
        });
      }
    } catch (e) {
      dispatch({
        type: CLEAR_CART_FAILURE,
        payload: e.message // Handle clear cart failure
      });
    }
  };
  
  