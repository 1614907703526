import {
  faCreditCard,
  faLanguage,
  faLocation,
  faStopwatch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "./Button";
import SecondaryButton from "./SecondaryButton";
import ContactUs from "./ContactUs";

const CourseCard = (props) => {
  const [showContactUs, setShowContactUs] = useState(false);
  const navigate = useNavigate();
  const {
    title,
    duration,
    location,
    language,
    offer,
    image,
    id,
    purchased = false,
  } = props;

  return (
    <div
      className={`cursor-pointer rounded-3xl drop-shadow-[0_4px_6px_2px_rgba(51,61,80,0.1)] border border-Stroke mb-8 flex-shrink-0`}
    >
      <div className="hidden md:block">
        <img src={image} className="rounded-t-3xl w-fit cover" />
      </div>
      {/* <div className="block md:hidden object-cover rounded-t-3xl">
        <img src={image} className="rounded-t-3xl  " />
      </div> */}
      <div
        style={{
          backgroundImage: `url(${image})`,
          width: "220px",
          height: "150px",
        }}
        className={`block md:hidden bg-cover rounded-t-3xl `}
      />
      <div className="p-2">
        <div className="mb-2">
          <p className="font-medium text-base md:text-2xl text-title">
            {title}
          </p>
        </div>
        <div className="flex flex-row py-2 items-center">
          <FontAwesomeIcon
            size="text-sm"
            icon={faStopwatch}
            className="mr-1 text-title"
          />
          <p className="text-sm text-title">{duration}</p>
        </div>
        <div className="flex flex-row py-2 items-center">
          <FontAwesomeIcon
            size="text-sm"
            icon={faLocation}
            className="mr-1 text-title"
          />
          <p className="text-sm text-title">{location}</p>
        </div>
        {language !== "" && (
          <div className="flex flex-row py-2 items-center">
            <FontAwesomeIcon
              size="text-sm"
              icon={faLanguage}
              className="mr-1 text-title"
            />
            <p className="text-sm text-title">{language}</p>
          </div>
        )}
        {offer !== "" && (
          <div className="flex flex-row py-2 items-center">
            <FontAwesomeIcon
              size="text-sm"
              icon={faCreditCard}
              className="mr-1 text-title"
            />
            <p className="text-sm text-title">{offer}</p>
          </div>
        )}
      </div>
      <div className="flex flex-row justify-evenly item-center my-2">
        <div>
          <SecondaryButton
            label="View Course"
            
            onClick={() => 
              !purchased ? navigate(`/course/${id}`) : navigate(`/lms/${id}`)
            }
          />
        </div>
        {!purchased && (
          
          <div className="">
            <Button
              label="Inquire Now"
              onClick={() => {
                setShowContactUs(true);
                
              }}
            />
          </div>
        )}
      </div>
      
      <div
        className={`fixed ${
          showContactUs ? "" : "hidden"
        } inset-0 bg-Black bg-opacity-50 overflow-y-auto h-full w-full`}
      >
        <div className="flex justify-center my-24 md:my-28">
          <ContactUs setShowContactUs={setShowContactUs} />
        </div>
      </div>
    </div>
    
  );
};

export default CourseCard;
