import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Menu = (props) => {
  const [active, setActive] = useState("");
  const navigate = useNavigate();


  // Define the menu items and their sub-items
  const menuItems = [
    {
      label: "Courses",
      subItems: [
        { label: "On Campus", path: "/course/1" },
        { label: "Online Degree", path: "/course/1" },
        { label: "Diploma Programs", path: "/course/5" },
        { label: "Short Courses", path: "/course/1" },
        { label: "Free Course", path: "/course" },
      ],
    },
    {
      label: "Admission",
      subItems: [
        { label: "Admission Form", path: "/admission-form" },
        { label: "Scholarship", path: "/scholarship" },
        // { label: "+2 or PUC Courses", path: "/puccourse" },
      ],
    },
    {
      label: "Community",
      subItems: [
        
        { label: "W Power", path: "#" },
        { label: "Salam Centre Franchise", path: "/salam-centre-franchise" },
        { label: "Magazines", path: "/magazines" },
        { label: "Crowd Funding", path: "#" },
        { label: "Forum", path: "https://lms.aspirecollege.edu.in/forums"},
      ],
    },
    {
      label: "Life at Aspire",
      subItems: [
        { label: "Gallery", path: "/gallery" },
        { label: "Alumni", path: "/alumni" },
        { label: "Testimonials", path: "/testimonials" },
      ],
    },
    {
      label: "About Aspire",
      subItems: [
        { label: "Aspire at a Glance", path: "/glance" },
        { label: "Aspire Chairman", path: "/chairman" },
        { label: "Aspire Faculties", path: "/faculties" },
      ],
    },
    {
      label: "Shop",
      path: "https://lms.aspirecollege.edu.in/products",
    },
    {
      label: "Events",
      subItems: [
        { label: "Upcoming Events", path: "/events" },
        { label: "Past Events", path: "/events" },
      ],
    },
    
    {
      label: "Blogs",
      path: "https://lms.aspirecollege.edu.in/blog",
    },
  ];

  return (
    <div>

     <div className="grid grid-cols-4 p-4 md:p-20">
        <div className="cursor-pointer col-span-3">
          <img
            src={`${process.env.PUBLIC_URL}/images/logo-dark.png`}
            style={{ width: "200px" }}
          />
        </div>
        <div
          className="cursor-pointer col-span-1"
          onClick={() => props.setShowMenu(false)}
        >
          <p className="text-right">Close X</p>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-4">
        <div className="flex flex-col flex-shrink-0 px-4 md:px-20">
          {menuItems.map((menuItem) => (
            <div
              key={menuItem.label}
              className={`mr-5 cursor-pointer py-3 ${
                active === menuItem.label ? "text-AspireBrandBlue border-b-4" : ""
              }`}
              onMouseEnter={() => setActive(menuItem.label)}
              onClick={() => {
                if (menuItem.path) {
                  // If there's a direct path, navigate to it
                  if (menuItem.path.startsWith("http")) {
                    // If it's an external link, open it in a new tab
                    window.open(menuItem.path, "_blank");
                  } else {
                    navigate(menuItem.path);
                  }
                }
              }}
            >
              <p className="text-2xl font-serif font-bold">{menuItem.label}</p>
              {active === menuItem.label && menuItem.subItems && (
                <div className="block md:hidden">
                  <div className="my-4">
                    <p className="text-sm font-bold">{menuItem.label}</p>
                  </div>
                  {menuItem.subItems.map((subItem) => (
                    <div className="mb-4" key={subItem.label}>
                      <p
                        className="cursor-pointer"
                        onClick={() => {
                          if (subItem.path) {
                            navigate(subItem.path);
                            props.setShowMenu(false);
                          }
                        }}
                      >
                        {subItem.label}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="grid col-span-1 h-full hidden md:grid">
          {/* Sub-menus for large screens */}
          {menuItems.map((menuItem) => (
            <div key={menuItem.label}>
              {active === menuItem.label && menuItem.subItems && (
                <div>
                  <div className="mb-4">
                    <p className="text-sm font-bold">{menuItem.label}</p>
                  </div>
                  {menuItem.subItems.map((subItem) => (
                    <div className="mb-4" key={subItem.label}>
                      <p
                        className="cursor-pointer"
                        onClick={() => {
                          if (subItem.path) {
                            navigate(subItem.path);
                            props.setShowMenu(false);
                          }
                        }}
                      >
                        {subItem.label}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="border-t-2 px-4 md:px-20 overflow-x-auto">
        <div className="flex flex-row w-full flex-shrink-0">
          <p
            className="text-sm p-4 cursor-pointer"
            onClick={() => {
              props.setShowMenu(false);
              navigate("/terms-conditions");
            }}
          >
            Terms & Conditions
          </p>
          <p
            className="text-sm p-4 cursor-pointer"
            onClick={() => {
              props.setShowMenu(false);
              navigate("/privacy-policy");
            }}
          >
            Privacy Policy
          </p>
          <p
            className="text-sm p-4 cursor-pointer"
            onClick={() => {
              props.setShowMenu(false);
              props.setShowContactUs(true);
            }}
          >
            Apply Now
          </p>
          <p
            className="text-sm p-4 cursor-pointer"
            onClick={() => {
              props.setShowMenu(false);
              props.setShowContactUs(true);
            }}
          >
            Contact
          </p>
         
        </div>
      </div>
    </div>
  );
};

export default Menu;
