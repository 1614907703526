import {
  faEnvelope,
  faInbox,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Button from "./Button";
import TextInput from "./TextInput";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authenticate } from "../redux/actions/authAction";

const Login = (props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const auth = useSelector((state) => state.Auth);
  const dispatch = useDispatch();
  const doAuth = () => {
    dispatch(authenticate({ email, password }));
  };
  useEffect(() => {
    if (auth.accessToken != "") {
      props.setShowLogin(false);
    }
  }, [auth.accessToken]);

  return (
    <div className="bg-GrayBg  rounded-2xl w-full md:w-2/5 p-4 fixed z-[120px]">
      <div className="grid grid-cols-4">
        <div className="cursor-pointer col-span-3">
          <img
            src={`${process.env.PUBLIC_URL}/images/logo-dark.png`}
            style={{ width: "150px" }}
          />
        </div>
        <div
          className="cursor-pointer col-span-1"
          onClick={() => props.setShowLogin(false)}
        >
          <p className="text-right">X</p>
        </div>
      </div>
      <div className="my-2">
        <p className="text-xl font-bold font-serif my-2">Sign in</p>
        {auth.authError != "" ? (
          <p className="text-sm text-center" style={{ color: "red" }}>
            {auth.authError}
          </p>
        ) : null}
        <div>
          <TextInput
            name="email"
            label="Email"
            onChange={(event) => setEmail(event.target.value)}
          />
        </div>
        <div>
          <TextInput
            name="password"
            label="Password"
            type="password"
            onChange={(event) => setPassword(event.target.value)}
          />
        </div>

        <div
          className="my-2"
          onClick={() => {
            props.setShowLogin(false);
            props.setShowForgotPassword(true);
          }}
        >
          <p className="text-sm my-2 px-4 text-AspireBrandBlue cursor-pointer ">
            {"Forgot Password"}
          </p>
        </div>
      </div>
      <div className="flex flex-row w-full justify-center items-center  my-2">
        <div className="flex justify-center items-center">
          <Button
            label="Sign in"
            onClick={() => {
              doAuth();
            }}
          />
        </div>
      </div>
      <div
        className="flex flex-row w-full  my-2"
        onClick={() => props.setShowSignup(true)}
      >
        <p className="text-sm my-2">New user?</p>{" "}
        <p className="text-sm my-2 pl-2 text-AspireBrandBlue cursor-pointer">
          Sign up
        </p>
      </div>
    </div>
  );
};

export default Login;
