import React, { useState } from "react";
import BlogsCard from "../components/BlogCard";
//import BlogsCard from "../components/BlogsCard";
import Template from "../components/Template";
import styles from "../components/styles/Blog.module.css";
const Blogs = () => {
    const [active, setActive] = useState('Blogs')
    const courseData = [
        {
            title: "How Arabic demanding the modern world?",
            author: "Saad Mohamed",
            timestamp: "10 days ago",
            image: "https://images.unsplash.com/photo-1659301254614-8d6a9d46f26a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80"
        },
        {
            title: "How Arabic demanding the modern world?",
            author: "Saad Mohamed",
            timestamp: "10 days ago",
            image: "https://images.unsplash.com/photo-1659301254614-8d6a9d46f26a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80"
        },
        {
            title: "How Arabic demanding the modern world?",
            author: "Saad Mohamed",
            timestamp: "10 days ago",
            image: "https://images.unsplash.com/photo-1659301254614-8d6a9d46f26a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80"
        }
    ]
    return (
        <Template>
            <div className={styles.fullW}>
                <div className="flex flex-row justify-center">
                    <div className="flex flex-row flex-shrink-0">
                        <div className={`mr-5 cursor-pointer py-3 ${active == 'Blogs' ? 'text-AspireBrandBlue border-b-4' : ''}`} onClick={() => setActive('Blogs')}>
                            <p className="font-medium text-xs md:text-base">Blogs</p>
                        </div>
                        <div className={`mx-11 font-medium text-xs md:text-base cursor-pointer py-3 ${active == 'News' ? 'text-AspireBrandBlue border-b-4' : ''}`} onClick={() => setActive('News')}>
                            <p>News</p>
                        </div>
                    </div>
                </div>
                <div className={styles.gridR}>
                    {courseData.map((data, index) => (
                        <BlogsCard title={data.title} index={index} image={data.image} author={data.author} timestamp={data.timestamp} />
                    ))}
                </div>
            </div>
        </Template>
    )
}

export default Blogs;