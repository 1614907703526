import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Button from "../components/Button";
import Cart from "../components/Cart";
import SecondaryButton from "../components/SecondaryButton";
import ShippingAddress from "../components/ShippingAddress";
import Template from "../components/Template";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProduct } from "../redux/actions/productsAction";
import { addToCart } from "../redux/actions/cartAction";
import Swal from 'sweetalert2';

const ProductDetail = () => {
    const { id } = useParams()
    const [index, setIndex] = useState(0)
    const [showCart, setShowCart] = useState(false)
    const [showAddress, setShowAddress] = useState(false)
    const user = useSelector(state=>state.Auth.accessToken)
    const product = useSelector(state => state.Products.selectedProduct);
    const cartItems = useSelector(state => state.Cart.items);
    const cartItemCount = cartItems.length
    console.log(cartItemCount)
    console.log(user)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getProduct(id))
    }, [id])
    // const addCart = async (body) => {
    //     dispatch(addToCart(body))
    // }
    const addCart = async (body) => {
        if (!user) {
            // Show SweetAlert2 box to login first
            // const MySwal = withReactContent(Swal);
            Swal.fire({
                icon: 'warning',
                title: 'Login Required',
                text: 'Please login first to add the product to your cart.',
            });
        } else {
            // User is logged in, add the product to the cart
            dispatch(addToCart(body));
        }
    };
    return (
        <Template>
            <div onClick={() => { setShowCart(true) }} className='flex fixed justify-between items-center bg-AspireBrandBlue rounded-l-2xl py-3 px-4  cursor-pointer  right-0 top-[200px] z-[100]'>
            {cartItemCount > 0 && (
    <span className="absolute -top-2 -right-2  rounded-full px-2 py-1 text-White text-4xl">
      {cartItemCount}
    </span>
  )}
                <FontAwesomeIcon icon={faCartShopping} className="text-White" />
                
                <p className="text-sm text-White px-2">Cart</p>
               
            </div>
            <div className='px-5 md:px-72 py-16 md:pt-36'>
                <div className="block md:hidden">
                    <img className="rounded-2xl w-[394px] h-[394px]" src={product.image[index]} />
                    <div className="mt-2"><p className="text-xl md:text-xl font-bold">{product.productName}</p></div>

                    <div className="flex flex-row item-center mt-2">
                        <p className="text-xl mr-2 font-bold">₹{product.sellingPrice}</p>
                        <p className="text-base text-lightText line-through mr-2">₹{product.mrp}</p>
                        <p className="text-base text-AspireBlue1 mr-2">{Math.round(((product.mrp - product.sellingPrice)/product.mrp)*100)}% OFF)</p>
                    </div>
                    <div className="mt-4">
                        <ul className="list-disc px-5">
                            <li className="text-base text-lightText font-light my-1">{product.productDescription}</li>
                        </ul>
                    </div>
                    <div className='flex flex-row justify-between item-center my-4'>
                        <SecondaryButton label="Add to cart" onClick={() => { addCart({productId:product._id, qty:1}) }} />
                        <Button label='Buy Now' onClick={() => { }} />
                    </div>
                </div>

                <div className="hidden md:block">
                    <div className="grid grid-cols-2 gap-8">
                        <div>
                            <img className="rounded-2xl w-full h-[524px]" src={product.image[index]} />
                            <div className="flex flex-row gap-4 mt-4">
                                {product.image.map((image, i) => (
                                    <div onClick={() => setIndex(i)} className={index == i ? "border-2 border-AspireBlue1 rounded-2xl" : ""}  >
                                        <img className="rounded-2xl w-[124px] h-[124px]" src={product.image[i]} />
                                    </div>
                                ))}

                            </div>
                        </div>
                        <div>
                            <div className="mt-2"><p className="text-xl md:text-xl font-bold">{product.productName}</p></div>

                            <div className="flex flex-row item-center mt-4">
                                <p className="text-2xl mr-2 font-bold">₹{product.sellingPrice}</p>
                                <p className="text-base text-lightText line-through mr-2">₹{product.mrp}</p>
                                <p className="text-base text-AspireBlue1 mr-2">({Math.round(((product.mrp - product.sellingPrice)/product.mrp)*100)}% OFF)</p>
                            </div>
                            <div className="mt-8">
                                <ul className="list-disc px-5">
                                    <p className="text-base text-lightText font-light my-1 text-justify">{product.productDescription}</p>
                                    
                                </ul>
                            </div>
                            <div className='flex flex-row gap-4 item-center my-8'>
                                <SecondaryButton label="Add to cart" onClick={() => { addCart({productId:product._id, qty:1}) }} />
                                <Button label='Buy Now' onClick={() => { setShowAddress(true) }} />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className={`fixed ${showAddress ? "" : "hidden"} inset-0 bg-Black bg-opacity-50 overflow-y-auto h-full w-full`}>
                <div className='flex justify-center my-24 md:my-28'>
                    <ShippingAddress setShowAddress={setShowAddress} />
                </div>
            </div>
            <div className={`fixed ${showCart ? "" : "hidden"} inset-0 bg-Black bg-opacity-50 overflow-y-auto h-full w-full`}>
                <div className='flex justify-center my-24 md:my-28'>
                    <Cart setShowCart={setShowCart} setShowAddress={setShowAddress} />
                </div>
            </div>
            
        </Template>
    )
}

export default ProductDetail