import React from "react"
import Template from "../components/Template"

const BlogDetail = () => {
    return (
        <Template>
            <div className='flex  item-center'>
                <div className='px-5 md:px-72 pt-36 pb-4'>
                    <div className="flex flex-row justify-between">
                        <div>
                            <p className="text-4xl font-bold font-serif mb-16">How Arabic changing the modern world?</p>
                            <p className="font-base font-light text-justify text-wrap">05-Feb-2023 - Saad Mohamed </p>
                        </div>
                    </div>

                </div>
            </div>
            <div className="flex flex-row justify-between px-5 md:px-72 ">
                <div className="">
                    <p className="text font-bold font-serif mt-16 mb-4">What is Lorem ipsum?</p>
                    <p className="font-title text-justify text-wrap">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type </p>
                    <div className="rounded-2xl mt-8">
                        <img className="rounded-2xl w-full" src={`${process.env.PUBLIC_URL}/images/map.png`} />
                    </div>
                </div>
            </div>
            <div className="flex flex-row justify-between px-5 md:px-72 ">
                <div className="">
                    <p className="text font-bold font-serif mt-16 mb-4">What is Lorem ipsum?</p>
                    <p className="font-title text-justify text-wrap">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type </p>
                    <div className="flex flex-row juatify-center mr-4">
                        <div className="rounded-2xl mt-8">
                            <img className="rounded-2xl w-full" src={`${process.env.PUBLIC_URL}/images/map.png`} />
                        </div>
                        <div className="rounded-2xl mt-8 ml-4">
                            <img className="rounded-2xl w-full" src={`${process.env.PUBLIC_URL}/images/map.png`} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-row justify-between px-5 md:px-72 mb-10 ">
                <div className="">
                    <p className="text font-bold font-serif mt-16 mb-4">What is Lorem ipsum?</p>
                    <p className="font-title text-justify text-wrap">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type </p>
            
                </div>
            </div>
        </Template>
    )
}

export default BlogDetail;