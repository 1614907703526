import { faCreditCard, faLanguage, faLocation, faStopwatch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Button from './Button'
import SecondaryButton from './SecondaryButton'

const BlogsCard = (props) => {
    const { title, author, timestamp, image, index } = props
    return (
        <>
            <div className={`rounded-3xl hidden md:block  drop-shadow-[0_4px_6px_2px_rgba(51,61,80,0.1)]`}>
                <div>
                    <img src={image} className="rounded-3xl" />
                </div>
                <div className='p-2'>
                    <div className='mb-2'>
                        <p className='font-medium text-base md:text-2xl text-title'>{title}</p>
                    </div>
                </div>
                <div className='flex flex-row my-2 mx-2 gap-4'>
                    <p className='text-lightText font-light text-sm'>{timestamp}</p>
                    <p className='text-lightText font-light text-sm'>-</p>
                    <p className='text-lightText font-light text-sm'>{author}</p>
                </div>
            </div>
            <div className={`block md:hidden block grid grid-cols-3 mt-4 drop-shadow-[0_4px_6px_2px_rgba(51,61,80,0.1)]`}>
                <div className='grid grid-cols-1'>
                    <img src={image} className="rounded-2xl"  />
                </div>
                <div className='grid grid-cols-1 col-span-2 gap-4 mx-4'>
                    <div>
                        <div className=''>
                            <span className='font-medium text-base md:text-base text-title'>{title}</span>
                        </div>
                    </div>
                    <div className='flex flex-row gap-2'>
                        <p className='text-lightText font-light text-sm'>{timestamp}</p>
                        <p className='text-lightText font-light text-sm'>-</p>
                        <p className='text-lightText font-light text-sm'>{author}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogsCard;
