import Swal from 'sweetalert2';
import Api from '../../lib/api'

const {  ADD_ADMISSIONS, ADD_ADMISSIONS_FAILURE } = require("./types");




export const doAdmission = (body, navigate) => async dispatch => {
    
  try {
      const response = await Api.post(body, '/admission/add');
      if (response.success) {
          dispatch({
              type: ADD_ADMISSIONS,
              payload: response.data
          })
          Swal.fire({
            title: 'Admission Successful.',
            html: '<p>Thank you!</p>',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'OK',
          }).then((result) => {
            if (result.isConfirmed) {
              // Perform actions when the user clicks "OK"
              window.location.href = "/";
            }
          });
      } else {
          dispatch({
              type: ADD_ADMISSIONS_FAILURE,
              payload: response.data
          })
      }
  } catch (e) {
      dispatch({
          type: ADD_ADMISSIONS_FAILURE,
          payload: e.message
      })
  }

}