import React from "react";

const ProgressBar = ({ value }) => {
  const fillStyle = {
    width: `${value}%`,
    background: '#11497e', // Replace 'blue' with the desired fill color
  };
    return (
      <div className="w-full h-2 bg-gray-200 rounded">
      <div className="h-full rounded" style={fillStyle}></div>
    </div>
    );
  };

export default ProgressBar;