import Api from "../../lib/api";
import {
  GET_COURSES,
  GET_COURSES_FAILURE,
  GET_COURSE_DETAIL,
  GET_COURSE_DETAIL_FAILURE,
} from "./types";

export const getAllCourses = () => async (dispatch) => {
  try {
    const response = await Api.get("/course");
    if (response.success) {
      dispatch({
        type: GET_COURSES,
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: GET_COURSES_FAILURE,
        payload: response.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_COURSES_FAILURE,
      payload: err.message,
    });
  }
};

export const getCourse = (id) => async (dispatch) => {
  try {
    const response = await Api.get("/course/" + id);
    if (response.success) {
      dispatch({
        type: GET_COURSE_DETAIL,
        payload: response.data,
      });
    } else {
      dispatch({
        type: GET_COURSE_DETAIL_FAILURE,
        payload: response.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_COURSE_DETAIL_FAILURE,
      payload: err.message,
    });
  }
};
