import {
  faEnvelope,
  faInbox,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Button from "./Button";
import TextInput from "./TextInput";
import { useDispatch, useSelector } from "react-redux";
import { doSignup } from "../redux/actions/authAction";

const Signup = (props) => {
  const auth = useSelector((state) => state.Auth);
  const dispatch = useDispatch();
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    password: "",
    confirmPassword: "",
  });

  const updateForm = (event) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  };
  const toggleModal = () => {
    props.setShowSignup(false);
    props.setShowLogin(true);
  };
  const register = () => {
    dispatch(doSignup(user, toggleModal));
  };

  return (
    <div className="bg-GrayBg  rounded-2xl w-full md:w-2/5 p-4 fixed z-[120px]">
      <div className="grid grid-cols-4">
        <div className="cursor-pointer col-span-3">
          <img
            src={`${process.env.PUBLIC_URL}/images/logo-dark.png`}
            style={{ width: "150px" }}
          />
        </div>
        <div
          className="cursor-pointer col-span-1"
          onClick={() => props.setShowSignup(false)}
        >
          <p className="text-right">X</p>
        </div>
      </div>
      <div className="my-2">
        <p className="text-xl font-bold font-serif my-2">Sign up</p>
        {auth.authError != "" ? (
          <p className="text-sm text-center" style={{ color: "red" }}>
            {auth.registerError}
          </p>
        ) : null}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <TextInput
              name="firstName"
              label="First Name"
              type="Email"
              onChange={(event) => updateForm(event)}
            />
          </div>
          <div>
            <TextInput
              name="lastName"
              label="Last Name"
              type="Mobile Number"
              onChange={(event) => updateForm(event)}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <TextInput
              name="email"
              label="Email"
              type="Email"
              onChange={(event) => updateForm(event)}
            />
          </div>
          <div>
            <TextInput
              name="mobileNumber"
              label="Phone number"
              type="Mobile Number"
              onChange={(event) => updateForm(event)}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <TextInput
              type="password"
              name="password"
              label="Password"
              onChange={(event) => updateForm(event)}
            />
          </div>
          <div>
            <TextInput
              type="password"
              name="confirmPassword"
              label="Confirm Password"
              onChange={(event) => updateForm(event)}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row w-full justify-center items-center  my-2">
        <div className="flex justify-center items-center">
          <Button
            label="Sign up"
            onClick={() => {
              register();
              toggleModal();
            }}
          />
        </div>
      </div>
      <div className="flex flex-row w-full  my-2" onClick={() => toggleModal()}>
        <p className="text-sm my-2">Already have an account?</p>{" "}
        <p className="text-sm my-2 pl-2 text-AspireBrandBlue cursor-pointer">
          Sign in
        </p>
      </div>
    </div>
  );
};

export default Signup;
