import React, { useState } from "react";
import Analytics from "../components/Analytics";
import Communities from "../components/Communities";
import Courses from "../components/Courses";
import Events from "../components/Events";
import Hero from "../components/Hero";
import Template from "../components/Template";
import Testimonial from "../components/Testimonial";
import WhyAspire from "../components/WhyAspire";
import Carousel from "../components/Carousel";
import CourseProgressCard from "../components/CourseProgressCard";
import image1 from "../static/images/Banners-1.jpg";
import image2 from "../static/images/Banners-2.jpg";
import image3 from "../static/images/Banners-3.jpg";
import Affiliations from "../components/Affiliations";


const Home = () => {
  window.scrollTo(0, 0);
  const images = [
    image1,
    image2,
    image3,
    // Add more images as needed
  ];

  return (
    <Template>
      <Carousel images={images} />

      {/* <Hero /> */}
      <Analytics />
      <Courses limit={3} isHomePage={true} />

      <Communities />
      <Events />

      <WhyAspire />
      <Testimonial />
      <Affiliations />
    </Template>
  );
};

export default Home;
