import { faCalendar, faCreditCard, faLanguage, faLocation, faStopwatch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Button from './Button'
import SecondaryButton from './SecondaryButton'
import moment from 'moment'
import { pathOr } from 'ramda'

const EventCard = (props) => {
    const { title, time, location, image, date, index, active } = props
    return (
        <>
            <div className={`rounded-3xl hidden flex-shrink-0 w-[300px] md:w-[580px] md:flex flex-row drop-shadow-[0_4px_6px_2px_rgba(51,61,80,0.1)] border border-Stroke p-2 mr-4 w-6/12`}>
                <div>
                    <img src={image} className="rounded-3xl" style={{ width: 256, height: 256 }} />
                </div>
                <div className='p-2'>
                    <div className='mb-8'>
                        <p className='font-medium text-base md:text-2xl text-title'>{title}</p>
                    </div>
                    <div className='flex flex-row py-2 items-center'>
                        <FontAwesomeIcon size='text-sm' icon={faCalendar} className="mr-1 text-title" />
                        <p className='text-sm text-title'>{moment(pathOr("",['0'],date)).format('DD-MMM-YYYY') + " to " + moment(pathOr("",['1'],date)).format('DD-MMM-YYYY')}</p>
                    </div>
                    <div className='flex flex-row py-2 items-center'>
                        <FontAwesomeIcon size='text-sm' icon={faStopwatch} className="mr-1 text-title" />
                        <p className='text-sm text-title'>{pathOr("",['0'],time) + " to " + pathOr("",['1'],time)}</p>
                    </div>
                    <div className='flex flex-row py-2 items-center'>
                        <FontAwesomeIcon size='text-sm' icon={faLocation} className="mr-1 text-title" />
                        <p className='text-sm text-title'>{location}</p>
                    </div>
                    <div className='flex flex-row justify-end self-end item-end my-2 mx-2'>
                        <SecondaryButton label={active == 'Past Events' ? "Play" : "View"} onClick={() => { }} />
                    </div>
                </div>
            </div>
            <div className={`rounded-3xl md:hidden flex-shrink-0 w-[300px] md:w-[580px] flex flex-col drop-shadow-[0_4px_6px_2px_rgba(51,61,80,0.1)] border border-Stroke p-2 mr-4 w-6/12`}>
                <div className='flex flex-row'>
                    <div>
                        <img src={image} className="rounded-3xl" style={{ width: 115, height: 64 }} />
                    </div>
                    <div className='p-2'>
                        <div className='mb-8'>
                            <p className='font-medium text-base md:text-2xl text-title'>{title}</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='flex flex-row py-2 items-center'>
                        <FontAwesomeIcon size='text-sm' icon={faCalendar} className="mr-1 text-title" />
                        <p className='text-sm text-title'>{moment(pathOr("",['0'],date)).format('DD-MMM-YYYY') + " to " + moment(pathOr("",['1'],date)).format('DD-MMM-YYYY')}</p>
                    </div>
                    <div className='flex flex-row py-2 items-center'>
                        <FontAwesomeIcon size='text-sm' icon={faStopwatch} className="mr-1 text-title" />
                        <p className='text-sm text-title'>{pathOr("",['0'],time) + " to " + pathOr("",['1'],time)}</p>
                    </div>
                    <div className='flex flex-row py-2 items-center'>
                        <FontAwesomeIcon size='text-sm' icon={faLocation} className="mr-1 text-title" />
                        <p className='text-sm text-title'>{location}</p>
                    </div>
                    <div className='flex flex-row justify-center self-center item-center my-2 mx-2'>
                        <SecondaryButton label={active == 'Past Events' ? "Play" : "View"} onClick={() => { }} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default EventCard;
