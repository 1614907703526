import React from "react";
import styles from "./styles/whyaspire.module.css"
const WhyAspire = () => {
    return (
        <div className='bg-GrayBg'>
             <div className={styles.fullW} style={{ padding: "50px 0" }}>
                <div className="mr-8">
                    <p className="text-2xl font-serif font-bold">Why Aspire ?</p>
                </div>
                <div className="grid grid cols-1 md:grid-cols-2 gap-4 md:gap-8 flex items-center">
                    <div className="mr-2">

                        <div className="my-4">
                            <p className="text-sm font-sans text-justify">All the usual answers leap to mind, but what Aspire does most of all is that it builds a love for learning in the students that lasts long time even after the student has left the college yards.</p>
                        </div>
                        <div className="my-4">
                            <p className="text-sm font-sans text-justify">The college offers academic excellence, Positive social values, creative freedom & self-discovery that equips every student with necessary skill sets to take his place anywhere in the world. What makes that possible is our scholars from the renowned university of Madina, having imbibed the Islamic values & the necessary knowledge that urge the students to question the false beliefs / theories, challenge them, validate hypothesis & employ analytical reasoning.</p>
                        </div>
                    </div>
                    <div className="rounded-3xl">
                        <iframe className="w-full rounded-3xl" style={{height:360}} src="https://www.youtube.com/embed/3nAmAiAne68" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default WhyAspire