import React, { useState, useCallback } from "react";
import Template from "../components/Template";
import ImageViewer from "react-simple-image-viewer";
import Header from "../components/Header";
import Footer from "../components/Footer";

import Campus1 from "../static/images/Campus1.jpg";
import Campus2 from "../static/images/Campus2.jpg";
import Campus3 from "../static/images/Campus3.jpg";
import Campus4 from "../static/images/Campus4.jpg";
import Campus5 from "../static/images/Campus5.jpg";
import Elearning1 from "../static/images/Elearning-Initiative-1.jpg";
import Elearning2 from "../static/images/Elearning-Initiative-2.jpg";
import PeaceExpo1 from "../static/images/Peace-Expo-1.jpg";
import PeaceExpo2 from "../static/images/Peace-Expo-2.jpg";
import PeaceExpo3 from "../static/images/Peace-Expo-3.jpg";
import PeaceExpo4 from "../static/images/Peace-Expo-4.jpg";
import PeaceExpo5 from "../static/images/Peace-Expo-5.jpg";
import PeaceExpo6 from "../static/images/Peace-Expo-6.jpg";
import PeaceExpo7 from "../static/images/Peace-Expo-7.jpg";
import PeaceExpo8 from "../static/images/Peace-Expo-8.jpg";
import Workshop1 from "../static/images/Workshop-on-Business-Ethics-1.jpg";
import Workshop2 from "../static/images/Workshop-on-Business-Ethics-2.jpg";

const Gallery = () => {
  window.scrollTo(0, 0);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("all"); // Default category

  const categories = [
    // { label: "All", value: "all" },
    { label: "Campus", value: "campus" },
    { label: "E-Learning", value: "elearning" },
    { label: "Peace Expo", value: "peaceexpo" },
    { label: "Workshop", value: "workshop" },
  ];

  const images = [
    {
      category: "campus",
      src: Campus1,
    },
    {
      category: "campus",
      src: Campus2,
    },
    {
      category: "campus",
      src: Campus3,
    },
    {
      category: "campus",
      src: Campus4,
    },
    {
      category: "campus",
      src: Campus5,
    },
    {
      category: "elearning",
      src: Elearning1,
    },
    {
      category: "elearning",
      src: Elearning2,
    },
    {
      category: "peaceexpo",
      src: PeaceExpo1,
    },
    {
      category: "peaceexpo",
      src: PeaceExpo2,
    },
    {
      category: "peaceexpo",
      src: PeaceExpo3,
    },
    {
      category: "peaceexpo",
      src: PeaceExpo4,
    },
    {
      category: "peaceexpo",
      src: PeaceExpo5,
    },
    {
      category: "peaceexpo",
      src: PeaceExpo6,
    },
    {
      category: "peaceexpo",
      src: PeaceExpo7,
    },
    {
      category: "peaceexpo",
      src: PeaceExpo8,
    },
    {
      category: "workshop",
      src: Workshop1,
    },
    {
      category: "workshop",
      src: Workshop2,
    },
    // Add more images with their respective categories
  ];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const filteredImages =
    selectedCategory === "all"
      ? images
      : images.filter((img) => img.category === selectedCategory);

  return (
    <div>
      <Template>
      <div className="">
        <h1 className="font-bold text-2xl text-center mt-6">Gallery</h1>
      </div>
      <div className="flex justify-center">
        <div>
        <div className="flex justify-center space-x-4 mb-4">
  {categories.map((category) => (
    <button
      key={category.value}
      onClick={() => setSelectedCategory(category.value)}
      className={`${
        selectedCategory === category.value
          ? "bg-#07467A text-white"
          : "bg-gray-300 text-gray-700"
      } px-4 py-2 rounded-full hover:bg-#07467A hover:text-white`}
      style={{
        backgroundColor:
          selectedCategory === category.value ? "#07467A" : undefined, color:"#acacac"
      }}
    >
      {category.label}
    </button>
  ))}
</div>

          <div className="grid grid-cols-4 gap-12">
            {filteredImages.map((img, index) => (
              <img
                src={img.src}
                onClick={() => openImageViewer(index)}
                width="300"
                key={index}
                style={{ margin: "2px" }}
                alt=""
              />
            ))}
          </div>
        </div>
      </div>

      {isViewerOpen && (
        <ImageViewer
          src={filteredImages.map((img) => img.src)}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={true}
        />
      )}
      </Template>
    </div>
  );
};

export default Gallery;
