import {
  faAddressCard,
  faChevronDown,
  faCircle,
  faCreditCard,
  faFile,
  faStar,
  faStopwatch,
  faCartShopping,
} from "@fortawesome/free-solid-svg-icons";
import Cart from "../components/Cart";
import ShippingAddress from "../components/ShippingAddress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Button from "../components/Button";
import CourseCard from "../components/CourseCard";
import ReviewCard from "../components/ReviewCard";
import SecondaryButton from "../components/SecondaryButton";
import Template from "../components/Template";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { getCourse } from "../redux/actions/courseAction";
import { addToCart } from "../redux/actions/cartAction";
import AdmissionForm from "../components/AdmissionForm";
import { getCourseData } from "../data/courseDetails";

import TestiIcon from "../static/images/testi-icon.png";

const CourseDetail = () => {
  window.scrollTo(0, 0);
  // const { course, detail } = useSelector((state) => state.Courses.course);
  const courseDetails = getCourseData();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // console.log(singleCourse);

  const { id } = useParams();
  const parsedId = parseInt(id);
  console.log(id);

  const [showCart, setShowCart] = useState(false);
  const [showAddress, setShowAddress] = useState(false);

  const cartItems = useSelector((state) => state.Cart.items);
  const cartItemCount = cartItems.length;

  // const handleEnrollClick = () => {
  //   setShowAdmission(true);
  // };
  // const handleCloseEnrollClick = () => {
  //   setShowAdmission(false);
  // };
  const singleCourse = courseDetails.find((course) => course.id === parsedId);
  console.log(singleCourse);

  // Trigger the file download
  const handleDownload = (pdfPath) => {
    const link = document.createElement("a");
    link.href = pdfPath;
    link.target = "_blank"; // Open the link in a new tab/window
    link.rel = "noopener noreferrer";
    link.download = pdfPath.substring(pdfPath.lastIndexOf("/") + 1);
    link.click();
  };

  // useEffect(() => {
  //    const getCourse=()=>{
  //       return
  //    }

  //    getCourse()
  //    console.log(getCourse())
  // }, [id]);

  const addCart = async (body) => {
    dispatch(addToCart(body));
  };

  //Payment
  const handleBuyNow = async () => {
    try {
      const response = await fetch("/create-order", {
        method: "POST",
      });

      const order = await response.json();

      window.location.href = order.checkout_url;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const reviews = [
    {
      image: TestiIcon,
      name: "Maryam (مريم)",
      qualification: "Bachelors in Islamic Studies",
      comment:
        '"I love the variety of courses offered and the flexibility of the platform. I can learn at my own pace and on my own time, which has been a game-changer for me.',
      date: "11-02-2023",
    },
    {
      image: TestiIcon,
      name: "Maryam (مريم)",
      qualification: "Bachelors in Islamic Studies",
      comment:
        '"I love the variety of courses offered and the flexibility of the platform. I can learn at my own pace and on my own time, which has been a game-changer for me.',
      date: "11-02-2023",
    },
    {
      image: TestiIcon,
      name: "Maryam (مريم)",
      qualification: "Bachelors in Islamic Studies",
      comment:
        '"I love the variety of courses offered and the flexibility of the platform. I can learn at my own pace and on my own time, which has been a game-changer for me.',
      date: "11-02-2023",
    },
  ];
  return (
    <Template>
     
     <div className='flex flex-col md:flex-row bg-[url("/images/course-banner.png")] bg-cover'>
  {/* Course Image (Mobile and Tablet View) */}
  <div className="w-full md:hidden">
    <img
      className="rounded-2xl w-full"
      src={singleCourse?.image}
      alt="Course Image"
    />
  </div>
  {/* Content */}
  <div className="px-5 md:px-60 py-16 pt-12 pb-16">
    <div className="flex flex-row justify-between">
      {/* Course Details */}
      <div className="w-full md:w-2/5">
        <p className="text-4xl font-bold font-serif mb-8">
          {singleCourse?.title}
        </p>
        <p className="font-title text-justify text-wrap">
          {singleCourse?.desc}
        </p>
        <div className="mt-8 flex flex-row items-center">
          {/* <p className="mr-4 font-bold">4.9</p> */}
          {[...Array(5)].map((_, index) => (
            <FontAwesomeIcon key={index} icon={faStar} />
          ))}
        </div>
        <div className="mt-4 flex flex-row">
          <div className="mr-12">
            <SecondaryButton
              label="Download Brochure"
              onClick={() => handleDownload(singleCourse?.brochurePdf)}
            />
          </div>
          <Button
            label="Apply Now"
            onClick={() => navigate("/admission-form")}
          />
        </div>
      </div>
      {/* Course Image (Desktop View) */}
      <div className="w-2/5 hidden md:block">
        <img
          className="rounded-2xl w-full h-full"
          src={singleCourse?.image}
          alt="Course Image"
        />
      </div>
    </div>
  </div>
</div>
      


      <div className="px-5 md:px-72 mb-10 mt-10 overflow-x-auto">
        <div>
          <p className="text-2xl font-bold mb-8">Value you get</p>
          <div className="flex flex-wrap lg:flex-row flex-col">
            {singleCourse.values.map((value) => (
              <div className="flex-shrink-0 flex my-4 lg:mr-6 items-center">
                <div className="bg-AspireBlue4 rounded-full flex justify-center items-center mr-4 p-2">
                  <FontAwesomeIcon icon={faStopwatch} className="" />
                </div>
                <div className="mr-6">
                  <p className="mb-1">{value.heading}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className=" px-5 md:px-64 my-10">
        <div className="border border-Stroke rounded-md">

        <img
          className="rounded-2xl w-full h-full"
          src={singleCourse?.certificate}
          alt="Course Image"
        />
         
        </div>
        <div className="mt-10">
          <p className="text-l text-center">
            Certificate will be provided after completing the course.
          </p>
        </div>
        <div className="mt-10">
          <p className="text-l text-center">{singleCourse.certificateDesc}</p>
        </div>
      </div>
      <section className="dark:bg-gray-800 dark:text-gray-100 md:px-64 ">
        <div className=" max-w-5xl py-12 mx-auto">
          <div className="grid gap-4 mx-4 sm:grid-cols-12">
            <div className="relative col-span-12 px-4 space-y-6 sm:col-span-9">
              <div className="col-span-12 space-y-12 relative px-4 sm:col-span-8 sm:space-y-8 sm:before:absolute sm:before:top-2 sm:before:bottom-0 sm:before:w-0.5 sm:before:-left-3 before:dark:bg-gray-700 before:bg-AspireBlue3">
                {singleCourse?.curriculum.map((detail) => {
                  return (
                    <div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:dark:bg-violet-400 before:bg-AspireBlue3">
                      <h3 className="text-xl font-semibold tracking-wide my-2">
                        {detail.title}
                      </h3>
                      <time className="text-xs tracking-wide dark:text-gray-400 my-2">
                        {detail.desc}
                      </time>
                      {detail?.learningUnits.map((unit) => {
                        return (
                          <div className="flex flex-row justify-between bg-AspireBlueHover rounded-xl p-4 mt-3">
                            <div>
                              <p>{unit}</p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="px-5 md:px-72 mt-10 mb-20">
        <div>
          <p className="text-2xl font-bold mb-8">
            World-class faculties and Mentors
          </p>
        </div>
        <div className="flex flex-row justify-between overflow-x-auto ">
          {singleCourse.faculties &&
            singleCourse.faculties.map((faculty) => (
              <div className="bg-GrayBg flex flex-row mr-4 p-8 rounded-2xl">
                <div className="p-4 w-[200px] md:w-6/12">
                  <div className="rounded-xl flex justify-center">
                    <img
                      className="rounded-xl"
                      src={faculty.img}
                      style={{ width: 120, height: 120 }}
                    />
                  </div>
                  <div className="my-4">
                    <p className="text-center font-bold text-sm">
                      {faculty.name}
                    </p>
                  </div>
                  <div className="my-2">
                    <p className="text-xs text-center  text-wrap text-title">
                      {faculty.degree.toString()}
                    </p>
                  </div>
                  <div className="my-2">
                    <p className="text-xs text-center  text-wrap text-title">
                      {faculty.experience} of Experience
                    </p>
                  </div>
                </div>
                <div className="p-4 w-6/12 hidden md:block">
                  <p>About Faculty</p>
                  <p className="text-xs leading-5 text-wrap text-title text-justify">
                    {faculty.about}
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div> */}
      <div className="px-5 md:px-64 mt-10 mb-20 ">
        <p className="font-bold text-2xl text-center">Course Highlights</p>
        <div className="px-1 md:px-64 mt-10 mb-20">
          <div className="border border-Stroke my-4 ">
            <p className="text-center font-bold text-xl my-4 mt-16">
              {/* {course?.final_price} */}
            </p>
            <p className="my-4 text-center text-sm text-title">
              {/* {detail?.title} */}
            </p>
            <div className="flex justify-center my-8">
              <img
                src={`${process.env.PUBLIC_URL}/images/logo-dark.png`}
                style={{ width: "150px" }}
              />
            </div>
            {singleCourse.programFee &&
              singleCourse.programFee.map((el) => (
                <div className="flex flex-row justify-left my-4">
                  <div className="mx-4">
                    <FontAwesomeIcon
                      icon={faCircle}
                      className="text-AspireBlue3"
                    />
                  </div>
                  <div className="text-sm text-title">{el}</div>
                </div>
              ))}

            <div className="flex justify-center mb-16">
              <Button
                label="Enquire Now"
                onClick={() => {
                  navigate("/enquiry-form");
                }}
              ></Button>
              {/* <Button label="Enquire Now" onClick={()=>setShowAdmission(true)}></Button> */}
            </div>
          </div>
        </div>
      </div>
      <div className="px-5 md:px-20 lg:px-72 mt-10">
        <p className="font-bold text-2xl">Students Review</p>
        <div className="mb-2">
          <span className="text-title py-3">Amazing feedback we got from</span>
        </div>
        <div className="mb-2 grid grid-cols-1 gap-4 md:grid-cols-3 sm:grid-cols-1">
          {singleCourse.reviews.map((data, index) => (
            
            <ReviewCard
              name={data.name}
              index={index}
              image={data.image}
              qualification={data.qualification}
              comment={data.comment}
              date={data.date}
            />
           
          ))}
        </div>
      </div>
      <div
        className={`fixed ${
          showAddress ? "" : "hidden"
        } inset-0 bg-Black bg-opacity-50 overflow-y-auto h-full w-full`}
      >
        <div className="flex justify-center my-24 md:my-28">
          <ShippingAddress setShowAddress={setShowAddress} />
        </div>
      </div>
      <div
        className={`fixed ${
          showCart ? "" : "hidden"
        } inset-0 bg-Black bg-opacity-50 overflow-y-auto h-full w-full`}
      >
        <div className="flex justify-center my-24 md:my-28">
          <Cart setShowCart={setShowCart} setShowAddress={setShowAddress} />
        </div>
      </div>
    </Template>
  );
};

export default CourseDetail;
