import React, { useState } from "react";
import Template from "../components/Template";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";
import ProgressBar from "../components/ProgressBar";
import { useSelector } from "react-redux";

const CourseProgress = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState("Inprogress");
  const [value, setValue] = useState(0);
  const courses = useSelector((state) => state.LMS.courses);
  const personalInfo = useSelector((state) => state.LMS.personalInfo);
  

  console.log(courses);
  console.log(personalInfo);
  // console.log(progress)

  const progress = useSelector((state) => state.LMS.progress.map(course => ({
    ...course,
    course_id: course.course_id.replace(/-/g, " ").toUpperCase()
  })));
  console.log(progress);
  const activeCompletedCourses = progress.filter(
    (course) => course.status === "completed"
  );
  const activeInProgressCourses = progress.filter(course => active === "Inprogress" && (course.status === "not_started" || course.status === "not_completed"));
  // const courses = [
  //   {
  //     course: {
  //       title: "Sample Course 1",
  //       id: 1,
  //     },
  //   },
  //   {
  //     course: {
  //       title: "Sample Course 2",
  //       id: 2,
  //     },
  //   },
  //   // Add more course objects as needed
  // ];

  return (
    <Template>
      <div className="flex items-center bg-[url('/images/banner.png')] bg-cover">
        <div className="pt-36 md:pb-12 px-5 md:px-72">
          <div>
            <p className="text-4xl font-serif font-bold">
              Hello, {personalInfo.email}
            </p>
            <p className="text-4xl font-serif font-bold">Welcome Back</p>
          </div>
        </div>
      </div>
      <div className="px-5 md:px-72 py-10 md:py-4">
        <div className="flex flex-row justify-between py-3 overflow-x-auto">
          <div className="flex flex-row flex-shrink-0">
            <div
              className={`mr-5 cursor-pointer py-3 ${
                active === "Inprogress" ? "text-AspireBrandBlue border-b-4" : ""
              }`}
              onClick={() => setActive("Inprogress")}
            >
              <span className="font-medium text-xs md:text-base">
                In progress
              </span>
            </div>
            <div
              className={`mx-11 cursor-pointer py-3 ${
                active === "Completed" ? "text-AspireBrandBlue border-b-4" : ""
              }`}
              onClick={() => setActive("Completed")}
            >
              <span className="font-medium text-xs md:text-base">
                Completed
              </span>
            </div>
          </div>
        </div>
        <div>
          {active === "Completed"
            ? activeCompletedCourses.map((course, index) => (
                <div
                  key={index}
                  className="w-full border-2 border-GrayBg rounded-2xl my-4 shadow-lg relative z-10"
                >
                  <div className="flex flex-row w-full">
                    <div className="p-2">
                      <img
                        className="rounded-2xl w-[124px] h-[124px]"
                        src="https://images.pexels.com/photos/7249294/pexels-photo-7249294.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                        alt="Course Thumbnail"
                      />
                    </div>
                    <div className="flex flex-col align-center justify-between px-4 py-2 w-full">
                      <p className="text-xs py-1">Course {index + 1}</p>
                      <p className="text-xl py-1">{course.course_id}</p>
                      <p className="text-xs py-1">Feb 2023 - Sep 2023</p>
                      <div>
                        <div className="h-2 w-full flex flex-row items-center space-x-2">
                          <ProgressBar value={course.progress_rate} />
                          <span className="text-xs">
                            {course.progress_rate}%
                          </span>
                          <div className="relative right-0 ml-10">
                            
                          </div>
                        </div>
                      </div>
                      <p className="text-xs py-1">Overall Progress</p>
                    </div>
                  </div>
                </div>
              ))
            : activeInProgressCourses.map((course, index) => {
                return (
                  <div className="w-full border-2 border-GrayBg rounded-2xl my-4 shadow-lg relative z-10">
                    <div className="flex flex-row w-full">
                      <div className="p-2">
                        <img
                          className="rounded-2xl w-[124px] h-[124px]"
                          src="https://images.pexels.com/photos/7249294/pexels-photo-7249294.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                          alt="Course Thumbnail"
                        />
                      </div>
                      <div className="flex flex-col align-center justify-between px-4 py-2 w-full">
                        <p className="text-xs py-1">Course {index + 1}</p>
                        <p className="text-xl py-1">{course.course_id}</p>
                        <p className="text-xs py-1">Feb 2023 - Sep 2023</p>
                        <div>
                          <div className="h-2 w-full flex flex-row items-center space-x-2">
                            <ProgressBar value={course.progress_rate} />
                            <span className="text-xs">
                              {course.progress_rate}%
                            </span>
                            <div className="relative right-0 ml-10">
                              <Button
                                label="Continue"
                                onClick={() => {
                                  const formattedId = course.course_id
                                    .toLowerCase()
                                    .replace(/\s+/g, "-");
                                  const updatedCourse = {
                                    ...course,
                                    course_id: formattedId,
                                  };
                                  navigate("/lms/" + formattedId);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <p className="text-xs py-1">Overall Progress</p>
                      </div>
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
    </Template>
  );
};

export default CourseProgress;
