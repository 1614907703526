import React from "react";
import Template from "../components/Template";
import ContactUs from "../components/ContactUs";

export default function EnquiryForm() {
  window.scrollTo(0, 0);
  return (
    <div>
      <Template>
        <div className="relative left-0 w-screen h-screen flex items-center justify-center  bg-white pt-36 md:pb-12 px-5 md:px-72">
          <ContactUs />
        </div>
      </Template>
    </div>
  );
}
