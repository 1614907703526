import React from "react";
import styles from "./styles/testimonial.module.css";
import Image1 from "../static/images/TNOU-logo.png";
import Image2 from "../static/images/MSU-logo.png";
import Image3 from "../static/images/ISIP-logo.png";
import Image4 from "../static/images/NCAE-logo.png";

const Affiliations = () => {
  return (
    <div className={styles.fullW} style={{ padding: "30px 0" }}>
      <div className="mb-2">
        <p className="text-2xl font-serif font-bold">Affiliations</p>
      </div>
      <div className="mb-2">
        <span className="text-title py-3">Affiliated with Universities</span>
      </div>
    <div className="flex">
      <div className="w-1/4">
        <img src={Image1} alt="Image 1" className="w-full h-auto" />
      </div>
      <div className="w-1/4">
        <img src={Image2} alt="Image 2" className="w-full h-auto" />
      </div>
      <div className="w-1/4">
        <img src={Image3} alt="Image 3" className="w-full h-auto" />
      </div>
      <div className="w-1/4">
        <img src={Image4} alt="Image 4" className="w-full h-auto" />
      </div>
    </div>
    </div>
  );
};

export default Affiliations;
