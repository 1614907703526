import psychology from "../../src/static/images/psychology.jpg";
import bais from "../../src/static/images/bais.jpg";
import puc from "../../src/static/images/puc.jpg";
import basicarabic from "../../src/static/images/basic-arabic-diploma.png";
import intermediatearabic from "../../src/static/images/intermediate-arabic-diploma.png";
import advancearabic from "../../src/static/images/advance-arabic-diploma.png";
import islamicinheritance from "../../src/static/images/islamic-inheritance.png";
import zakahmaster from "../../src/static/images/zakah-master.png";
import khulafarashideen from "../../src/static/images/khulafa-rashideen.png";
import ahlussunnah from "../../src/static/images/ahlus-sunnah.png";
import IslamicPsychology from "../../src/static/images/Islamic-psychology.png";
import mais from "../../src/static/images/mais.jpg";
import bba from "../../src/static/images/bba-oncampus.jpg";
import bbaonline from "../../src/static/images/bba-online.jpg";
import BIS from "../../src/static/pdf/brochure/BIS.pdf";
import MIS from "../../src/static/pdf/brochure/MIS.pdf";
import PUC from "../../src/static/pdf/brochure/PUC.pdf";
import psychologyPdf from "../../src/static/pdf/brochure/psychology.pdf";

import BaisOncampusCert from "../../src/static/images/certificates/BAIS on campus.png";
import advancearabicCert from "../../src/static/images/certificates/Advanced Arabic Diploma.png";
import AhlusCert from "../../src/static/images/certificates/Ahlus Sunnah Wal Jamah Aqeedah.png";
import BscOncampusCert from "../../src/static/images/certificates/B.SC. Psychology on campus.png";
import BscOnlineCert from "../../src/static/images/certificates/B.Sc. Psychology online.png";
import BaisOnlineCert from "../../src/static/images/certificates/BAIS online.png";
import BasicArabicCert from "../../src/static/images/certificates/Basic Arabic Diploma.png";
import IntermediateArabicCert from "../../src/static/images/certificates/Intermediate Arabic Diploma.png";
import IslamicInheritanceCert from "../../src/static/images/certificates/Islamic Inheritance.png";
import KhulafaRashideenCert from "../../src/static/images/certificates/Khulafa Rashideen.png";
import MAISoncampusCert from "../../src/static/images/certificates/MAIS on campus.png";
import MAISonlineCert from "../../src/static/images/certificates/MAIS online.png";
import PUConcampusCert from "../../src/static/images/certificates/PUC on campus.png";
import PUConlineCert from "../../src/static/images/certificates/PUC online.png";
import ZakahMasterClassCert from "../../src/static/images/certificates/Zakah Master Class.png";
import BBACert from "../../src/static/images/certificates/BBA on campus.png";
import BBACertOnline from "../../src/static/images/certificates/BBA online.png";

import TestiIcon from "../static/images/testi-icon.png";

export const getCourseData = () => {
  return [
    {
      id: 1,
      title: "Bachelor of Islamic Studies",
      desc: "This course is comprised of a study of the fundamental issues in the Science of Tawheed/ ‘Aqeedah most relevant today. It gives an overview of the categories of Tawheed and Shirk.",
      duration: "36 Months (3 Yrs)",
      location: "Chennai | Bengaluru",
      language: "",
      offer: "Financial Support",
      image: bais,
      certificate: BaisOncampusCert,
      certificateDesc:
        "Deepen your knowledge, unlock diverse career paths. Job opportunities: Educator, Researcher, Community Advisor, Translator, Da’ee, Pursue higher studies and much more, YOU NAME IT!!",
      slug: "bachelor-of-islamic-studies",
      programFee: [
        "Proficiency in Arabic for text engagement",
        "Navigate legal rulings with Fiqh expertise",
        "Quranic insights through Tafseer studies",
        "Seerah & Hadith studies enhance Sunnah grasp",
        "Contribute to the ummah via enriched Islamic knowledge",
      ],
      brochurePdf: BIS,
      values: [
        {
          heading: "Academic support from experienced teachers",

          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "Become an Aalim/Aalimah in just 3 years",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "Extracurricular fun within Islamic values",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "Safe environment for girls",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
      ],

      curriculum: [
        {
          title: "First Year",
          desc: "Gain a deep understanding of Islamic principles, legal rulings, the life of the Prophet, and develop proficiency in Arabic.",
          learningUnits: ["Aqeedah", "Fiqh", "Seerah", "Arabic", "English"],
        },
        {
          title: "Second Year",
          desc: "Second Year Provides you with a comprehensive understanding of Islamic law, Quranic interpretation, Arabic language skills, and an insightful perspective on the human psyche.",
          learningUnits: [
            "Fiqh",
            "Usool At Tafseer",
            "Arabic",
            "Hadhees & Usool Al Hadhees",
            "EVS",
            "Psychology",
            "English",
          ],
        },
        {
          title: "Third Year",
          desc: "Provides students with a well-rounded understanding of the historical development of Islam, the principles and foundations of Islamic law, advanced proficiency in Arabic language, and a strong ethical framework for engaging in business practices.",
          learningUnits: [
            "Islamic History",
            "Usool ul Fiqh",
            "Islamic Phylosophy",
            "Advanced Arabic",
            "Business Ethics",
          ],
        },
      ],
      faculties: [
        {
          id: 1,
          name: "Dr. Mohammad Saad",
          degree: [
            "M-Tech Electrical Engineering",
            "PhD- Computer Science",
            "IIT Madras",
          ],
          experience: "25 Years",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type Lorem. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum ha",
          img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
        {
          id: 2,
          name: "Dr. Mohammad Saad",
          degree: [
            "M-Tech Electrical Engineering",
            "PhD- Computer Science",
            "IIT Madras",
          ],
          experience: "25 Years",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type Lorem. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum ha",
          img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
      ],
      reviews: [
        {
          image: TestiIcon,
          name: "Hafeel Ahmed",
          qualification: "Bachelors in Islamic Studies",
          comment:
            '"The best place where you can gain knowledge and tarbiyyah with authentic scholars is Aspire college. Personally, I really appreciate how my Arabic teachers have always been supportive and helped me improve my grasp of the language',
          date: "11-02-2023",
        },
        {
          image: TestiIcon,
          name: "Aafila Fathima",
          qualification: "Bachelors in Islamic Studies",
          comment:
            "I joined this course to learn about islam but not only did I learn about Allah's deen but I also learnt how to lead a life in dunya, Alhamdulillah!",
          date: "11-02-2023",
        },
        {
          image: TestiIcon,
          name: "Abdul Nazeer",
          qualification: "Bachelors in Islamic Studies",
          comment:
            "Alhamdulillah, Aspire possesses authentic scholars with sound aqeedah and good knowledge in all fundamentals (Usool). The college provides good facilities for students and are supporting in all situations.",
          date: "11-02-2023",
        },
      ],
    },
    {
      id: 2,
      title: "B.Sc. Psychology",
      desc: "Students are taught English, basics of psychology, biological psychology and the foundations of sociology. This is in addition to them being taught the correct understanding of the Islamic principles, legal rulings and the life of the Prophet",
      duration: "36 Months (3 Yrs)",
      location: "Chennai | Bengaluru",
      language: "",
      offer: "Financial Support",
      image: psychology,
      brochurePdf: BIS,
      certificateDesc:
        "Diverse career paths. Job opportunities: Counselor, Researcher, Human Resources, Therapist, Educator, Researcher, Community Advisor, Pursue higher studies and much more, YOU NAME IT!!",
      certificate: BscOncampusCert,
      slug: "bsc-psychology",
      programFee: [
        "Gain insights into fundamental psychological principles and theories",
        "Explore the interplay between biology and behaviour",
        "Study psychological disorders and their treatment approaches",
        "Learn to provide guidance and psychological support",
        "Develop foundational skills for effective counseling interactions",
      ],
      values: [
        {
          heading: "Academic support from expert teachers",

          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "Faith-Based Guidance",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "Extracurricular fun within Islamic values",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "Safe environment for girls",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
      ],

      curriculum: [
        {
          title: "First Semester",
          desc: "Students are taught English, basics of psychology, biological psychology and the foundations of sociology. This is in addition to them being taught the correct understanding of the Islamic principles, legal rulings and the life of the Prophet",
          learningUnits: [
            "Arabic",
            "Aqeedah",
            "General Psychology - I",
            "Biological Psychology - I",
            "Educational Psychology",
          ],
        },
        {
          title: "Second Semester",
          desc: "Students gain insight into sports psychology, psychological statistics, fitness and wellness, experimental and counseling psychology. Along with this the students are also taught Islamic law, Qur'anic interpretation and the different schools of jurisprudence in Islam.",
          learningUnits: [
            "Arabic - II",
            "Aqeedah - II",
            "General Psychology - II",
            "Biological Psychology - II",
            "Health Psychology",
          ],
        },
        {
          title: "Third Semester",
          desc: "The syllabus ensures comprehensive coverage of abnormal psychology, health psychology and organizational behaviour. The students are also introduced to environmental studies, positive and social psychology. Other subjects include rehabilitation psychology, educational psychology and value education.",
          learningUnits: [
            "Usool ut Tafseer",
            "Usool Ul Fiqh",
            "Developmental Psychology - I",
            "Research Methods & Statistics",
            "Learner's Choice - CBCS",
          ],
        },

        {
          title: "Fourth Semester",
          desc: "The syllabus ensures comprehensive coverage of abnormal psychology, health psychology and organizational behaviour. The students are also introduced to environmental studies, positive and social psychology. Other subjects include rehabilitation psychology, educational psychology and value education.",
          learningUnits: [
            "Hadith & Usool ul Hadith",
            "Islamic Jurisprudence",
            "Developmental Psychology-II",
            "Experimental Psychology-I (Practical)",
            "Life Skills Education",
          ],
        },
        {
          title: "Fifth Semester",
          desc: "The syllabus ensures comprehensive coverage of abnormal psychology, health psychology and organizational behaviour. The students are also introduced to environmental studies, positive and social psychology. Other subjects include rehabilitation psychology, educational psychology and value education.",
          learningUnits: [
            "Legal Maxims and Objectives of Islamic Legislation",
            "Social Psychology - I",
            "Guidance & Counselling Psychology",
            "Industrial /Organizational Psychology",
            "Learner's Choice - CBCS",
            "Abnormal Psychology - I",
          ],
        },
        {
          title: "Sixth Semester",
          desc: "The syllabus ensures comprehensive coverage of abnormal psychology, health psychology and organizational behaviour. The students are also introduced to environmental studies, positive and social psychology. Other subjects include rehabilitation psychology, educational psychology and value education.",
          learningUnits: [
            "Abnormal Psychology-II",
            "Social Psychology - II",
            "Human Resource Development",
            "Experimental Psychology-II",
            "Basic Counselling Skills",
          ],
        },
      ],
      faculties: [
        {
          id: 1,
          name: "Dr. Mohammad Saad",
          degree: [
            "M-Tech Electrical Engineering",
            "PhD- Computer Science",
            "IIT Madras",
          ],
          experience: "25 Years",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type Lorem. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum ha",
          img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
        {
          id: 2,
          name: "Dr. Mohammad Saad",
          degree: [
            "M-Tech Electrical Engineering",
            "PhD- Computer Science",
            "IIT Madras",
          ],
          experience: "25 Years",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type Lorem. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum ha",
          img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
      ],
      reviews: [
        {
          image: TestiIcon,
          name: "Sayema Ryan",
          qualification: "Bachelors in Psychology",
          comment:
            "I am very much delighted and really so so happy to see my results and it has been a great and a wonderful experience knowing and learning this particular course. It was a wonderful journey! Alhamdulillah",
          date: "11-02-2023",
        },
        {
          image: TestiIcon,
          name: "Hazira Fathima",
          qualification: "Bachelors in Psychology",
          comment:
            "The doors of khair have opened upon us through this source in achieving duniya n Aakhirah and benefiting through different webinars n special lectures too.. Alhamdulillah!",
          date: "11-02-2023",
        },
        {
          image: TestiIcon,
          name: "Shamina Umm Abdullah",
          qualification: "Bachelors in Psychology",
          comment:
            "I'm blessed to learn an authentic knowledge from aspire. Which actually helped me personally to shape myself better . I learnt how to deal certain situations in my life.",
          date: "11-02-2023",
        },
      ],
    },
    {
      id: 3,
      title: "Master in Islamic Studies",
      desc: "This course explores the rich tapestry of Islamic knowledge through in-depth study of Islamic Civilization, Quranic Sciences, Shariah Law, Comparative Religion, and conduct a research-based Dissertation. Gain advanced expertise in Islamic scholarship, research methodology, and critical analysis to contribute to the academic understanding of Islam and its contemporary relevance",
      duration: "24 Months (2 Yrs)",
      location: "Chennai | Bengaluru",
      language: "",
      offer: "Financial Support",
      image: mais,
      brochurePdf: BIS,
      certificate: MAISoncampusCert,
      certificateDesc:
        "Deepen your knowledge, unlock diverse career paths. Job opportunities: Educator, Researcher, Community Advisor, Translator, Da’ee, Pursue higher studies and much more, YOU NAME IT!!",
      slug: "master-in-islamic-studies",
      programFee: [
        "In-depth exploration of Prophetic Narrations for scholarly insight",
        "Understand ethical principles integral to Islamic teachings",
        "Acquire skills for scholarly research in Islamic and Arabic studies",
        "Gain insights into historical and contemporary Muslim world dynamics",
        "Analyse religions and Muslim sects in a global context",
      ],
      values: [
        {
          heading: "Academic support from dedicated teachers",

          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "Deepen your Knowledge",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "Extracurricular fun within Islamic values",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "Safe environment for girls",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
      ],

      curriculum: [
        {
          title: "First Year",
          desc: "Offers an in-depth exploration of Hadeeth Sciences, Jurisprudence, Arabic Language, Islamic Morals, and Research Methodology, equipping graduates with advanced knowledge and research skills for scholarly pursuits in the field of Islam.",
          learningUnits: [
            "Sciences of Hadeeth (Prophetic Narrations) (1st year)",
            "Islamic Jurisprudence (1st year)",
            " Arabic Language (1st year)",
            "Principles of Islamic Islamic Morals and the Methodology of Propogating (1st year)",
            " Research Methodology in Islamic & Arabic Studies (1st year)",
          ],
        },
        {
          title: "Second Year",
          desc: "Offers profound insights into Islamic Civilization and the Contemporary Muslim World, learn Quranic Sciences and the study of Legal Maxims and Objectives of Shariah. Engage in a Comparative Study of Religions and Muslim Sects, and culminate your expertise with a comprehensive Dissertation.",
          learningUnits: [
            "Islamic Civilization and Contemporary Muslim World ( 2nd Year)",
            "Quranic Sciences (2nd year)",
            "Legal Maxims and Objectives of Islamic Legislation (Shariah) (2nd year)",
            "Comparative Study of Religions and Muslim Sects (2nd year)",
            "Dissertation (2nd year)",
          ],
        },
      ],
      faculties: [
        {
          id: 1,
          name: "Dr. Mohammad Saad",
          degree: [
            "M-Tech Electrical Engineering",
            "PhD- Computer Science",
            "IIT Madras",
          ],
          experience: "25 Years",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type Lorem. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum ha",
          img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
        {
          id: 2,
          name: "Dr. Mohammad Saad",
          degree: [
            "M-Tech Electrical Engineering",
            "PhD- Computer Science",
            "IIT Madras",
          ],
          experience: "25 Years",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type Lorem. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum ha",
          img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
      ],
      reviews: [
        {
          image: TestiIcon,
          name: "Mohammad Yusuf",
          qualification: "Masters in Islamic Studies",
          comment: `Knowledge and Tarbiyyah go hand in hand and at Aspire college of Excellence I found a blend like no other. Scholars who have graduated from renowned Islamic Universities in Makkah, Madinah and India ensure that only authentic Islamic knowledge is imparted. `,
          date: "11-02-2023",
        },
        {
          image: TestiIcon,
          name: "Ummay Habiba",
          qualification: "Masters in Islamic Studies",
          comment: `Aspire has given me an amazing opportunity to study under the scholars. It is a place where the young generation get nourished through Tarbiyyah, Alhamdulillah!`,
          date: "11-02-2023",
        },
        {
          image: TestiIcon,
          name: "K. Shehnaz",
          qualification: "Masters in Islamic Studies",
          comment:
            "Knowing something beneficial and correct but with no ability to stop something wrong is not useful. Aspire strengthened my knowledge and confidence and helped me to deal with my situations in the right way.",
          date: "11-02-2023",
        },
      ],
    },
    {
      id: 4,
      title: "PUC / 12th Std",
      desc: "Seeking to continue your education after the 10th standard? At Aspire College, our in-person PUC program provides a structured and interactive on-campus environment for your 11th and 12th standard education. Additionally, you can study Islamic subjects concurrently, achieving a balance between secular and religious education, all within just 1 year.",
      duration: "12 Months (1 Year)",
      location: "Chennai | Bengaluru",
      language: "",
      offer: "Financial Support",
      image: puc,
      brochurePdf: BIS,
      certificate: PUConcampusCert,
      certificateDesc:
        "You'll get the same value and recognition for completing PUC as you do for successfully completing 12th grade. Pursue higher studies of your choice. Recommended to do BAIS after completion to get the full benefits of doing your PUC at Aspire.",
      slug: "puc",
      programFee: [
        "Save valuable time by completing PUC / 12th Std in just one year",
        "Transition to Undergraduate Degree Program directly after PUC completion",
        "PUC and 12th Std certificates carry equal value and recognition",
        "Strong foundation for both your Academic and Islamic Journey",
        "Ideal preparation for pursuing a Bachelor's in Islamic Studies for in-depth learning",
      ],
      values: [
        {
          heading: "Pre-College Education with Islamic studies",

          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "Academic guidance from expert teachers",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "Extracurricular fun within Islamic values",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "Safe environment for girls",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
      ],

      curriculum: [
        {
          title: "Curriculum",
          desc: "",
          learningUnits: [
            " English",
            " Psychology",
            "Sociology",
            "Introduction to Law",
            "Economics",
            " Computer Applications",
          ],
        },
      ],
      faculties: [
        {
          id: 1,
          name: "Dr. Mohammad Saad",
          degree: [
            "M-Tech Electrical Engineering",
            "PhD- Computer Science",
            "IIT Madras",
          ],
          experience: "25 Years",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type Lorem. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum ha",
          img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
        {
          id: 2,
          name: "Dr. Mohammad Saad",
          degree: [
            "M-Tech Electrical Engineering",
            "PhD- Computer Science",
            "IIT Madras",
          ],
          experience: "25 Years",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type Lorem. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum ha",
          img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
      ],
      reviews: [
        {
          image: TestiIcon,
          name: "Ayisha humairah",
          qualification: "PUC / 12th Std",
          comment:
            "The teachers are knowledgeable and engaging, making the virtual learning environment enjoyable.",
          date: "11-02-2023",
        },
        {
          image: TestiIcon,
          name: "Jannathnisa ",
          qualification: "PUC / 12th Std",
          comment:
            "I highly recommend Aspire College's online PUC classes for anyone seeking a quality pre-university education.",
          date: "11-02-2023",
        },
        {
          image: TestiIcon,
          name: "Noor Ashika",
          qualification: "PUC / 12th Std",
          comment:
            "I can't say enough positive things about Aspire's online PUC classes! The teachers go above and beyond to ensure we grasp the concepts thoroughly..",
          date: "11-02-2023",
        },
      ],
    },

    {
      id: 5,
      title: "Basic Arabic Diploma",
      desc: "Lay a strong foundation in the Arabic language with our Basic Arabic Diploma course. Develop essential skills in reading, writing, listening, and speaking Arabic. Gain proficiency in everyday conversations, expand your vocabulary, and acquire a solid understanding of Arabic grammar.",
      duration: "12 Months (1 Year)",
      location: "Chennai | Bengaluru",
      language: "",
      offer: "Financial Support",
      image: basicarabic,
      brochurePdf: BIS,
      certificate: BasicArabicCert,
      certificateDesc:
        "The certificate attests to your successful completion of the Basic Arabic Diploma program at Aspire College of Excellence.",
      slug: "basic-arabic-diploma",
      programFee: [
        "Hybrid learning experience with Recorded + Live classes",
"Taught by award-winning expert faculty",
"Classes with Advanced teaching equipment",
"Learn from the comfort of your home",
"Start from Basic level - no prior knowledge needed",
      ],
      values: [
        {
          heading: "Qualified and supportive teachers",

          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "Strong Foundation",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "High quality course videos",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "Recognized Certificates",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
      ],

      curriculum: [
        {
          title: "",
          desc: "",
          learningUnits: [" "],
        },
      ],
      faculties: [
        {
          id: 1,
          name: "Dr. Mohammad Saad",
          degree: [
            "M-Tech Electrical Engineering",
            "PhD- Computer Science",
            "IIT Madras",
          ],
          experience: "25 Years",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type Lorem. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum ha",
          img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
        {
          id: 2,
          name: "Dr. Mohammad Saad",
          degree: [
            "M-Tech Electrical Engineering",
            "PhD- Computer Science",
            "IIT Madras",
          ],
          experience: "25 Years",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type Lorem. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum ha",
          img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
      ],
      reviews: [
        {
          image: TestiIcon,
          name: "Fatima Ali",
          qualification: "Diploma Arabic Student",
          comment:
            "MashaAllah, the Intermediate Arabic Diploma course deepened my love for the language and the Deen. Barakallah for such a dedicated teacher who made this journey meaningful.",
          date: "10-06-2023",
        },
        {
          image: TestiIcon,
          name: "Zainab Hassan",
          qualification: "Diploma Arabic Student",
          comment:
            "Alhamdulillah, my heart is content with the Intermediate Arabic course. Can’t wait to see what’s more there in the Advanced program.",
          date: "11-02-2023",
        },
        {
          image: TestiIcon,
          name: "Amirah Rahman",
          qualification: "Diploma Arabic Student",
          comment:
            "Alhamdulillah now I can understand more phrases from the Quran. It’s a different feeling when you can understand the verse that you are reciting. Alhamdulillah Alhamdulillah",
          date: "11-02-2023",
        },
        {
          image: TestiIcon,
          name: "Omar Farooq",
          qualification: "Diploma Arabic Student",
          comment:
            "The ustaadh is very energetic and doesn’t get me bored at all. I love the idea o recorded classes as you can replay a certain topic over and over again until you fully understand. Jazakumullahu Khairan",
          date: "08-07-2023",
        },
      ],
    },

    {
      id: 6,
      title: "Intermediate Arabic Diploma",
      desc: "Take your Arabic skills to the next level with our Intermediate Arabic Diploma course. Build upon your foundational knowledge and enhance your fluency in Arabic. Expand your vocabulary, refine your grammar, and engage in more complex conversations and written expressions. Prepare yourself to communicate effectively in various real-life situations.",
      duration: "12 Months (1 Year)",
      location: "Chennai | Bengaluru",
      language: "",
      offer: "Financial Support",
      image: intermediatearabic,
      brochurePdf: BIS,
      certificate: IntermediateArabicCert,
      certificateDesc:
        "The certificate attests to your successful completion of the Intermediate Arabic Diploma program at Aspire College of Excellence.",
      slug: "basic-arabic-diploma",
      programFee: [
        "Hybrid learning experience with Recorded + Live classes",
"Taught by award-winning expert faculty",
"Classes with Advanced teaching equipment",
"Learn from the comfort of your home",
"Start from Basic level - no prior knowledge needed",
      ],
      values: [
        {
          heading: "Qualified and supportive teachers",

          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "Strong Foundation",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "High quality course videos",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "Recognized Certificates",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
      ],

      curriculum: [
        {
          title: "",
          desc: "",
          learningUnits: [" "],
        },
      ],
      faculties: [
        {
          id: 1,
          name: "Dr. Mohammad Saad",
          degree: [
            "M-Tech Electrical Engineering",
            "PhD- Computer Science",
            "IIT Madras",
          ],
          experience: "25 Years",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type Lorem. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum ha",
          img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
        {
          id: 2,
          name: "Dr. Mohammad Saad",
          degree: [
            "M-Tech Electrical Engineering",
            "PhD- Computer Science",
            "IIT Madras",
          ],
          experience: "25 Years",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type Lorem. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum ha",
          img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
      ],
      reviews: [
        {
          image: TestiIcon,
          name: "Fatima Ali",
          qualification: "Diploma Arabic Student",
          comment:
            "MashaAllah, the Intermediate Arabic Diploma course deepened my love for the language and the Deen. Barakallah for such a dedicated teacher who made this journey meaningful.",
          date: "10-06-2023",
        },
        {
          image: TestiIcon,
          name: "Zainab Hassan",
          qualification: "Diploma Arabic Student",
          comment:
            "Alhamdulillah, my heart is content with the Intermediate Arabic course. Can’t wait to see what’s more there in the Advanced program.",
          date: "11-02-2023",
        },
        {
          image: TestiIcon,
          name: "Amirah Rahman",
          qualification: "Diploma Arabic Student",
          comment:
            "Alhamdulillah now I can understand more phrases from the Quran. It’s a different feeling when you can understand the verse that you are reciting. Alhamdulillah Alhamdulillah",
          date: "11-02-2023",
        },
        {
          image: TestiIcon,
          name: "Omar Farooq",
          qualification: "Diploma Arabic Student",
          comment:
            "The ustaadh is very energetic and doesn’t get me bored at all. I love the idea o recorded classes as you can replay a certain topic over and over again until you fully understand. Jazakumullahu Khairan",
          date: "08-07-2023",
        },
      ],
    },

    {
      id: 7,
      title: "Advance Arabic Diploma",
      desc: "Achieve mastery in the Arabic language with our Advanced Arabic Diploma course. Deepen your understanding of Arabic literature, poetry, and complex grammatical structures. Enhance your ability to express yourself fluently and eloquently in both spoken and written Arabic. Develop advanced comprehension skills and engage with authentic Arabic resources to navigate diverse contexts confidently.",
      duration: "12 Months (1 Year)",
      location: "Chennai | Bengaluru",
      language: "",
      offer: "Financial Support",
      image: advancearabic,
      brochurePdf: BIS,
      certificate: advancearabicCert,
      certificateDesc:
        "The certificate attests to your successful completion of the Advanced Arabic Diploma program at Aspire College of Excellence.",
      slug: "basic-arabic-diploma",
      programFee: [
        "Hybrid learning experience with Recorded + Live classes",
"Taught by award-winning expert faculty",
"Classes with Advanced teaching equipment",
"Learn from the comfort of your home",
"Start from Basic level - no prior knowledge needed",
      ],
      values: [
        {
          heading: "Qualified and supportive teachers",

          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "Strong Foundation",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "High quality course videos",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "Recognized Certificates",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
      ],

      curriculum: [
        {
          title: "First Year",
          desc: "",
          learningUnits: [
            " English",
            " Psychology",
            "Sociology",
            "Introduction to Law",
            "Economics",
            " Computer Applications",
          ],
        },
      ],
      faculties: [
        {
          id: 1,
          name: "Dr. Mohammad Saad",
          degree: [
            "M-Tech Electrical Engineering",
            "PhD- Computer Science",
            "IIT Madras",
          ],
          experience: "25 Years",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type Lorem. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum ha",
          img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
        {
          id: 2,
          name: "Dr. Mohammad Saad",
          degree: [
            "M-Tech Electrical Engineering",
            "PhD- Computer Science",
            "IIT Madras",
          ],
          experience: "25 Years",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type Lorem. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum ha",
          img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
      ],
      reviews: [
        {
          image: TestiIcon,
          name: "Safiya Ahmed",
          qualification: "Diploma Arabic Student",
          comment:
            "Alhamdulillah, I stand fulfilled after completing the Advanced Arabic course. It felt like scaling higher peaks of understanding and faith. The live QnA sessions were like gatherings of inspiration, where fellow students shared insights.",
          date: "10-06-2023",
        },
        {
          image: TestiIcon,
          name: "Imran Qadir",
          qualification: "Diploma Arabic Student",
          comment:
            "Masha Allah feels like I’ve mastered the Arabic Language. My overall journey with Aspire college was pleasing. Barakallahu Feek.",
          date: "11-02-2023",
        },
        {
          image: TestiIcon,
          name: "Yasmin Aziz",
          qualification: "Diploma Arabic Student",
          comment:
            "Deepened my connection with Quran and Arabic books. It was such a honour to study under such a dedicated and talented ustaad. May Allah bless him.",
          date: "11-02-2023",
        },
        {
          image: TestiIcon,
          name: "Rashid Malik",
          qualification: "Diploma Arabic Student",
          comment:
            "When I started learning Arabic, I never thought I’d come this far in understanding the Arabic Language of the Quran. The teacher was so supportive. The classes were so interesting. Just feeling blessed Alhamdulillah.",
          date: "08-07-2023",
        },
      ],
    },

    {
      id: 8,
      title: "Zakah Master Class (with Practical Demo)",
      desc: "Join our Zakah Master Class with Practical Demo for a comprehensive exploration of this important pillar of Islam that is often negelected. Dive deep into the principles, calculations, and distribution of Zakah, guided by experts and scholars. Witness a practical demonstration that brings Zakah to life, allowing you to confidently navigate wealth purification and charitable giving. Elevate your understanding of this essential obligation, empowering you to fulfil it accurately and meaningfully. Enroll now and embark on a transformative journey of knowledge, compassion, and impactful generosity.",
      duration: "12 Months (1 Year)",
      location: "Chennai | Bengaluru",
      language: "",
      offer: "Financial Support",
      image: zakahmaster,
      brochurePdf: BIS,
      certificate: ZakahMasterClassCert,
      certificateDesc:
        "The certificate attests to your successful completion of the Advanced Arabic Diploma program at Aspire College of Excellence.",
      slug: "basic-arabic-diploma",
      programFee: [
        "Explore the essence of Zakah, Islam's vital pillar.",
        "A live run-through of how to calculate your Zakah",
        "Equipped with all the knowledge you need",
        "Calculate amounts due for different types of Zakah",
        "Free Zakah Calculation Sheet provided",
      ],
      values: [
        {
          heading: "Qualified and supportive teachers",

          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "Strong Foundation",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "High quality course videos",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "Recognized Certificates",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
      ],

      curriculum: [
        {
          title: "",
          desc: "",
          learningUnits: [" "],
        },
      ],
      faculties: [
        {
          id: 1,
          name: "Dr. Mohammad Saad",
          degree: [
            "M-Tech Electrical Engineering",
            "PhD- Computer Science",
            "IIT Madras",
          ],
          experience: "25 Years",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type Lorem. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum ha",
          img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
        {
          id: 2,
          name: "Dr. Mohammad Saad",
          degree: [
            "M-Tech Electrical Engineering",
            "PhD- Computer Science",
            "IIT Madras",
          ],
          experience: "25 Years",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type Lorem. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum ha",
          img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
      ],
      reviews: [
        {
          image: TestiIcon,
          name: "Safiya Ahmed",
          qualification: "Diploma Arabic Student",
          comment:
            "Alhamdulillah, I stand fulfilled after completing the Advanced Arabic course. It felt like scaling higher peaks of understanding and faith. The live QnA sessions were like gatherings of inspiration, where fellow students shared insights.",
          date: "10-06-2023",
        },
        {
          image: TestiIcon,
          name: "Imran Qadir",
          qualification: "Diploma Arabic Student",
          comment:
            "Masha Allah feels like I’ve mastered the Arabic Language. My overall journey with Aspire college was pleasing. Barakallahu Feek.",
          date: "11-02-2023",
        },
        {
          image: TestiIcon,
          name: "Yasmin Aziz",
          qualification: "Diploma Arabic Student",
          comment:
            "Deepened my connection with Quran and Arabic books. It was such a honour to study under such a dedicated and talented ustaad. May Allah bless him.",
          date: "11-02-2023",
        },
        {
          image: TestiIcon,
          name: "Rashid Malik",
          qualification: "Diploma Arabic Student",
          comment:
            "When I started learning Arabic, I never thought I’d come this far in understanding the Arabic Language of the Quran. The teacher was so supportive. The classes were so interesting. Just feeling blessed Alhamdulillah.",
          date: "08-07-2023",
        },
      ],
    },

    {
      id: 9,
      title: "The Dynamic Khulafa Rashideen",
      desc: "Explore the Legacy of Khulafa Rashideen, the Rightly Guided Caliphs, in this enlightening course. Uncover the profound teachings, leadership, and contributions of Abu Bakr, Umar, Uthman, and Ali (May Allah be pleased with them), who played pivotal roles in shaping early Islamic history. Delve into their achievements, governance, and enduring wisdom, gaining a deep appreciation for their role as guardians of Islamic principles. Immerse yourself in the pivotal moments of Islam's foundational period, drawing inspiration from their examples to navigate contemporary challenges and uphold the values of Islam. Join us in uncovering the invaluable lessons of the Khulafa Rashideen for a transformative journey of historical insight and spiritual growth.",
      duration: "12 Months (1 Year)",
      location: "Chennai | Bengaluru",
      language: "",
      offer: "Financial Support",
      image: khulafarashideen,
      brochurePdf: BIS,
      certificate: KhulafaRashideenCert,
      certificateDesc:
        "The certificate attests to your successful completion of the Advanced Arabic Diploma program at Aspire College of Excellence.",
      slug: "basic-arabic-diploma",
      programFee: [
        "Legacy of the Rightly Guided Caliphs",
        "Guidance from Caliphs",
        "Just Governance Strategies",
        "Safeguarding Islamic Values",
        "Get Inspired & Boost your Imaan",
      ],
      values: [
        {
          heading: "Qualified and supportive teachers",

          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "Strong Foundation",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "High quality course videos",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "Recognized Certificates",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
      ],

      curriculum: [
        {
          title: "First Year",
          desc: "",
          learningUnits: [
            " English",
            " Psychology",
            "Sociology",
            "Introduction to Law",
            "Economics",
            " Computer Applications",
          ],
        },
      ],
      faculties: [
        {
          id: 1,
          name: "Dr. Mohammad Saad",
          degree: [
            "M-Tech Electrical Engineering",
            "PhD- Computer Science",
            "IIT Madras",
          ],
          experience: "25 Years",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type Lorem. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum ha",
          img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
        {
          id: 2,
          name: "Dr. Mohammad Saad",
          degree: [
            "M-Tech Electrical Engineering",
            "PhD- Computer Science",
            "IIT Madras",
          ],
          experience: "25 Years",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type Lorem. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum ha",
          img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
      ],
      reviews: [
        {
          image: TestiIcon,
          name: "Safiya Ahmed",
          qualification: "Diploma Arabic Student",
          comment:
            "Alhamdulillah, I stand fulfilled after completing the Advanced Arabic course. It felt like scaling higher peaks of understanding and faith. The live QnA sessions were like gatherings of inspiration, where fellow students shared insights.",
          date: "10-06-2023",
        },
        {
          image: TestiIcon,
          name: "Imran Qadir",
          qualification: "Diploma Arabic Student",
          comment:
            "Masha Allah feels like I’ve mastered the Arabic Language. My overall journey with Aspire college was pleasing. Barakallahu Feek.",
          date: "11-02-2023",
        },
        {
          image: TestiIcon,
          name: "Yasmin Aziz",
          qualification: "Diploma Arabic Student",
          comment:
            "Deepened my connection with Quran and Arabic books. It was such a honour to study under such a dedicated and talented ustaad. May Allah bless him.",
          date: "11-02-2023",
        },
        {
          image: TestiIcon,
          name: "Rashid Malik",
          qualification: "Diploma Arabic Student",
          comment:
            "When I started learning Arabic, I never thought I’d come this far in understanding the Arabic Language of the Quran. The teacher was so supportive. The classes were so interesting. Just feeling blessed Alhamdulillah.",
          date: "08-07-2023",
        },
      ],
    },

    {
      id: 10,
      title: "Islamic Inheritance (with Practical Cases)",
      desc: "Enroll in our comprehensive course to delve into the intricate framework of Islamic inheritance laws. It is a fard kifâyah (communal obligation) to learn it for the obvious objective of being able to distribute the estate of the deceased justly among the heirs, as prescribed by Allah. Analyse Quranic and Hadith sources, studying rightful distributions and legal intricacies. From share calculations to exceptions, gain a deep understanding of this crucial facet of Islamic jurisprudence. Acquire knowledge to ensure equitable wealth distribution among heirs.",
      duration: "12 Months (1 Year)",
      location: "Chennai | Bengaluru",
      language: "",
      offer: "Financial Support",
      image: islamicinheritance,
      brochurePdf: BIS,
      certificate: IslamicInheritanceCert,
      certificateDesc:
        "The certificate attests to your successful completion of the Advanced Arabic Diploma program at Aspire College of Excellence.",
      slug: "basic-arabic-diploma",
      programFee: [
        "Understand how inheritance principles are implemented",
        "Investigate authentic sources to understand the foundations",
        "From theory to practical cases",
        "Learn the methods behind determining rightful shares for inheritors",
        "Receive expert insights and guidance",
      ],
      values: [
        {
          heading: "Qualified and supportive teachers",

          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "Strong Foundation",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "High quality course videos",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "Recognized Certificates",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
      ],

      curriculum: [
        {
          title: "",
          desc: "",
          learningUnits: [" "],
        },
      ],
      faculties: [
        {
          id: 1,
          name: "Dr. Mohammad Saad",
          degree: [
            "M-Tech Electrical Engineering",
            "PhD- Computer Science",
            "IIT Madras",
          ],
          experience: "25 Years",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type Lorem. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum ha",
          img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
        {
          id: 2,
          name: "Dr. Mohammad Saad",
          degree: [
            "M-Tech Electrical Engineering",
            "PhD- Computer Science",
            "IIT Madras",
          ],
          experience: "25 Years",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type Lorem. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum ha",
          img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
      ],
      reviews: [
        {
          image: TestiIcon,
          name: "Safiya Ahmed",
          qualification: "Diploma Arabic Student",
          comment:
            "Alhamdulillah, I stand fulfilled after completing the Advanced Arabic course. It felt like scaling higher peaks of understanding and faith. The live QnA sessions were like gatherings of inspiration, where fellow students shared insights.",
          date: "10-06-2023",
        },
        {
          image: TestiIcon,
          name: "Imran Qadir",
          qualification: "Diploma Arabic Student",
          comment:
            "Masha Allah feels like I’ve mastered the Arabic Language. My overall journey with Aspire college was pleasing. Barakallahu Feek.",
          date: "11-02-2023",
        },
        {
          image: TestiIcon,
          name: "Yasmin Aziz",
          qualification: "Diploma Arabic Student",
          comment:
            "Deepened my connection with Quran and Arabic books. It was such a honour to study under such a dedicated and talented ustaad. May Allah bless him.",
          date: "11-02-2023",
        },
        {
          image: TestiIcon,
          name: "Rashid Malik",
          qualification: "Diploma Arabic Student",
          comment:
            "When I started learning Arabic, I never thought I’d come this far in understanding the Arabic Language of the Quran. The teacher was so supportive. The classes were so interesting. Just feeling blessed Alhamdulillah.",
          date: "08-07-2023",
        },
      ],
    },

    {
      id: 11,
      title: "Ahlus Sunnah Wal Jamah Aqeedah",
      desc: "Enroll in our comprehensive Ahlus Sunnah wal Jamah Aqeedah course, where you will explore the core tenets of Islam with detailed references from the Quran and Sunnah. Immerse yourself in the profound study of beliefs such as belief in Allah, belief in angels, belief in Scriptures, Belief in the Day of Judgement and belief in Qadr (divine destiny). Based on Famous Book, “The Sunnah's Outspread Flags of The Belief of Ahlus Sunnah Wal Jamah”. Join us in this enlightening journey, deepening your insight into the Aqeedah of Ahlus Sunnah wal Jamah.",
      duration: "12 Months (1 Year)",
      location: "Chennai | Bengaluru",
      language: "",
      offer: "Financial Support",
      image: ahlussunnah,
      brochurePdf: BIS,
      certificate: AhlusCert,
      certificateDesc:
        "The certificate attests to your successful completion of the Advanced Arabic Diploma program at Aspire College of Excellence.",
      slug: "basic-arabic-diploma",
      programFee: [
        "Strengthen your core beliefs",
        "Profound exploration of Aqeedah",
        "Based on the book, “The Sunnah's Outspread Flags of The Belief of Ahlus Sunnah Wal Jamah",
        "Cover all essential aspects of Ahlus Sunnah wal Jamah Aqeedah",
        "Deepen your conviction and theological understanding",
      ],
      values: [
        {
          heading: "Qualified and supportive teachers",

          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "Strong Foundation",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "High quality course videos",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "Recognized Certificates",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
      ],

      curriculum: [
        {
          title: "First Year",
          desc: "",
          learningUnits: [
            " English",
            " Psychology",
            "Sociology",
            "Introduction to Law",
            "Economics",
            " Computer Applications",
          ],
        },
      ],
      faculties: [
        {
          id: 1,
          name: "Dr. Mohammad Saad",
          degree: [
            "M-Tech Electrical Engineering",
            "PhD- Computer Science",
            "IIT Madras",
          ],
          experience: "25 Years",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type Lorem. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum ha",
          img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
        {
          id: 2,
          name: "Dr. Mohammad Saad",
          degree: [
            "M-Tech Electrical Engineering",
            "PhD- Computer Science",
            "IIT Madras",
          ],
          experience: "25 Years",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type Lorem. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum ha",
          img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
      ],
      reviews: [
        {
          image: TestiIcon,
          name: "Safiya Ahmed",
          qualification: "Diploma Arabic Student",
          comment:
            "Alhamdulillah, I stand fulfilled after completing the Advanced Arabic course. It felt like scaling higher peaks of understanding and faith. The live QnA sessions were like gatherings of inspiration, where fellow students shared insights.",
          date: "10-06-2023",
        },
        {
          image: TestiIcon,
          name: "Imran Qadir",
          qualification: "Diploma Arabic Student",
          comment:
            "Masha Allah feels like I’ve mastered the Arabic Language. My overall journey with Aspire college was pleasing. Barakallahu Feek.",
          date: "11-02-2023",
        },
        {
          image: TestiIcon,
          name: "Yasmin Aziz",
          qualification: "Diploma Arabic Student",
          comment:
            "Deepened my connection with Quran and Arabic books. It was such a honour to study under such a dedicated and talented ustaad. May Allah bless him.",
          date: "11-02-2023",
        },
        {
          image: TestiIcon,
          name: "Rashid Malik",
          qualification: "Diploma Arabic Student",
          comment:
            "When I started learning Arabic, I never thought I’d come this far in understanding the Arabic Language of the Quran. The teacher was so supportive. The classes were so interesting. Just feeling blessed Alhamdulillah.",
          date: "08-07-2023",
        },
      ],
    },

    {
      id: 12,
      title: "Diploma in Islamic Psychology",
      desc: "Acquire a comprehensive understanding of the human psyche from an Islamic perspective. Learn how to apply psychological theories and techniques within an Islamic framework to address mental health issues, counseling, and personal development. Develop the skills to provide culturally sensitive and spiritually grounded support to the Ummah",
      duration: "12 Months (1 Year)",
      location: "Chennai | Bengaluru",
      language: "",
      offer: "Financial Support",
      image: IslamicPsychology,
      brochurePdf: BIS,
      certificate: BscOnlineCert,
      certificateDesc:
        "The certificate attests to your successful completion of the Islamic Psychology Diploma program at Aspire College of Excellence.",
      slug: "basic-arabic-diploma",
      programFee: [
        "Apply psychological concepts within Islamic context.",
        "Learn from experts with cutting-edge discipline.",
        "Apply Islamic ethics in shaping behavior and choices.",
        "Become a better Muslim and contribute to the Ummah",
        "Recommended for Brides to Be, Married Ladies and Aspiring Relationship Counselors.",
      ],
      values: [
        {
          heading: "Expert Guidance",

          desc: "Learn from highly skilled and experienced faculties, specialized both in Psychology and Islamic Studies",
        },
        {
          heading: "Personal Transformation",
          desc: "Enhance your own self-awareness and personal development",
        },
        {
          heading: "High quality course videos",
          desc: "Engaging and visually appealing course videos. Enjoy a seamless learning experience with crystal-clear visuals and audio, ensuring a high-quality educational experience that captures your attention and enhances comprehension",
        },
        {
          heading: "Professional Recognition",
          desc: "Earn a recognized diploma showcasing your specialized knowledge",
        },
      ],

      curriculum: [
        {
          title: "Curriculum",
          desc: "",
          learningUnits: [
            "Psychopathology",
            "Counseling Techniques",
            "Islamic Studies",
            "Islamic Personality Development",
            "Strengthening Faith",
            "Tips and strategies for raising Children",
            "Conflict Resolution",
            "And more",
          ],
        },
      ],
      faculties: [
        {
          id: 1,
          name: "Dr. Mohammad Saad",
          degree: [
            "M-Tech Electrical Engineering",
            "PhD- Computer Science",
            "IIT Madras",
          ],
          experience: "25 Years",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type Lorem. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum ha",
          img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
        {
          id: 2,
          name: "Dr. Mohammad Saad",
          degree: [
            "M-Tech Electrical Engineering",
            "PhD- Computer Science",
            "IIT Madras",
          ],
          experience: "25 Years",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type Lorem. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum ha",
          img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
      ],
      reviews: [
        {
          image: TestiIcon,
          name: "Fatima Ali",
          qualification: "Diploma in Islamic Psychology",
          comment:
            "Grateful for the enriching journey in Islamic Psychology. A blend of knowledge and spirituality. Alhamdulillah!",
          date: "10-06-2023",
        },
        {
          image: TestiIcon,
          name: "Zainab Hussain",
          qualification: "Diploma in Islamic Psychology",
          comment:
            "A holistic approach that strengthened my faith and my understanding of people. Love the way the teachers implemented Islam into every topic they taught us.",
          date: "11-02-2023",
        },
        {
          image: TestiIcon,
          name: "Maryam",
          qualification: "Diploma in Islamic Psychology",
          comment:
            "An illuminating path to understanding the human psyche within an Islamic context. Such an interesting concept and they executed it beautifully. Jazakumullahu Khair.",
          date: "11-02-2023",
        },
        {
          image: TestiIcon,
          name: "Siddiqa . S",
          qualification: "Diploma in Islamic Psychology",
          comment:
            "A transformative experience. Empowered me to understand minds and souls better. Alhumdhulillah Such a beneficial course that every woman must take. Helped me to work on myself better. Jazakillah",
          date: "08-07-2023",
        },
      ],
    },

    {
      id: 13,
      title: "Bachelor of Islamic Studies (Online)",
      desc: "This course is comprised of a study of the fundamental issues in the Science of Tawheed/ ‘Aqeedah most relevant today. It gives an overview of the categories of Tawheed and Shirk.",
      duration: "36 Months (3 Yrs)",
      location: "Chennai | Bengaluru",
      language: "",
      offer: "Financial Support",
      image: bais,
      certificate: BaisOnlineCert,
      certificateDesc:
        "Deepen your knowledge, unlock diverse career paths. Job opportunities: Educator, Researcher, Community Advisor, Translator, Da’ee, Pursue higher studies and much more, YOU NAME IT!!",
      slug: "bachelor-of-islamic-studies",
      programFee: [
        "Proficiency in Arabic for text engagement",
        "Navigate legal rulings with Fiqh expertise",
        "Quranic insights through Tafseer studies",
        "Seerah & Hadith studies enhance Sunnah grasp",
        "Contribute to the ummah via enriched Islamic knowledge",
      ],
      brochurePdf: BIS,
      values: [
        {
          heading: "Qualified and experienced teachers",

          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "100+ Hours of recording",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "High quality course videos",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "Recognized Certificates",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
      ],

      curriculum: [
        {
          title: "First Year",
          desc: "Gain a deep understanding of Islamic principles, legal rulings, the life of the Prophet, and develop proficiency in Arabic.",
          learningUnits: ["Aqeedah", "Fiqh", "Seerah", "Arabic", "English"],
        },
        {
          title: "Second Year",
          desc: "Second Year Provides you with a comprehensive understanding of Islamic law, Quranic interpretation, Arabic language skills, and an insightful perspective on the human psyche.",
          learningUnits: [
            "Fiqh",
            "Usool At Tafseer",
            "Arabic",
            "Hadhees & Usool Al Hadhees",
            "EVS",
            "Psychology",
            "English",
          ],
        },
        {
          title: "Third Year",
          desc: "Provides students with a well-rounded understanding of the historical development of Islam, the principles and foundations of Islamic law, advanced proficiency in Arabic language, and a strong ethical framework for engaging in business practices.",
          learningUnits: [
            "Islamic History",
            "Usool ul Fiqh",
            "Islamic Phylosophy",
            "Advanced Arabic",
            "Business Ethics",
          ],
        },
      ],
      faculties: [
        {
          id: 1,
          name: "Dr. Mohammad Saad",
          degree: [
            "M-Tech Electrical Engineering",
            "PhD- Computer Science",
            "IIT Madras",
          ],
          experience: "25 Years",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type Lorem. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum ha",
          img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
        {
          id: 2,
          name: "Dr. Mohammad Saad",
          degree: [
            "M-Tech Electrical Engineering",
            "PhD- Computer Science",
            "IIT Madras",
          ],
          experience: "25 Years",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type Lorem. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum ha",
          img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
      ],
      reviews: [
        {
          image: TestiIcon,
          name: "Hafeel Ahmed",
          qualification: "Bachelors in Islamic Studies",
          comment:
            '"The best place where you can gain knowledge and tarbiyyah with authentic scholars is Aspire college. Personally, I really appreciate how my Arabic teachers have always been supportive and helped me improve my grasp of the language',
          date: "11-02-2023",
        },
        {
          image: TestiIcon,
          name: "Aafila Fathima",
          qualification: "Bachelors in Islamic Studies",
          comment:
            "I joined this course to learn about islam but not only did I learn about Allah's deen but I also learnt how to lead a life in dunya, Alhamdulillah!",
          date: "11-02-2023",
        },
        {
          image: TestiIcon,
          name: "Abdul Nazeer",
          qualification: "Bachelors in Islamic Studies",
          comment:
            "Alhamdulillah, Aspire possesses authentic scholars with sound aqeedah and good knowledge in all fundamentals (Usool). The college provides good facilities for students and are supporting in all situations.",
          date: "11-02-2023",
        },
      ],
    },
    {
      id: 14,
      title: "B.Sc. Psychology (Online)",
      desc: "Students are taught English, basics of psychology, biological psychology and the foundations of sociology. This is in addition to them being taught the correct understanding of the Islamic principles, legal rulings and the life of the Prophet",
      duration: "36 Months (3 Yrs)",
      location: "Chennai | Bengaluru",
      language: "",
      offer: "Financial Support",
      image: psychology,
      brochurePdf: BIS,
      certificate: BscOnlineCert,
      certificateDesc:
        "Diverse career paths. Job opportunities: Counselor, Researcher, Human Resources, Therapist, Educator, Researcher, Community Advisor, Pursue higher studies and much more, YOU NAME IT!!",
      slug: "bsc-psychology",
      programFee: [
        "Gain insights into fundamental psychological principles and theories",
        "Explore the interplay between biology and behaviour",
        "Study psychological disorders and their treatment approaches",
        "Learn to provide guidance and psychological support",
        "Develop foundational skills for effective counseling interactions",
      ],
      values: [
        {
          heading: "Exceptional Faculties",

          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "Comprehensive Understanding",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "Faith-Based Guidance",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "Recognized Certificates",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
      ],

      curriculum: [
        // {
        //   title: "First Year",
        //   desc: "Students are taught English, basics of psychology, biological psychology and the foundations of sociology. This is in addition to them being taught the correct understanding of the Islamic principles, legal rulings and the life of the Prophet ",
        //   learningUnits: [
        //     "Foundation in English",
        //     "General Psychology- l & ll",
        //     "Biological Psychology – I & ll",
        //     "Educational Psychology",
        //     "Health Psychology",
        //   ],
        // },
        // {
        //   title: "Second Year",
        //   desc: "Students gain insight into sports psychology, psychological statistics, fitness and wellness, experimental and counseling psychology. Along with this the students are also taught Islamic law, Qur'anic interpretation and the different schools of jurisprudence in Islam.",
        //   learningUnits: [
        //     " Foundation in English",
        //     "Developmental Psychology- l & ll",
        //     "Research Methods & Statistics",
        //     "Experimental Psychology- l (Practical)",
        //     "Life Skills Education",
        //   ],
        // },
        // {
        //   title: "Third Year",
        //   desc: "The syllabus ensures comprehensive coverage of abnormal psychology, health psychology and organizational behaviour. The students are also introduced to environmental studies, positive and social psychology. Other subjects include rehabilitation psychology, educational psychology and value education.",
        //   learningUnits: [
        //     "Abnormal Psychology- l & ll",
        //     "Social Psychology- l & ll",
        //     "Experimental Psychology- ll (Practical)",
        //     "Guidance & Counselling Psychology",
        //     "Industrial / Organizational Psychology",
        //     "Human Resource Development",
        //     "Basic Counselling Skills",
        //   ],
        // },
      ],
      faculties: [
        {
          id: 1,
          name: "Dr. Mohammad Saad",
          degree: [
            "M-Tech Electrical Engineering",
            "PhD- Computer Science",
            "IIT Madras",
          ],
          experience: "25 Years",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type Lorem. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum ha",
          img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
        {
          id: 2,
          name: "Dr. Mohammad Saad",
          degree: [
            "M-Tech Electrical Engineering",
            "PhD- Computer Science",
            "IIT Madras",
          ],
          experience: "25 Years",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type Lorem. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum ha",
          img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
      ],
      reviews: [
        {
          image: TestiIcon,
          name: "Sayema Ryan",
          qualification: "Bachelors in Pshychology",
          comment:
            "I am very much delighted and really so so happy to see my results and it has been a great and a wonderful experience knowing and learning this particular course. It was a wonderful journey! Alhamdulillah",
          date: "11-02-2023",
        },
        {
          image: TestiIcon,
          name: "Hazira Fathima",
          qualification: "Bachelors in Pshychology",
          comment:
            "The doors of khair have opened upon us through this source in achieving duniya n Aakhirah and benefiting through different webinars n special lectures too.. Alhamdulillah!",
          date: "11-02-2023",
        },
        {
          image: TestiIcon,
          name: "Shamina Umm Abdullah",
          qualification: "Bachelors in Islamic Studies",
          comment:
            "I'm blessed to learn an authentic knowledge from aspire. Which actually helped me personally to shape myself better . I learnt how to deal certain situations in my life.",
          date: "11-02-2023",
        },
      ],
    },
    {
      id: 15,
      title: "Master in Islamic Studies (Online)",
      desc: "This course explores the rich tapestry of Islamic knowledge through in-depth study of Islamic Civilization, Quranic Sciences, Shariah Law, Comparative Religion, and conduct a research-based Dissertation. Gain advanced expertise in Islamic scholarship, research methodology, and critical analysis to contribute to the academic understanding of Islam and its contemporary relevance",
      duration: "24 Months (2 Yrs)",
      location: "Chennai | Bengaluru",
      language: "",
      offer: "Financial Support",
      image: mais,
      brochurePdf: BIS,
      certificate: MAISonlineCert,
      certificateDesc:
        "Deepen your knowledge, unlock diverse career paths. Job opportunities: Educator, Researcher, Community Advisor, Translator, Da’ee, Pursue higher studies and much more, YOU NAME IT!!",
      slug: "master-in-islamic-studies",
      programFee: [
        "In-depth exploration of Prophetic Narrations for scholarly insight",
        "Understand ethical principles integral to Islamic teachings",
        "Acquire skills for scholarly research in Islamic and Arabic studies",
        "Gain insights into historical and contemporary Muslim world dynamics",
        "Analyse religions and Muslim sects in a global context",
      ],
      values: [
        {
          heading: "Qualified and Dedicated Teachers",

          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "Deepenend Knowledge",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "High quality course videos",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "Recognized Certificates",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
      ],

      curriculum: [
        {
          title: "First Year",
          desc: "Offers an in-depth exploration of Hadeeth Sciences, Jurisprudence, Arabic Language, Islamic Morals, and Research Methodology, equipping graduates with advanced knowledge and research skills for scholarly pursuits in the field of Islam.",
          learningUnits: [
            "Sciences of Hadeeth (Prophetic Narrations) (1st year)",
            "Islamic Jurisprudence (1st year)",
            " Arabic Language (1st year)",
            "Principles of Islamic Islamic Morals and the Methodology of Propogating (1st year)",
            " Research Methodology in Islamic & Arabic Studies (1st year)",
          ],
        },
        {
          title: "Second Year",
          desc: "Offers profound insights into Islamic Civilization and the Contemporary Muslim World, learn Quranic Sciences and the study of Legal Maxims and Objectives of Shariah. Engage in a Comparative Study of Religions and Muslim Sects, and culminate your expertise with a comprehensive Dissertation.",
          learningUnits: [
            "Islamic Civilization and Contemporary Muslim World ( 2nd Year)",
            "Quranic Sciences (2nd year)",
            "Legal Maxims and Objectives of Islamic Legislation (Shariah) (2nd year)",
            "Comparative Study of Religions and Muslim Sects (2nd year)",
            "Dissertation (2nd year)",
          ],
        },
      ],
      faculties: [
        {
          id: 1,
          name: "Dr. Mohammad Saad",
          degree: [
            "M-Tech Electrical Engineering",
            "PhD- Computer Science",
            "IIT Madras",
          ],
          experience: "25 Years",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type Lorem. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum ha",
          img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
        {
          id: 2,
          name: "Dr. Mohammad Saad",
          degree: [
            "M-Tech Electrical Engineering",
            "PhD- Computer Science",
            "IIT Madras",
          ],
          experience: "25 Years",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type Lorem. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum ha",
          img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
      ],
      reviews: [
        {
          image: TestiIcon,
          name: "Mohammad Yusuf",
          qualification: "Masters in Islamic Studies",
          comment: `Knowledge and Tarbiyyah go hand in hand and at Aspire college of Excellence I found a blend like no other. Scholars who have graduated from renowned Islamic Universities in Makkah, Madinah and India ensure that only authentic Islamic knowledge is imparted. `,
          date: "11-02-2023",
        },
        {
          image: TestiIcon,
          name: "Ummay Habiba",
          qualification: "Masters in Islamic Studies",
          comment: `Aspire has given me an amazing opportunity to study under the scholars. It is a place where the young generation get nourished through Tarbiyyah, Alhamdulillah!`,
          date: "11-02-2023",
        },
        {
          image: TestiIcon,
          name: "K. Shehnaz",
          qualification: "Masters in Islamic Studies",
          comment:
            "Knowing something beneficial and correct but with no ability to stop something wrong is not useful. Aspire strengthened my knowledge and confidence and helped me to deal with my situations in the right way.",
          date: "11-02-2023",
        },
      ],
    },
    {
      id: 16,
      title: "PUC / 12th Std (Online)",
      desc: "Are you looking to continue your education after 10th standard without the constraints of traditional classroom settings? Aspire College offers the perfect solution with its Online PUC program. Whether you have family commitments or other responsibilities, our flexible online classes allow you to balance your personal life while pursuing your 11th and 12th standard education combined in just 1 Year.",
      duration: "12 Months (1 Year)",
      location: "Chennai | Bengaluru",
      language: "",
      offer: "Financial Support",
      image: puc,
      brochurePdf: BIS,
      certificate: PUConlineCert,
      certificateDesc:
        "You'll get the same value and recognition for completing PUC as you do for successfully completing 12th grade. Pursue higher studies of your choice. Recommended to do BAIS after completion to get the full benefits of doing your PUC at Aspire.",
      slug: "puc",
      programFee: [
        "Save valuable time by completing PUC / 12th Std in just one year",
        "Transition to Undergraduate Degree Program directly after PUC completion",
        "PUC and 12th Std certificates carry equal value and recognition",
        "Strong foundation for both your Academic and Islamic Journey",
        "Ideal preparation for pursuing a Bachelor's in Islamic Studies for in-depth learning",
      ],
      values: [
        {
          heading: "Qualified and supportive teachers",

          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "Strong Foundation",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "High quality course videos",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "Recognized Certificates",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
      ],

      curriculum: [
        {
          title: "Curriculum",
          desc: "",
          learningUnits: [
            " English",
            " Psychology",
            "Sociology",
            "Introduction to Law",
            "Economics",
            " Computer Applications",
          ],
        },
      ],
      faculties: [
        {
          id: 1,
          name: "Dr. Mohammad Saad",
          degree: [
            "M-Tech Electrical Engineering",
            "PhD- Computer Science",
            "IIT Madras",
          ],
          experience: "25 Years",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type Lorem. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum ha",
          img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
        {
          id: 2,
          name: "Dr. Mohammad Saad",
          degree: [
            "M-Tech Electrical Engineering",
            "PhD- Computer Science",
            "IIT Madras",
          ],
          experience: "25 Years",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type Lorem. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum ha",
          img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
      ],
      reviews: [
        {
          image: TestiIcon,
          name: "Ayisha humairah",
          qualification: "PUC / 12th Std",
          comment:
            "The teachers are knowledgeable and engaging, making the virtual learning environment enjoyable.",
          date: "11-02-2023",
        },
        {
          image: TestiIcon,
          name: "Jannathnisa ",
          qualification: "PUC / 12th Std",
          comment:
            "I highly recommend Aspire College's online PUC classes for anyone seeking a quality pre-university education.",
          date: "11-02-2023",
        },
        {
          image: TestiIcon,
          name: "Noor Ashika",
          qualification: "PUC / 12th Std",
          comment:
            "I can't say enough positive things about Aspire's online PUC classes! The teachers go above and beyond to ensure we grasp the concepts thoroughly..",
          date: "11-02-2023",
        },
      ],
    },
    {
      id: 17,
      title: "Bachelor of Business Administration (BBA)",
      desc: "Elevate your business acumen with a unique blend of tradition and modernity in our BBA with Islamic Studies program. This innovative course integrates fundamental business principles with a deep understanding of Islamic values and ethics. Explore the dynamic intersections of commerce and faith, gaining insights into ethical business practices guided by Islamic principles. Delve into courses covering business administration, finance, marketing, and management, all while grounding your studies in the rich context of Islamic teachings.",
      duration: "36 Months (3 Year)",
      location: "Chennai | Bengaluru",
      language: "",
      offer: "Financial Support",
      image: bba,
      brochurePdf: BIS,
      certificate: BBACert,
      certificateDesc:
        "Diverse career paths. Job opportunities: Business Analyst, Data Analyst, Marketing Manager, Financial Analyst, Human Resources Specialist, Sales Manager, Entrepreneur Specialist, Advisor, Pursue higher studies and much more, YOU NAME IT!!",
      slug: "bba",
      programFee: [
        "Comprehensive business knowledge",
        "Critical business skills",
        "Wide range of career options",
        "Adaptability to changing business landscape",
        "Entrepreneurial mindset and business creation skills",
        "Gain Islamic knowledge alongside",
      ],
      values: [
        {
          heading: "Academic support from expert teachers",

          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "Integrated with Islamic Studies",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "Extracurricular fun within Islamic values",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "Safe environment for girls",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
      ],

      curriculum: [
        {
          title: "First Semester",
          desc: "",
          learningUnits: [
            "Arabic",
            "Aqeedah",
            "Principles of Management",
            "Financial Accounting",
            "Managerial Economics",
          ],
        },
        {
          title: "Second Semester",
          desc: "",
          learningUnits: [
            "Arabic - II",
"Aqeedah - II",
"Business Organisation",
"Management Accounting",
"Business Statistics",
          ],
        },
        {
          title: "Third Semester",
          desc: "",
          learningUnits: [
            "Usool ut Tafseer",
            "Usool Ul Fiqh",
            "Business Communication",
            "Organisational Behaviour",
            "Financial Management",
            "Marketing Management",
            "Quantitative Methods",
          ],
        },
        {
          title: "Fourth Semester",
          desc: "",
          learningUnits: [
            "Hadith & Usool ul Hadith",
            "Islamic Jurisprudence",
            "Business Environment",
            "Human Resource Management",
            "Operations Management",
            "Computer Applications in Business",
          ],
        },
        {
          title: "Fifth Semester",
          desc: "",
          learningUnits: [
            "Strategic Management",
            "Business Law",
            "Research Methods in Business",
            "Entrepreneurship and Management of Small Business",
            "Learner's Choice - CBCS",
          ],
        },
        {
          title: "Sixth Semester",
          desc: "",
          learningUnits: [
            "Money and Banking",
            "International Business",
            "Financial Market and Services",
            "Introduction to Retailing",
            "E-Business",
          ],
        },
      ],
      faculties: [
        {
          id: 1,
          name: "Dr. Mohammad Saad",
          degree: [
            "M-Tech Electrical Engineering",
            "PhD- Computer Science",
            "IIT Madras",
          ],
          experience: "25 Years",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type Lorem. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum ha",
          img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
        {
          id: 2,
          name: "Dr. Mohammad Saad",
          degree: [
            "M-Tech Electrical Engineering",
            "PhD- Computer Science",
            "IIT Madras",
          ],
          experience: "25 Years",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type Lorem. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum ha",
          img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
      ],
      reviews: [
        {
          image: TestiIcon,
          name: "Hafeel Ahmed",
          qualification: "Bachelor of Business Administration (BBA)",
          comment:
            '"The best place where you can gain knowledge and tarbiyyah with authentic scholars is Aspire college. Personally, I really appreciate how my Arabic teachers have always been supportive and helped me improve my grasp of the language',
          date: "11-02-2023",
        },
        {
          image: TestiIcon,
          name: "Aafila Fathima",
          qualification: "Bachelor of Business Administration (BBA)",
          comment:
            "I joined this course to learn about islam but not only did I learn about Allah's deen but I also learnt how to lead a life in dunya, Alhamdulillah!",
          date: "11-02-2023",
        },
        {
          image: TestiIcon,
          name: "Abdul Nazeer",
          qualification: "Bachelor of Business Administration (BBA)",
          comment:
            "Alhamdulillah, Aspire possesses authentic scholars with sound aqeedah and good knowledge in all fundamentals (Usool). The college provides good facilities for students and are supporting in all situations.",
          date: "11-02-2023",
        },
      ],
    },

    {
      id: 18,
      title: "Bachelor of Business Administration (BBA Online)",
      desc: "Elevate your business acumen with a unique blend of tradition and modernity in our BBA with Islamic Studies program. This innovative course integrates fundamental business principles with a deep understanding of Islamic values and ethics. Explore the dynamic intersections of commerce and faith, gaining insights into ethical business practices guided by Islamic principles. Delve into courses covering business administration, finance, marketing, and management, all while grounding your studies in the rich context of Islamic teachings.",
      duration: "36 Months (3 Year)",
      location: "Chennai | Bengaluru",
      language: "",
      offer: "Financial Support",
      image: bbaonline,
      brochurePdf: BIS,
      certificate: BBACertOnline,
      certificateDesc:
        "Diverse career paths. Job opportunities: Business Analyst, Data Analyst, Marketing Manager, Financial Analyst, Human Resources Specialist, Sales Manager, Entrepreneur Specialist, Advisor, Pursue higher studies and much more, YOU NAME IT!!",
      slug: "bba",
      programFee: [
        "Comprehensive business knowledge",
        "Critical business skills",
        "Wide range of career options",
        "Adaptability to changing business landscape",
        "Entrepreneurial mindset and business creation skills",
        "Gain Islamic knowledge alongside",
      ],
      values: [
        {
          heading: "Academic support from expert teachers",

          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "Integrated with Islamic Studies",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "High quality course videos",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
        {
          heading: "Recognized Certificates",
          desc: "All our Ustaads are qualified and trained in respectable Jaam’iahs, ensuring that students receive high quality education",
        },
      ],

      curriculum: [
        {
          title: "First Semester",
          desc: "",
          learningUnits: [
            "Arabic",
            "Aqeedah",
            "Principles of Management",
            "Financial Accounting",
            "Managerial Economics",
          ],
        },
        {
          title: "Second Semester",
          desc: "",
          learningUnits: [
            "Arabic - II",
"Aqeedah - II",
"Business Organisation",
"Management Accounting",
"Business Statistics",
          ],
        },
        {
          title: "Third Semester",
          desc: "",
          learningUnits: [
            "Usool ut Tafseer",
            "Usool Ul Fiqh",
            "Business Communication",
            "Organisational Behaviour",
            "Financial Management",
            "Marketing Management",
            "Quantitative Methods",
          ],
        },
        {
          title: "Fourth Semester",
          desc: "",
          learningUnits: [
            "Hadith & Usool ul Hadith",
            "Islamic Jurisprudence",
            "Business Environment",
            "Human Resource Management",
            "Operations Management",
            "Computer Applications in Business",
          ],
        },
        {
          title: "Fifth Semester",
          desc: "",
          learningUnits: [
            "Strategic Management",
            "Business Law",
            "Research Methods in Business",
            "Entrepreneurship and Management of Small Business",
            "Learner's Choice - CBCS",
          ],
        },
        {
          title: "Sixth Semester",
          desc: "",
          learningUnits: [
            "Money and Banking",
            "International Business",
            "Financial Market and Services",
            "Introduction to Retailing",
            "E-Business",
          ],
        },
      ],
      faculties: [
        {
          id: 1,
          name: "Dr. Mohammad Saad",
          degree: [
            "M-Tech Electrical Engineering",
            "PhD- Computer Science",
            "IIT Madras",
          ],
          experience: "25 Years",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type Lorem. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum ha",
          img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
        {
          id: 2,
          name: "Dr. Mohammad Saad",
          degree: [
            "M-Tech Electrical Engineering",
            "PhD- Computer Science",
            "IIT Madras",
          ],
          experience: "25 Years",
          about:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type Lorem. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum ha",
          img: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        },
      ],
      reviews: [
        {
          image: TestiIcon,
          name: "Hafeel Ahmed",
          qualification: "Bachelor of Business Administration (BBA)",
          comment:
            '"The best place where you can gain knowledge and tarbiyyah with authentic scholars is Aspire college. Personally, I really appreciate how my Arabic teachers have always been supportive and helped me improve my grasp of the language',
          date: "11-02-2023",
        },
        {
          image: TestiIcon,
          name: "Aafila Fathima",
          qualification: "Bachelor of Business Administration (BBA)",
          comment:
            "I joined this course to learn about islam but not only did I learn about Allah's deen but I also learnt how to lead a life in dunya, Alhamdulillah!",
          date: "11-02-2023",
        },
        {
          image: TestiIcon,
          name: "Abdul Nazeer",
          qualification: "Bachelor of Business Administration (BBA)",
          comment:
            "Alhamdulillah, Aspire possesses authentic scholars with sound aqeedah and good knowledge in all fundamentals (Usool). The college provides good facilities for students and are supporting in all situations.",
          date: "11-02-2023",
        },
      ],
    },
  ];
};
