import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Template from "../components/Template";
import { useDispatch, useSelector } from "react-redux";
import { getCourse } from "../redux/actions/courseAction";
import { faAddressCard, faChevronDown, faChevronUp, faCircle, faCreditCard, faFile, faStar, faStopwatch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Lms = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const { course, detail } = useSelector(state => state.Courses.course)
    const [activeIndex, setActiveIndex] = useState(0)
    const [activeUnitIndex, setActiveUnitIndex] = useState(0)
    useEffect(() => {
        dispatch(getCourse(id))
    }, [])
    console.log(detail)
    return (
        <Template>
            <div className=' pt-36 md:pb-12 px-5 md:px-72'>
                <h3 className="text-AspireBrandBlue">{course?.title}</h3>
            </div>
            <div className='md:pb-12 px-5 md:px-72 min-h-screen'>
                <div className="grid grid-cols-12 min-h-screen overflow">
                    <div className="col-span-4">
                       
                            {detail?.sections.map((detail, index) => {
                                return (
                                    <>
                                    <div className="flex flex-row justify-between bg-AspireBlueHover rounded-xl p-4 mt-3" onClick={() => setActiveIndex(index)}>
                                        <div>
                                            <p>{detail?.title}</p>
                                        </div>
                                        <div>
                                            <FontAwesomeIcon icon={index === activeIndex ? faChevronUp :faChevronDown} />
                                        </div>
                                    </div>
                                    {detail.learningUnits.map(unit => {
                                        return (
                                            <div className="flex flex-row justify-between bg-AspireBlueHover rounded-xl p-4 mt-3" style={{display: index === activeIndex ? 'flex' : 'none' }}>
                                                <div>
                                                    <p>{unit?.title}</p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    </>
                                )
                            })}


                       
                    </div>
                    <div className="col-span-8 px-4">
                            <iframe className="min-h-screen" src="https://fast.wistia.net/embed/iframe/v7kpdzd590" style={{width:'100%'}} />
                            {/* <iframe src="https://www.ivideo.com/embed/636cc2fa0626ccdc8e003309" width="640" height="360" frameborder="0" allowfullscreen></iframe> */}

                    </div>
                </div>
            </div>
        </Template>
    )
}

export default Lms