import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SecondaryButton = (props) => {
    const { onClick, label } = props
    return (
        <div className="w-max flex flex-row justify-between items-center bg-White rounded-3xl py-3 px-4  cursor-pointer border border-AspireBrandBlue" onClick={() => onClick()}>
           
            <p className="text-xs text-AspireBrandBlue font-semibold">{label}</p>
        </div>
    )
}

export default SecondaryButton