import { GET_COURSES, GET_COURSE_DETAIL } from "../actions/types"



const initialState = {
    courses: [],
    course: {}
}

const CourseReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_COURSES:
            return Object.assign({}, state, {
                courses: action.payload
            })
        case GET_COURSE_DETAIL:
            return Object.assign({}, state, {
                course: action.payload
            })
        default:
            return state
    }
}

export default CourseReducer