import React from "react";
import Template from "../components/Template";

const AspireCommunity = () => {
    window.scrollTo(0, 0);
    return (
        <Template>

            <div className='px-5 md:px-72 py-16 md:pt-36'>
                <div className="mr-8">
                    <p className="text-2xl font-serif font-bold">Aspire in the Community</p>
                </div>
                <div className="grid grid cols-1 md:grid-cols-2 gap-4 md:gap-8 flex items-center">
                    <div className="mr-2">

                        <div className="my-4">
                            <p className="text-sm font-sans text-justify">We Endeavor to prepare the Aspirants to excel by providing them an educational foundation that builds character, right attitude nurtured values.</p>
                        </div>
                        <div className="my-4">
                            <ul className="list-disc pl-5">
                                <li className="text-base text-justify">We reach out to support, train & empower people across the city, including the less fortunate. We share our knowledge generously.</li>
                                <li className="text-base text-justify">Aspire has a vibrant and ambitious ‘Community Outreach Programme” which has undertaken many noteworthy and hugely beneficial initiatives.</li>
                                <li className="text-base text-justify">Aspire offers free online Fatwa Service provided by our esteemed scholars for the general benefit of the community members.</li>
                                <li className="text-base text-justify">Aspire College has also initiated an important step towards maintaining communal harmony by organizing “Know-your-neighbor” events titled “Towards a better tomorrow”.</li>
                                <li className="text-base text-justify">Aspire has undertaken an online campaign to “Deweed the garden of Islam” through our “Mythbuster series” on shattering bidah and superstitions in our deen.</li>
                                <li className="text-base text-justify">Aspire conducts free workshops on “health & lifestyle”, “Career & guidance counseling”, Psychology workshops for mental health & awareness and regular workshops for its parents and interested members of the community</li>
                            </ul>
                        </div>
                    </div>
                    <div className="rounded-3xl">
                        <img className="rounded-2xl w-full" src={`${process.env.PUBLIC_URL}/images/map.png`} />
                    </div>
                </div>
            </div>
        </Template>
    )
}
export default AspireCommunity