import React from "react";
import Button from "../components/Button";
import Template from "../components/Template";
import styles from "../components/styles/Scholarship.module.css";
import { useNavigate } from "react-router-dom";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const SalamCentre = (props) => {
  window.scrollTo(0, 0);
  const navigate = useNavigate();
  return (
    <Template>


      <div className="flex justify-center item-center">
        <div className={styles.fullW} >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4" >
            <div className="mt-8">
              <p className="font-bold text-2xl">Aspire's Vision</p>
              <br />
              <p className="text-base text-justify">
                The traditional education system often falls short in unlocking
                the full potential of every Student. Moreover, it's becoming
                increasingly unsustainable in the fast-evolving world. To truly
                bring out the best in every student, education must embrace a
                new and powerful principle: "Compete with Yourself!" At Aspire,
                we've integrated an interactive and innovative approach to
                educate our students.
              </p>
              <p className="text-base text-justify mt-4 mb-4">
                The vast majority of the Islamic Education System in India are
                set in traditional methods. Many want to reform and adapt
                themselves but struggle due to lack of expert support or clarity
                of direction. Aspire helps you build that.
              </p>
              <p className="mt-8 mb-4 text-sm font-bold">
                And that's precisely why Aspire has introduced Aspire Salam
                Centre.
              </p>
            </div>
            <div className=" p-3 flex justify-center md:justify-start item-center mt-8 rounded-xl">
              <img
                style={{ maxWidth: "600px" }}
                src={`${process.env.PUBLIC_URL}/images/aspire-vision.png`}
                className={"w-full h-full md:w-full md:h-full rounded-xl"}
              />
            </div>
          </div>


          <hr />


          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-12">
            <div className="p-3 flex  items-center md:justify-start md:items-start mt-8 rounded-xl">
              <img
                style={{ maxWidth: "600px" }}
                src={`${process.env.PUBLIC_URL}/images/wsc.png`}
                className="w-full h-auto md:w-full md:h-full rounded-xl"
              />
            </div>
            <div className="mt-8" >
              <p className="font-bold text-2xl">
                What is Salam Centre Franchise?
              </p>
              <br />
              <p className="text-base text-justify">
                Aspire! focuses on Islamic Knowledge that is authentic based on
                Quran & Sunnah, which is the need of the hour in the
                ever-changing modern and political settings. The syllabus has
                been researched and formulated in a way that students are
                inculcated with beneficial knowledge. This unique approach is
                why Aspire! is recommended by hundreds of parents.
              </p>
              <p className="text-base text-justify mt-4 mb-4">
                Salam Center Franchise is a 1-Stop Center for all Islamic
                Education Needs. You get the guidance and knowledge from expert
                and qualified Islamic scholars of Aspire with Salam Center
                Franchise.
              </p>
              <p className="text-base text-justify mt-4 mb-4">
                Join us on the mission to spread authentic Islamic knowledge in
                a beneficial way.
              </p>
              <p className="mt-8 mb-4 text-sm font-bold">
                Apply for Salam Center Franchise in your city.
              </p>
            </div>
          </div>

          <hr />

          <div className="grid grid-cols-1 md:grid-cols-2 gap-16 mt-16">
            <div className="mt-8">
              <p className="font-bold text-xl">
                Global Reach and Impact:
              </p>
              <br />
              <p className="text-base text-justify">
                Aspire Salam Centre proudly serves students from all across the
                country, benefiting learners of various backgrounds, ages, and
                levels of prior education.
              </p>
            </div>
            <div className="mt-8">
              <p className="font-bold text-xl ">
                Distinguished Faculty:
              </p>
              <br />
              <p className="text-base text-justify">
                Our faculty consists of qualified and authentic scholars who
                have graduated from renowned institutions. They not only impart
                knowledge but also inspire students to navigate the
                ever-changing challenges of today's world with steadfastness.
              </p>
            </div>
          </div>
<hr />
          


          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-16">
            <div>
              <p className="font-bold text-2xl mb-4">
                Why Aspire's Salam Centre Franchise?
              </p>
              <div className="">
                <ul className="list-disc pl-4">
                  <li className="text-l flex items-center gap-2">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="mr-1 md:mr-2 hover:text-White"
                      size="text-base"
                    />{" "}
                    Arabic, BAIS syllabus, books and university accreditation
                  </li>
                  <li className="text-l flex items-center gap-2">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="mr-1 md:mr-2 hover:text-White"
                      size="text-base"
                    />{" "}
                    5+ Years of Aspire Trust and Brand Image.
                  </li>
                  <li className="text-l flex items-center gap-2">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="mr-1 md:mr-2 hover:text-White"
                      size="text-base"
                    />{" "}
                    Authorized Aspire Study Center
                  </li>
                  <li className="text-l flex items-center gap-2">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="mr-1 md:mr-2 hover:text-White"
                      size="text-base"
                    />{" "}
                    Access to Highly Qualified Faculty
                  </li>
                  <li className="text-l flex items-center gap-2">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="mr-1 md:mr-2 hover:text-White"
                      size="text-base"
                    />{" "}
                    Provide Certified Islamic & Arabic Courses to Students
                  </li>
                  <li className="text-l flex items-center gap-2">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="mr-1 md:mr-2 hover:text-White"
                      size="text-base"
                    />{" "}
                    Empower Girls with Authentic Education
                  </li>
                  <li className="text-l flex items-center gap-2">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="mr-1 md:mr-2 hover:text-White"
                      size="text-base"
                    />{" "}
                    Digital Learning with latest features.
                  </li>
                  <li className="text-l flex items-center gap-2">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="mr-1 md:mr-2 hover:text-White"
                      size="text-base"
                    />{" "}
                    No Experience Required
                  </li>
                  <li className="text-l flex items-center gap-2">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="mr-1 md:mr-2 hover:text-White"
                      size="text-base"
                    />{" "}
                    Meet Growing Expectation of Students
                  </li>
                  <li className="text-l flex items-center gap-2">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="mr-1 md:mr-2 hover:text-White"
                      size="text-base"
                    />{" "}
                    Receive a high level of satisfaction through the service
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-span-1 md:col-span-1 p-3 flex justify-center md:justify-start items-center rounded-xl">
              <img style={{maxWidth:"500px"}}
                src={`${process.env.PUBLIC_URL}/images/wasc.png`}
                className=" rounded-xl"
                alt="Courses"
              />
            </div>
          </div>
            <hr />

          <div className="text-center mt-16">
            <h1 className="text-2xl font-bold ">Requirements</h1>

            <p className="text-sm text-gray-700 font-bold mt-4">
              These Basic Requirements Are Sufficient to Get Your Salam Center
              Started & Running!
            </p>
          </div>
          <div className="mt-8 grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="bg-gray-200 p-4 rounded-lg">
              <img
                src={`${process.env.PUBLIC_URL}/images/laptop.jpg`}
                alt="Courses"
              />
              {/* <h2 className="text-xl font-bold text-center p-4">
                LED TV & Laptop
              </h2> */}
            </div>
            <div className="bg-gray-200 p-4 rounded-lg">
              <img
                src={`${process.env.PUBLIC_URL}/images/seating.jpg`}
                alt="Courses"
              />
              {/* <h2 className="text-xl font-bold text-center p-4">
                Seating Space for 20+ Students
              </h2> */}
            </div>
            <div className="bg-gray-200 p-4 rounded-lg">
              <img
                src={`${process.env.PUBLIC_URL}/images/coordinator.jpg`}
                alt="Courses"
              />
              {/* <h2 className="text-xl font-bold text-center p-4">
                Centre Co-Ordinator
              </h2> */}
            </div>
          </div>
          <hr />
          <div className="mt-16 ">
            <p className="font-bold text-lg">Support Center</p>
            <br />
            <p className="text-base text-justify">
              Starting a center is not easy. It is even more difficult to
              sustain and augment quality year after year. Finding the right
              faculty who can impart authentic knowledge is of utmost importance
              especially when teaching Islamic Studies
            </p>
          </div>
          <div className="mt-8">
            <p className="font-bold text-lg">Academic Support</p>
            <br />
            <p className="text-base text-justify">
              Our curriculum is internationally researched, well structured and
              updated on regular basis. Assessments, Tests & Quizzes.
            </p>
          </div>
          <div className="mt-8">
            <p className="font-bold text-lg">Training Support</p>
            <br />
            <p className="text-base text-justify">
              Providing all syllabus/books. Tarbiyyah and Character Training,
              Co-Ordinator Training.
            </p>
          </div>
          <div className="mt-8">
            <p className="font-bold text-lg">Marketing Support</p>
            <br />
            <p className="text-base text-justify">
              Advertisements through Digital media. professionally prepared
              prospectus, brochures, newspaper advertisement, mailers, leaflets,
              banners, etc. (only design)
            </p>
          </div>
          <div className="mt-8">
            <p className="font-bold text-lg">Portal Support</p>
            <br />
            <p className="text-base text-justify">
              Creating & Managing Center Website. Students Access Management,
              Online Class Facilities. Overall Student Dashboard.
            </p>
          </div>

          <div class=" mt-16">
            <h1 class="text-2xl font-bold mb-4">
              Contact Us for more details...
            </h1>
            <p className="text-2xl text-GrayBg ">+91 98418 58847</p>
          </div>
        </div>
      </div>
    </Template>
  );
};
