import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import AuthReducer from "./authReducer";
import ProductReducer from "./productReducer";
import CustomerReducer from "./customerReducer";
import CartReducer from "./cartReducer";
import EventReducer from "./eventReducer";
import BlogReducer from "./blogReducer";
import CourseReducer from "./courseReducer";
import LmsReducer from "./lmsReducer";
import admissionReducer from "./admissionReducer";

const rootReducer = combineReducers({
  Auth: AuthReducer,
  Products: ProductReducer,
  Customers: CustomerReducer,
  Cart: CartReducer,
  Events: EventReducer,
  Blogs: BlogReducer,
  Courses: CourseReducer,
  LMS: LmsReducer,
  Admission: admissionReducer,
});

const rootPersistConfig = {
  key: "root",
  storage,
  whitelist: ["Auth", "Products", "Customers", "Courses", "LMS"],
  // blacklist: [''],
  stateReconciler: autoMergeLevel2,
};
export default persistReducer(rootPersistConfig, rootReducer);
