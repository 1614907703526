import React, { useEffect, useState } from "react";
import CourseCard from "./CourseCard";
import { useDispatch } from "react-redux";
import bais from "../static/images/arabic-2.png";
import mais from "../static/images/MAIS-online.png";
import basicarabic from "../static/images/basic-arabic-diploma.png";
import intermediatearabic from "../../src/static/images/intermediate-arabic-diploma.png";
import advancearabic from "../../src/static/images/advance-arabic-diploma.png";
import islamicinheritance from "../../src/static/images/islamic-inheritance.png";
import zakahmaster from "../../src/static/images/zakah-master.png";
import khulafarashideen from "../../src/static/images/khulafa-rashideen.png";
import ahlussunnah from "../../src/static/images/ahlus-sunnah.png";
import IslamicPsychology from "../../src/static/images/Islamic-psychology.png";
import psychology from "../static/images/psychology.jpg";
import BscOncampus from "../../src/static/images/Bsc-on-campus.png";
import BaisOncampus from "../../src/static/images/BAIS-On-campus.png";
import MaisOncampus from "../../src/static/images/MAIS-On-campus.png";
import PUCOncampus from "../../src/static/images/PUC-On-campus.png";
import bbaonline from "../../src/static/images/bba-online.jpg";
import bba from "../../src/static/images/bba-oncampus.jpg";

import puc from "../static/images/puc.jpg";
import styles from "./styles/courses.module.css";
import { useNavigate } from "react-router-dom";

const Courses = ({ limit, isHomePage }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const coursesPerComponent = 3;

  const [active, setActive] = useState("On Campus");

  const courses = [
    {
      id: 1,
      title: "Bachelor of Islamic Studies",
      duration: "3 Years",
      location: "Chennai | Bengaluru",
      language: "",
      offer: "Financial Support",
      image: BaisOncampus,
    },
    {
      id: 2,
      title: "B.Sc. Psychology",
      duration: "3 Years",
      location: "Chennai | Bengaluru",
      language: "",
      offer: "Financial Support",
      image: BscOncampus,
    },
    {
      id: 3,
      title: "Master in Islamic Studies",
      duration: "2 Years",
      location: "Chennai | Bengaluru",
      language: "",
      offer: "Financial Support",
      image: MaisOncampus,
    },
    {
      id: 4,
      title: "PUC / 12th Std",
      duration: "1 Year",
      location: "Chennai | Bengaluru",
      language: "",
      offer: "Financial Support",
      image: PUCOncampus,
    },
    {
      id: 5,
      title: "Basic Arabic Diploma",
      duration: "3 months",
      location: "50+ Assessments",
      language: "",
      offer: "Live Doubt-Clearing Sessions",
      image: basicarabic,
    },
    {
      id: 6,
      title: "Intermediate Arabic Diploma",
      duration: "3 months",
      location: "50+ Assessments",
      language: "",
      offer: "Live Doubt-Clearing Sessions",
      image: intermediatearabic,
    },
    {
      id: 7,
      title: "Advance Arabic Diploma",
      duration: "3 months",
      location: "50+ Assessments",
      language: "",
      offer: "Live Doubt-Clearing Sessions",
      image: advancearabic,
    },
    {
      id: 8,
      title: "Zakah Master Class (with Practical Demo)",
      duration: "Aspire Exclusive",
      location: "Quizzes & Assessments",
      language: "",
      offer: "High quality videos",
      image: zakahmaster,
    },
    {
      id: 9,
      title: "The Dynamic Khulafa Rashideen",
      duration: "Aspire Exclusive",
      location: "Quizzes & Assessments",
      language: "",
      offer: "High quality videos",
      image: khulafarashideen,
    },
    {
      id: 10,
      title: "Islamic Inheritance (with Practical Cases)",
      duration: "Aspire Exclusive",
      location: "Quizzes & Assessments",
      language: "",
      offer: "High quality videos",
      image: islamicinheritance,
    },
    {
      id: 11,
      title: "Ahlus Sunnah Wal Jamah Aqeedah",
      duration: "Aspire Exclusive",
      location: "Quizzes & Assessments",
      language: "",
      offer: "High quality videos",
      image: ahlussunnah,
    },
    {
      id: 12,
      title: "Diploma in Islamic Psychology",
      duration: "6 months",
      location: "Counselling Skills",
      language: "",
      offer: "Practical Case Studies",
      image: IslamicPsychology,
    },
    {
      id: 13,
      title: "Bachelor of Islamic Studies",
      duration: "3 Years",
      location: "150+ Assessments",
      language: "",
      offer: "1000+ Students",
      image: bais,
    },
    {
      id: 14,
      title: "B.Sc. Psychology",
      duration: "3 Years",
      location: "150+ Assessments",
      language: "",
      offer: "Case Studies & Assignments",
      image: psychology,
    },
    {
      id: 15,
      title: "Master in Islamic Studies",
      duration: "2 Years",
      location: "100+ Assessments",
      language: "",
      offer: "Upto 100% Scholarship",
      image: mais,
    },
    {
      id: 16,
      title: "PUC / 12th Std",
      duration: "1 Year",
      location: "80+ Assessments",
      language: "",
      offer: "High quality videos",
      image: puc,
    },
    {
      id: 17,
      title: "Bachelor of Business Administration (BBA)",
      duration: "3 Years",
      location: "80+ Assessments",
      language: "",
      offer: "High quality videos",
      image: bba,
    },
    {
      id: 18,
      title: "Bachelor of Business Administration (BBA)",
      duration: "3 Years",
      location: "80+ Assessments",
      language: "",
      offer: "High quality videos",
      image: bbaonline,
    },
  ];

  const filteredCourses = courses.filter((course) => {
    if (active === "All Courses") {
      return true;
    } else if (active === "On Campus") {
      return (
        course.id === 1 || course.id === 2 || course.id === 3 || course.id === 4 || course.id === 17
      );
    } else if (active === "Diploma") {
      return (
        course.id === 5 ||
        course.id === 6 ||
        course.id === 7 ||
        course.id === 12
      );
    } else if (active === "Online Courses") {
      return (
        course.id === 13 ||
        course.id === 14 ||
        course.id === 15 ||
        course.id === 16 ||
        course.id === 18
      );
    } else if (active === "Free Courses") {
      return (
        course.id === 8 ||
        course.id === 9 ||
        course.id === 10 ||
        course.id === 11
      );
    }

    return false;
  });

  const slicedCourses = limit
    ? filteredCourses.slice(0, limit)
    : filteredCourses;

  useEffect(() => {
    // dispatch(getAllCourses()); // You can uncomment this if needed
  }, []);

  return (
    <div className={styles.fullW}>
      <div style={{ marginTop: 70 }}>
        <p className="text-2xl font-serif font-bold">Courses</p>
      </div>
      <div>
        <p className="text-base">Browse by category</p>
      </div>

      <div className="flex flex-row flex-shrink-0 overflow-x-auto">
        <style>
          {`
      @media (max-width: 640px) {
        .mobile-item {
          width: 120px; /* Adjust the width as needed */
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    `}
        </style>
        <div
          className={`cursor-pointer py-3 mobile-item ${
            active === "On Campus" ? "text-AspireBrandBlue border-b-4" : ""
          }`}
          onClick={() => setActive("On Campus")}
          style={{ margin: "0 20px 0 0" }}
        >
          <span className="font-medium text-xs md:text-base">On Campus</span>
        </div>
        <div
          className={`cursor-pointer py-3 mobile-item ${
            active === "Online Courses" ? "text-AspireBrandBlue border-b-4" : ""
          }`}
          onClick={() => setActive("Online Courses")}
          style={{ margin: "0 20px 0 0" }}
        >
          <span className="font-medium text-xs md:text-base">
            Online Courses
          </span>
        </div>
        <div
          className={`cursor-pointer py-3 mobile-item ${
            active === "Diploma" ? "text-AspireBrandBlue border-b-4" : ""
          }`}
          onClick={() => setActive("Diploma")}
          style={{ margin: "0 20px 0 0" }}
        >
          <span className="font-medium text-xs md:text-base">Diploma</span>
        </div>
        {/* <div
          className={`cursor-pointer py-3 mobile-item ${
            active === "Short Courses" ? "text-AspireBrandBlue border-b-4" : ""
          }`}
          onClick={() => setActive("Short Courses")}
          style={{ margin: "0 20px 0 0" }}
        >
          <span className="font-medium text-xs md:text-base">Short Courses</span>
        </div> */}

        <div
          className={`cursor-pointer py-3 mobile-item ${
            active === "Free Courses" ? "text-AspireBrandBlue border-b-4" : ""
          }`}
          onClick={() => setActive("Free Courses")}
          style={{ margin: "0 20px 0 0" }}
        >
          <span className="font-medium text-xs md:text-base">Free Courses</span>
        </div>
        <div
          className={`cursor-pointer py-3 ${
            active === "All Courses" ? "text-AspireBrandBlue border-b-4" : ""
          }`}
          onClick={() => setActive("All Courses")}
        >
          {/* <span className="font-medium text-xs md:text-base">All Courses</span> */}

          {isHomePage ? (
          <div className="flex justify-center items-center hidden md:block">
            <span
              className="font-medium text-AspireBrandBlue cursor-pointer py-3"
              onClick={() => navigate("/courses")}
            >
              {"Show All Courses >"}
            </span>
            
          </div>
        ) : null}
        </div>
      </div>

      <div className="grid-cols-3 mt-10 overflow-x-auto gap-4 hidden md:grid">
        {slicedCourses.map((data, index) => (
          <CourseCard
            key={data.id}
            id={data.id}
            title={data.title}
            index={index}
            image={data.image}
            duration={data.duration}
            language={data.language}
            offer={data.offer}
            location={data.location}
          />
        ))}
      </div>
      <div className="flex flex-row justify-between md:hidden mt-10 overflow-x-auto gap-4 overflow-x-auto">
        {filteredCourses.map((data, index) => (
          <CourseCard
            key={data.id}
            id={data.id}
            title={data.title}
            index={index}
            image={data.image}
            duration={data.duration}
            language={data.language}
            offer={data.offer}
            location={data.location}
          />
        ))}
      </div>
    </div>
  );
};

export default Courses;
