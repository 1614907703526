import {
  faEnvelope,
  faInbox,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import Button from "../components/Button";
import TextInput from "../components/TextInput";
import { useDispatch, useSelector } from "react-redux";
import { getAllCourses } from "../redux/actions/courseAction";
import { doAdmission } from "../redux/actions/admissionAction";
import Swal from "sweetalert2";
import axios from "axios";
import Template from "../components/Template";
import { tryCatch } from "ramda";
import { useForm, ValidationError } from "@formspree/react";
import { Link } from "react-router-dom";

const AdmissionFormMain = () => {
  const dispatch = useDispatch();
  const courses = useSelector((state) => state.Courses.courses);
  const user = useSelector((state) => state.Auth.accessToken);
  const userEmail = useSelector((state) => state.Auth.email);

  // FORMSPREE
  const [state, handleSubmit] = useForm("xleyjbkd");
  if (state.succeeded) {
    return (
      // <div className="flex flex-row items-center justify-center  ">
      //   <p className="font-bold text-4xl text-Grey ">Thanks for joining!</p>
      //   <span className="text-xs ">
      //     <Link to="/" c>
      //       Go to Home
      //     </Link>
      //   </span>
      // </div>
      <div class="bg-gray-100 flex justify-center items-center h-screen">
        <div class="bg-white p-8 rounded-lg shadow-md text-center">
          <div class="text-green-500 text-4xl mb-4">
            <svg
              class="inline-block w-12 h-12"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M5 13l4 4L19 7"></path>
            </svg>
          </div>
          <h1 class="text-3xl font-semibold text-gray-800 mb-2">Thank You!</h1>
          <p class="text-lg text-gray-600">
            Your message has been successfully received.
          </p>
          <Link
            to="/"
            className="text-blue-500 hover:underline  inline-block underline text-xs"
          >
            Go to Homepage
          </Link>
        </div>
      </div>
    );
  }

  // const [formValid, setFormValid] = useState(false); // State variable for form validity

  // const [admission, setAdmission] = useState({
  //   studentName: "",
  //   email: "",
  //   mobileNumber: "",
  //   gender: "",
  //   location: "",
  //   // courseId: "",
  //   // coursePrice: "",
  //   queries: "",
  //   admissionMode: "",
  // });
  // console.log(admission);
  // Check if the selected course is available
  // const foundCourse = courses.find((course) => course.id === admission.courseId);
  // const coursePrice = foundCourse ? foundCourse.final_price : "";
  // console.log(coursePrice)

  // const [selectedCourse, setSelectedCourse] = useState({});
  // console.log(selectedCourse.final_price)

  // const updateForm = (event) => {
  //   const { name, value } = event.target;
  //   setAdmission((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };

  //   if (name === "courseId") {
  //     const foundCourse = courses.find((course) => course.id === value);
  //     const coursePrice = foundCourse ? foundCourse.final_price : "";
  //     setAdmission((prevState) => ({
  //       ...prevState,
  //       [name]: value,
  //       coursePrice: coursePrice,
  //     }));
  //   } else {
  //     setAdmission((prevState) => ({
  //       ...prevState,
  //       [name]: value,
  //     }));
  //   }
  // };
  // console.log(admission)

  // useEffect(() => {
  //   if (admission.courseId) {
  //     const course = courses.find((course) => course.id === admission.courseId);
  //     setSelectedCourse(course);
  //   }
  // }, [admission.courseId, courses]);

  //  PAYMENT INTEGRATION
  // const checkoutHandler = async (amount) => {
  //   try {
  //     const { data:{order} } = await axios.post("http://localhost:5000/pay/checkout", {
  //       amount,
  //     });
  //     const options = {
  //       "key": "rzp_test_NfWnFiQ7aNLgfT", // Enter the Key ID generated from the Dashboard
  //       "amount": order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
  //       "currency": "INR",
  //       "name": "Aspire College",
  //       "description": "Test Transaction",
  //       "image": "https://example.com/your_logo",
  //       "order_id": order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
  //       // "callback_url": "http://localhost:5000/pay/verifyPayment",
  //       handler:async(response)=>{
  //         try {
  //           const verifyUrl = "http://localhost:5000/pay/verifyPayment"
  //           const {data} = await axios.post(verifyUrl, {response,"order_id":order.id, admission})
  //           console.log(data)
  //           if(data.success = true){
  //             Swal.fire({
  //               title: 'Admission Successful.',
  //               html: '<p>Thank you!</p>',
  //               icon: 'success',
  //               showCancelButton: false,
  //               confirmButtonText: 'OK',
  //             }).then((result) => {
  //               if (result.isConfirmed) {
  //                 // Perform actions when the user clicks "OK"
  //                 window.location.href = "/";
  //               }
  //             });
  //           }

  //         } catch (error) {
  //           console.error(error)
  //         }

  //       },
  //       "prefill": {
  //           "name": "Shubh Kumar",
  //           "email": "sh@example.com",
  //           "contact": "9000090000"
  //       },
  //       "notes": {
  //           "address": "Razorpay Corporate Office"
  //       },
  //       "theme": {
  //           "color": "#3399cc"
  //       }
  //     };
  //   const razor = new window.Razorpay(options);

  //   razor.open();
  // }

  //   catch (error) {
  //     console.error(error);
  //   }
  // };

  const addAdmission = () => {};
  // ADMISSION FOR SIGNED IN USER
  // const addAdmission = () => {
  //   if (!user) {
  //     // Show SweetAlert2 box to login first
  //     Swal.fire({
  //       icon: "warning",
  //       title: "Login Required",
  //       text: "Please login first.",
  //     });
  //   } else {
  //     if (admission.admissionMode === "Offline") {
  //       dispatch(doAdmission(admission));
  //     } else if (admission.admissionMode === "Online") {

  //      const selectedCourse = courses.find((course) => course.id === admission.courseId);
  //      const amount = selectedCourse.final_price;

  //      if(amount=== 0){
  //       dispatch(doAdmission(admission));
  //      }
  //      else{
  //       checkoutHandler(amount)
  //      }

  //     }

  //   }
  // };

  // const buttonText = admission.admissionMode === "Offline" ? "Submit" : "Pay Now";
  const buttonText = "Submit";
  return (
    <Template>
      <div className="relative left-0 w-screen h-screen flex items-center justify-center  bg-white pt-36 md:pb-12 px-5 md:px-72">
        <div className="bg-GrayBg rounded-2xl w-80 md:w-1/2 p-2">
          <div className="grid grid-cols-4">
            <div className="cursor-pointer col-span-3">
              <img
                src={`${process.env.PUBLIC_URL}/images/logo-dark.png`}
                style={{ width: "150px" }}
                alt="logo"
              />
            </div>
            <div className="cursor-pointer col-span-1">
              {/* <p className="text-right">X</p> */}
            </div>
          </div>
          <div className="my-2">
            <p className="text-xl font-bold font-serif my-2">Admission Form</p>
            <form onSubmit={handleSubmit}>
              <div>
                <TextInput
                  name="studentName"
                  label="Student name"
                  type="text"
                  // value={admission.studentName}
                  // onChange={(event) => updateForm(event)}
                  required
                />
                <ValidationError
                  prefix="Email"
                  field="email"
                  errors={state.errors}
                />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <TextInput
                    name="email"
                    label="Email"
                    type="email"
                    // value={admission.email}
                    // onChange={(event) => updateForm(event)}
                    required
                  />
                  <ValidationError
                    prefix="Email"
                    field="email"
                    errors={state.errors}
                  />
                </div>
                <div>
                  <TextInput
                    name="mobileNumber"
                    label="Mobile number"
                    type="text"
                    // value={admission.mobileNumber}
                    // onChange={(event) => updateForm(event)}
                    required
                  />
                  <ValidationError
                    prefix="Email"
                    field="email"
                    errors={state.errors}
                  />
                </div>
              </div>
              {/* <div className="my-2">
                <p className="text-sm my-2 px-4 font-bold">Gender</p>
                <div className="grid grid-cols-2">
                  <div className="flex flex-row items-center px-4">
                    <input
                      name="gender"
                      type="radio"
                      // value="Male"
                      // onChange={(event) => updateForm(event)}
                      required
                    />
                    <ValidationError
                      prefix="Email"
                      field="email"
                      errors={state.errors}
                    />
                    <p className="ml-8">Male</p>
                  </div>
                  <div className="flex flex-row items-center">
                    <input
                      name="gender"
                      type="radio"
                      // value="Female"
                      // onChange={(event) => updateForm(event)}
                      required
                    />
                    <p className="ml-8">Female</p>
                  </div>
                </div>
              </div>
              {/* <div className="my-2">
              <p className="text-sm my-2 px-4 mt-8 font-bold">Course</p>
              <select
                name="courseId"
                className="rounded-2xl bg-White w-full mt-4"
                value={admission.courseId}
                onChange={(event) => updateForm(event)}
                required
              >
                <option value="">Select a course</option>
                {courses.map((course) => (
                  <option value={course.id} key={course.id}>
                    {course.title}
                  </option>
                ))}
              </select>
            </div> */}
              {/* <span>{admission.coursePrice}</span> */}
              {/* <div className="my-2">
                <p className="text-sm my-2 px-4 mt-8 font-bold">Location</p>
                <div className="grid grid-cols-2">
                  <div className="flex flex-row items-center px-4">
                    <input
                      name="location"
                      type="radio"
                      // value="Chennai"
                      // onChange={(event) => updateForm(event)}
                      required
                    />
                    <p className="ml-8">Chennai</p>
                  </div>
                  <div className="flex flex-row items-center">
                    <input
                      name="location"
                      type="radio"
                      // value="Bangalore"
                      // onChange={(event) => updateForm(event)}
                      required
                    />
                    <ValidationError
                      prefix="Email"
                      field="email"
                      errors={state.errors}
                    />
                    <p className="ml-8">Bangalore</p>
                  </div>
                </div>
              </div>{" "} */}

              {/* <div className="my-2">
              <p className="text-sm my-2 px-4 font-bold">Application Mode</p>
              <div className="grid grid-cols-2">
                <div className="flex flex-row items-center px-4">
                  <input
                    name="admissionMode"
                    type="radio"
                    value="Offline"
                    onChange={(event) => updateForm(event)}
                    required
                  />
                  <p className="ml-8">Offline</p>
                </div>
                <div className="flex flex-row items-center">
                  <input
                    name="admissionMode"
                    type="radio"
                    value="Online"
                    onChange={(event) => updateForm(event)}
                    required
                  />
                  <p className="ml-8">Online</p>
                </div>
              </div>
            </div> */}
              <div className="my-2">
                <div className="grid grid-cols-2">
                  <div className="flex flex-row items-center px-4 gap-12">
                    <fieldset
                      id="fs-frm-selects"
                      className="flex-col items-center gap-12"
                    >
                      <label for="timely" className="text-sm mt-8 font-bold">
                        Gender
                      </label>
                      <select
                        name="Gender"
                        id="timely"
                        required=""
                        className=""
                      >
                        <option value="" selected="" disabled="">
                          Choose
                        </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                    </fieldset>
                  </div>
                  <div className="flex flex-row items-center"></div>
                </div>
              </div>
              <div className="my-2 mt-8">
                <p className="text-sm my-2 px-4 font-bold">Message</p>
                <textarea
                  rows={4}
                  name="queries"
                  className="rounded-2xl bg-White w-full"
                  // value={admission.queries}
                  // onChange={(event) => updateForm(event)}
                  required
                />
                <ValidationError
                  prefix="Email"
                  field="email"
                  errors={state.errors}
                />
              </div>
              <div className="flex justify-end items-center">
                <button
                  type="submit"
                  disabled={state.submitting}
                  className=" bg-AspireBrandBlue rounded-3xl py-3 px-4  cursor-pointer text-White"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
          <div className="flex flex-row w-full justify-between my-2 mt-8">
            <div>
              <div className="flex flex-row  items-center">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="mr-1 md:mr-2 hover:text-White"
                  size="text-base"
                />
                <span>info@aspirecollege.edu.in</span>
              </div>
              <div className="flex flex-row  items-center">
                <FontAwesomeIcon
                  icon={faPhone}
                  className="mr-1 md:mr-2 hover:text-White"
                  size="text-base"
                />
                <span>+91 9940348823</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Template>
  );
};

export default AdmissionFormMain;
