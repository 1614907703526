
import Api from "../../lib/api";
import { ADD_EVENTS, ADD_EVENTS_FAILURE, DELETE_EVENTS,SELECTED_EVENT_FAILURE, DELETE_EVENTS_FAILURE, EDIT_EVENTS, EDIT_EVENTS_FAILURE, GET_EVENTS, GET_EVENTS_FAILURE, SELECTED_EVENT } from "./types";

export const createEvent = (body, navigate) => async (dispatch) => {
    try {
        const response = await Api.post(body, '/event');
        if (response.success) {
            dispatch({
                type: ADD_EVENTS,
                payload: response.data
            })
            navigate('/events')
        } else {
            dispatch({
                type: ADD_EVENTS_FAILURE,
                payload: response.data
            })
        }
    } catch (err) {
        dispatch({
            type: ADD_EVENTS_FAILURE,
            payload: err.message
        })
    }
}

export const getEvents = () => async (dispatch) => {
    try {
        const response = await Api.get('/event');
        if (response.success) {
            dispatch({
                type: GET_EVENTS,
                payload: response.data
            })
        } else {
            dispatch({
                type: GET_EVENTS_FAILURE,
                payload: response.data
            })
        }
    } catch (err) {
        dispatch({
            type: GET_EVENTS_FAILURE,
            payload: err.message
        })
    }
}

export const editEvents = (body, navigate) => async (dispatch) => {
    try {
        const response = await Api.post(body, `/event/edit`);
        if (response.success) {
            dispatch({
                type: EDIT_EVENTS,
                payload: response.data
            })
            navigate('/events')
        } else {
            dispatch({
                type: EDIT_EVENTS_FAILURE,
                payload: response.data
            })
        }
    } catch (err) {
        dispatch({
            type: EDIT_EVENTS_FAILURE,
            payload: err.message
        })
    }
}


export const deleteEvents = (body, navigate) => async (dispatch) => {
    try {
        const response = await Api.post(body, `/event/delete`);
        if (response.success) {
            dispatch({
                type: DELETE_EVENTS,
                payload: response.data
            })
            dispatch(getEvents())
            navigate('/events')
        } else {
            dispatch({
                type: DELETE_EVENTS_FAILURE,
                payload: response.data
            })
        }
    } catch (err) {
        dispatch({
            type: DELETE_EVENTS_FAILURE,
            payload: err.message
        })
    }
}

export const getEvent = (id) => async (dispatch) => {
    try {
        const response = await Api.get(`/event/${id}`);
        if (response.success) {
            dispatch({
                type: SELECTED_EVENT,
                payload: response.data
            })
        } else {
            dispatch({
                type: SELECTED_EVENT_FAILURE,
                payload: response.data
            })
        }
    } catch (err) {
        dispatch({
            type: SELECTED_EVENT_FAILURE,
            payload: err.message
        })
    }
}