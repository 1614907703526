import Api from '../../lib/api'
import { ADD_PRODUCT, ADD_PRODUCT_FAILURE, GET_PRODUCT, GET_PRODUCT_FAILURE, SELECTED_PRODUCT, SELECTED_PRODUCT_FAILURE } from "./types";



export const getProducts = () => async (dispatch) => {
    try {
        const response = await Api.get('/product');
        if (response.success) {
            dispatch({
                type: GET_PRODUCT,
                payload: response.data
            })
        } else {
            dispatch({
                type: GET_PRODUCT_FAILURE,
                payload: response.data
            })
        }
    } catch (err) {
        dispatch({
            type: GET_PRODUCT_FAILURE,
            payload: err.message
        })
    }
}

export const getProduct = (id) => async (dispatch) => {
    try {
        const response = await Api.get(`/product/${id}`);
        if (response.success) {
            dispatch({
                type: SELECTED_PRODUCT,
                payload: response.data
            })
        } else {
            dispatch({
                type: SELECTED_PRODUCT_FAILURE,
                payload: response.data
            })
        }
    } catch (err) {
        dispatch({
            type: SELECTED_PRODUCT_FAILURE,
            payload: err.message
        })
    }
}

