import React, { useState, useEffect } from "react";
import Template from "../components/Template";
import styles from "../components/styles/Alumni.module.css";

import Almuni1 from "../static/images/alumini_notes.jpg";

const AlumniPage = () => {
  window.scrollTo(0, 0);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAlumni, setSelectedAlumni] = useState(null);

  // Sample data for alumni
  const alumniData = [
    {
      id: 1,
      name: "What I Couldn't Write On Paper",
      description: `It's been almost 8 years since I first saw the shuyookh and 8 years in search of one answer.

      I remember when Sheikh Abdus salam would request our class to write down notes of his subject. I'm a person of particular interest in writing but still couldn't write down what he said on matters as basic as aqeedah. It used to surprise me.
      
      I wanted to share with my family the things that he said. I wanted to desperately jot down everything. But in vain.. Hence I resorted to remembering what he said and as soon as I went home, I would start saying my mom everything (still with my abaya on). Then again when my sisters came back from school, I would repeat everything. This way, Alhamdulilah I remembered many of his sayings. But still i couldn't write them down.
      
      Then when we came to the 3rd year, his lectures on Islamic philosophy completely blowed us away. We would sit still sometimes, not able to tune with the next lecture going on. Even for something as awesome as this... I still couldn't write them down.
      
      After 8 years, I'm finally realising the answer to this... It was because, these lectures were actually tarbiyyah classes. And tarbiyyah, adab, attitude and perspective cannot be written down merely. Some knowledges are meant to passed down by voices rather than books, as jibraeel a.s did by personally coming and reciting the aayat rather than giving them the written text.
      
      And the Prophet صلى الله عليه وسلم he himself resorted to speaking rather than writing down for people. Notes by themselves cannot be ever sufficient.
      
      Who can explain us the crucial topics like feminism, polygamy, slavery, disunity in the Ummah, gheerah etc. which are beyond the scope of the syllabus? Lectures are not just about dictating notes. Some lectures necessitate conviction, convincing us, experience, changing our thoughts and reviving our eemaan.
      
      And I don't know who will do this extra job for those who don't study under a teacher, expertised in this such as Sheikh Abdus Salam.
      
      Allahu musta'an
      
      - Rafia Afreen, Student of Sheikh Abdus Salam
      
      `,
      imageUrl: Almuni1,
    },
    {
      id: 2,
      name: "It was the Last day of college today...",
      description: `3 years of beautiful memories, new friends,great teachers, great experiences and all the new ventures.. Thanking Allah for every sec that I was blessed with... Under the roof of Aspire Clg. My heart will always be connected to this college, my great hard working and enthusiastic.
      Shiekhs and teachers, who are role models to us will always be remembered. I make dua that Allah blesses this college and every person attached to it. Aameen. It was the best experience of my life to learn under such renowned scholars. Alhamdulillah. One thing is true, a person's grit takes him a long way. I thank each and every person who supported and helped me in this journey.
      Barakallah fikum
      
      Jazakallahu khairan kaseera`,
      imageUrl: Almuni1,
    },
    {
      id: 3,
      name: "A New Beginning",
      description: `If I had the opportunity to go back in time and decide to not attend any party which wasn’t conducted in the most beautiful and halal manner as our fresher’s party at aspire, trust me I would. It has honestly been about five years since my heart had ever felt the peace of being between the right company, this is the company that I craved for and Alhamdulillah because of Allah (S.W) mercy I was able to be a part of something that gave me immense pleasure and most importantly peace.

        So easy it is, isn’t it? To be a part of something that is pure entertainment, to lose control of our Nafs by indulging in the influential party agendas that our society has set for us. But when you look back at the teachings of our Rasoolallah Sallalahu Alihi Wassalam, we come to understand and love the freedom that our Deen grants us rather than the idea of freedom that the people of this Duniya have designed for us. If it was me from five years back talking, I would have wanted to attend those late night parties and those dances but being amongst people who share the same set of values that our Islam teaches us, I have come to realize the importance of our Deen and the company of the people that we keep, and for that I am ever grateful to Allah (S.W).
        
        The freshers party, allowed us to not only get to know each other but also made me realize how being united as a team strengthens our Ummah. We played games that were like ice breaker sessions for us to feel relaxed and at ease. Along with that we had a quiz round where Islam related questions were also included to keep us reminded of our sole purpose in this world Alhamdullilah.
        
        Though only one person was crowned the as the best fresher, I can’t help but admit that being a part of such a beautiful crowd made me realize that the best are the people who are ready to face Allah because they have the knowledge of our Deen. It might seem exaggerated, but I have finally been able to fall asleep at night, knowing that I am at the right place all because of the guidance of Allah (S.W.). I pray to Allah on behalf of all students of Aspire, that he keeps us in the right company, with the people who truly love Allah and His Rasool and the ones who help us grow only in the way of Allah. Ameen
        
        PS: The Gulab Jamuns were yummy, and everyone loved the gorgeous decorations done by our lovely seniors, May Allah bless them. Ameen
        
        - Saba Naaz`,
      imageUrl: Almuni1,
    },
    {
      id: 4,
      name: "Message from Outgoing Batch",
      description: `The feeling of happiness and sadness at the same time comes at rare moments and graduation is one such moment. And graduating from ASPIRE, just makes it more intense.

      With our final exams been written, our first and foremost praises and thanks would be for Allah (swt) alone, for guiding us to the straight path, for granting us the pleasure of tasting the sweetness of seeking knowledge and most importantly, for making it easy for us. Alhamdulillah.
      We would then like to whole heartedly thank the management of ASPIRE for being ever supportive throughout the year, and for their amazing sense of responsibilty by providing every possible facility that the students would need and making sure of the ease and comfort of the students, Alhamdulillah.
      And as for our Shuyook, thanking them wouldn't be enough. For they have not been mere teachers alone. They have been our mentors, our guides, consultants, and at times even taking the role of parents, by embedding those pearls of wisdom and Akhalaaq not just in our minds, but in our hearts too. And for shaping our minds, preparing and moulding them for us to be what we are today, Alhamdulillah.
      Being a student of ASPIRE has been an honour and it will remain in our duas forever. We were a part of ASPIRE, we still are, and we will be proud ASPIRANTS, In Sha Allah.
      May Allah (swt) bless every single person who is a part of this blessed institution and may He (swt) bring Barakah in their time and efforts and may He, forgive our shortcomings and accept all of our good deeds. Ameen.
      With heavy yet content hearts,      
      - Students of III Year, B.A.I.S (2016-2017)`,
      imageUrl: Almuni1,
    },
    {
      id: 5,
      name: "Knowledge Gives Insight",
      description: `I found here many brothers from different countries who have studied Islamic sudies in their countries right from their birth but still they don't have a clear picture of various subjects. It amazed me as I found myself knowing many things which they don't know. What Shaikh would say to us in our classes is definitely true that we may not produce scholars but our students would have a clearer picture of things than many of those who study in many different islamic institutions from their birth.
      I thank Shaikh for teaching us the things he taught us and make dua for him. Aameen.
      - Maasid Siddiq Ganai, Student of Sheikh Abdus Salam`,
      imageUrl: Almuni1,
    },
    {
      id: 6,
      name: "Embracing All The Mazahib",
      description: `Shafi? Mailiki? Hanafi? Hanbali? Which mazhab do you belong to?
      This is a often asked question among us. Most of us grew up in a particular mazhab, loving it, adoring it and abhoring the other mazhab and sadely at times also seeing other mazahib other than your own as deviated from the truth. Astagfirullah.
      But is it our fault? Thats what we have been taught from childhood. And we believed it without any hestitaion.
      Coming from different mazahib, we all know the "differences in fiqh" we have from each other. But sadely forget the most important thing, i.e, "what is that unites us?"
      Yes we all might come from different mazhab and pray a little differently, but we pray to the same Lord. We follow the same prophet; Prophet Muhamad (saw). We follow the same religion, Islam. Tawheed unites all of us.
      We might have our differences in Fiqh, but we all hold the same opinion in aqeedah matters. We all our Muslims which ever mazhab we follow.
      Now its time not only to teach differences to out kids but foremostly to teach what unites all of muslims as brothers and sisters. And it is nothing but " لا إلاه إﻻ الله محمد رسول الله. There is no god worthy of worship except Allah and muhammad is the messenger of Allah"
      I will ever be grateful to Shaikh Abdus Salam m to have taught evolution of fiqh to us, which removed all the doubts, and helped us to embrace all the mazahib with a new perspective without blaming anyone. Alhamdulillah.
      - Bushra Wandge, Student of Sheikh Abdus Salam`,
      imageUrl: Almuni1,
    },
    {
      id: 7,
      name: "Aspire For Correct Aqeedah",
      description: `What Is More Precious Than Learning The Correct Aqeedah!!!
      All the Muslims who live around us claim themselves to be Muslims... But do you think all of them have the correct belief... No... And that's where "AQEEDAH" comes which really defines a Muslim's core belief. It is vital that we should know the correct creed and its adherents and its sources.
      Alhamdulilah.. I learnt and understood from my Shaikh that the sources of aqeedah are the Book of Allah explained by the Prophetic sunnah and consolidated by the understanding, statement, positions and clarification of salaf;those in the first three centuries of Islam and whoever followed them thereafter in belief, methodology and statement in expressing and clarifying the belief..
      The accurate source and the right teacher (Shaikh Abdus Salam)helped me to benefit a lot in this corrupted society.
      - Sameera, Student of Sheikh Abdus Salam`,
      imageUrl: Almuni1,
    },
    {
      id: 8,
      name: "Arabic - Language For The Whole Mankind",
      description: `Amongst all the languages of the world , Allah ( azza wajal) has chosen arabic language to reveal the quran.

      Allah says In surah Fussilat, verse 3
      ﺘَﺎﺏٌ ﻓُﺼِّﻠَﺖْ ﺁﻳَﺎﺗُﻪُ ﻗُﺮْﺁﻧًﺎ ﻋَﺮَﺑِﻴًّﺎ ﻟِﻘَﻮْﻡٍ ﻳَﻌْﻠَﻤُﻮﻥَ<
      
      "A Book, whereof the verses are explained in detail;- a Qur'an in Arabic, for people who understand;-"
      This reason is sufficient for the one to learn the Arabic language.
      Arabic, rolls off many Muslim's tongue readily , regardless of whether one knows it's meaning or not by reciting in 5 daily prayer.
      Then, how nice it would be if we know the meaning of what our Lord has spoken to us.
      ‘Umar (may Allah be pleased with him) said:
      “Learn the Sunnah and learn Arabic; learn the Qur’an in Arabic for it is in Arabic”.
      I am glad that by the grace of Allah , we had a highly qualified teacher ( shaikh abdussalam ) who taught us arabic right from the basics ( like how to write arabic letters) to advanced level (of sarf).Alhamdulillah.
      - Naseeha, Student of Sheikh Abdus Salam`,
      imageUrl: Almuni1,
    },

    {
      id: 9,
      name: "Realize Your Responsibility",
      description: `Have you ever thought of the reason of your birth?? We humans know which is right and which is wrong. We are allowed to choose between right and wrong. When we are allowed to choose wrong, then why punish us??!!
      All these questions were revolving in my mind since I was young.
      In my first year, I came across the topic of Covenant to Allah in Fundamentals of Tawheed.
      Allah (swt) says in the Qur'an in Surah Ahzab, verse 72,
      “We did indeed offer the Trust to the Heavens and the Earth and the Mountains; but they refused to undertake it, being afraid thereof: but man undertook it;- He was indeed unjust and foolish."
      I was shocked and surprised to see the lines that 'We Humans hold the responsibility of the Oath given to Allah before we even exist in this world'.
      Now I saw everything falling into place. My questions were answered through Shaikh's explanation.
      Alhamdulilah by the Grace of Allah Subhanahu wa Tala that my questions were answered through the excellent teaching of Shaikh Abdus Salam. I got a deep understanding of the essence of our birth and the responsibility fulfilling my oath given to Allah by being a Muslim on the correct aqeedah. I feel Blessed to be chosen by Allah to learn under Shaikh. May Allah Bless him. Aameen.
      - Sheerin Farzana, Student of Sheikh Abdus Salam`,
      imageUrl: Almuni1,
    },
    {
      id: 10,
      name: "The Bliss Of Authentic Knowledge",
      description: `"They will brain-wash you for sure"

      "This is a 'new' methodology. Stick to what your grandparents followed"
      
      "A girl should never learn more. It will lead to fitnah"
      
      These were the comments i got from my family when i told i wanted to study under madani shaykhs.
      
      they feared i will 'change'.
      
      The words "authentic scholars, authentic knowledge, Aqeedah" was new to them, as much as it was new to me, back then.
      
      I was in the dilemma of a high school girl. I was confused what stream i should choose. I was under peer pressure to choose a secular stream. Studying the deen was never appealing to my friends.
      
      "Choose a stream with a promising career", my friends said.
      
      My cousins were all put into "not so authentic" madrasas. I was torn between the two worlds.
      
      Atlast, by the grace of Allah, (and lots of convincing words to my parents that i will never change from my 'taqleed' ideology, no matter what), alhamdulillah i found myself sitting before *shaykh Abdussalam*. I was taught Aqeedah, Arabic, and usools of other subjects. My base was being built. Slowly, but strongly.
      
      Meanwhile my cousins who got into other madrasas and were trained to pray, do collective dhikr, read quran. Lots of practice under a gong of bell and a timetable. But no therotical knowledge. No aqeedah. No basics. Just in few months people told "how much my cousins were practicing but i seldom changed".
      
      Little did they know that it did not work that way with our shaykhs. Little did they know, only a feeble mushroom sprouts in one day's rain. Whereas a banyan tree takes years to grow, grows slow and steady, and lasts many decades. Our shaykhs molded us right from the basics. They needed time to shape us. To shape us from playful girls into *strong-women with the right thoughts. Right manhaj. Right aqeedah.* They taught us more than what was in the syllabus. They molded *our attitude. Our behaviour. Our dressing.* My thirst for knowledge increased day by day, and with more knowledge came "the putting into practice" part. I realized the difference between practicing out of my own will and doing them "with a timetable and gong of a bell".
      
      Now i know why there was differences between the scholars or imaams. Now i know if *taqleed* is right or wrong. Now i know who the *salaf-us-saalih* are.
      
      I realised, lots of knowledge to a woman is *NOT A FITNAH*. I was taught the examples of *Fathima al Fihriyyah* (the founder of the first university ever in this world).The example of *Aysha r.a* who taught 70 of the sahabas and was an expert in inheritance. I was taught the delicate skill of balancing my family life with deen. The skill of *zuhd*. The skill of being in the *middle path of ahlussunnah wal jama* without going to extremes.
      
      I could sense a lot of difference between learning under authentic shaykhs and being under other madrasas. There i was frowned upon for asking a lot of questions (to which they never had the right answers).There was a lot of beating around the bush. It never felt right. But here, our madani shaykhs always answered us patiently to every dumb question we shoot them with. Infact, the more we were taught, the more we were told to contemplate and question. It was never a one way "bayaan". The classes were interactive.
      
      Each session with the shaykh turned out to be an eye opener. I was shocked how much of history has been kept hidden from us. All this *history* is never taught in other aalima courses ive seen. They think women can never handle this much knowlege. Learning about all the sects, i slowly understood what was wrong. My cloud of skepticism faded. Each jig zaw puzzle perfectly fit into eachother.Truth was apparent from falsehood.
      As i finished my 3 year islamic course,under the guidance of *shaykh Abdussalam*, iam now left with new set of questions.
        *Why dont people stick to authentic scholars? The authentic knowledge. The understanding of the sahaba. Deen, in its purest form. Unaltered. Why should people blind follow without any proofs?* Didnot Allah swt say in the quran,
      "Produce your proof, if you should be truthful" (2:111)
      I end my article with the hadeeth,
      Abud-Dardaa (radyAllaahu ‘anhu) reported: “I heard the Messenger of Allaah (sallAllaahu ‘alayhi wa sallam) say: ‘Whoever treads a path due to which he seeks knowledge, Allaah will make him tread one of the paths towards Paradise. And the angels lower their wings out of contentment for the seeker of knowledge. And verily all those in the heavens and in the earth, even the fish in the depths of the sea ask forgiveness for the scholar. And verily, the virtue of the scholar over the worshipper is like the virtue of the moon on the night of Al-Badr over all of the stars. Indeed, the scholars are the inheritors of the prophets, for the prophets do not leave behind a dinar or a dirham for inheritance, but rather, they leave behind knowledge. So whoever takes hold of it, has acquired a large share (i.e. of inheritance).’”
      (Sahih bukhari)
      With my esteemed shaykh *Abdussalam madani* starting this new college *Aspire*, i expect a lot of sisters to benefit from him, and taste the bliss of authenticity.
      May Allah swt give us all the understanding of deen. Ameen.
      - Aysha Wafira, Student of Sheikh Abdus Salam`,
      imageUrl: Almuni1,
    },
    {
      id: 11,
      name: "Do Not Compartmentalize Your Deen, It Goes In Hand With Your Dunya",
      description: `Alhamdulillah, we're such good Muslims; we pray the jumuah, we fast in Ramadan, we pray the eid-salah, we give zakah, and we haven't even kept our self aloof of the world! We do not need to study religion as such, because making a career is more important. This is the cliche I always have to face with.

      Yes, true that! You really haven't kept yourself aloof of the world, because for you, the world was aloof of the religion.
      
      The first thing Islam teaches is knowledge. In Islam knowledge comes before action.
      
      It should however be kept in mind that this knowledge is not limited to any sphere. It's incumbent upon us to seek the knowledge of our deen as well as be knowledgeable about the world.
      
      One of the most important things I learnt from my mentor is that there is no need to limit oneself to a particular kind of education. Knowledge if it's beneficial has to be sought.
      
      One can be a doctor as well as a teacher of Arabic language for the sake of Allah.
      
      One can be a muhaddith and at the same time know engineering.
      
      One can be a hafizul Quran and at the same time be an artist.
      
      One can be "Islamic" but at the same time enjoy the wonders of this world.
      
      People have created limitations in their minds with regards to following their religion. According to them if one sports a beard he cannot laugh and be humorous. If one wears hijab she cannot dress appropriate. If one follows the religious obligations with dedication then he/she cannot have fun in this world.
      
      But this is the notion of those who view Islam with incorrect perspective. We are confined only by the walls we build ourselves.
      
      Islam has not confined us with the enjoyment or luxuries of this world. It has just channelized them in a way that is best for our own good, the wisdom of which we may or we may not perceive. It's us who have to learn to deal with them in a way which leads us to our well being in this world as well as the Hereafter.
      
      Among the things I have learnt from my mentor, Shaikh Abdus Salam this is one of the most important issue. We need to understand the importance of the Islamic knowledge and at the same time know that being knowledgeable about the world is equally important.
      
      That is why early Muslims pioneered the world with their acquisition of knowledge. They built among themselves those who could serve the religion as well as the community at the same time. There arose from them scholars, doctors, artists, scientists etc, who brought to the world enlightenment of knowledge.
      
      At a time when secular studies shunned the knowledge of the religion and those who sought after religion considered learning the contemporary studies as unnecessary and irrelevant, my mentor Shaikh Abdus Salam taught me to strike a balance between the both. To know what to seek and what to not. To be able to succeed in this world as well as the Hereafter.
      
      - Nuwaira, Student of Sheikh Abdus Salam`,
      imageUrl: Almuni1,
    },
    {
      id: 11,
      name: "Work Diligently",
      description: `Assalamu alikum my dear brothers and sisters in islam.

      After three years of studying with shaikh abdus salam and being with him I have come to know this path of seeking knowledge is indeed long and one has to be highly patient to survive and conquer it with the help of Allah azza wa jal.
      
      Shaikh constantly adviced us and me personally to work hard in developing the arabic language which requires considerable time for growth in reading speaking, listening and writing. Shaikh personally approached me to study with him to which i responded without further hesitation and I studied text of abi shuja a fiqh book in arabic . This gave me the confidence to read books like three fundamental principles, aqeedah Tahawiyah, usool min ilm usool, umdat ahkaam and several other books along with videos of teachers explaining the books from jeddah.
      
      Alhamdulilah after three years Shaikh adviced me to enroll for BA scholarship from kiu riyadh. Alhamdulilah was able to clear that with help of allah azza wa jal. My development in arabic was mainly due to Shaikhs initial guidance.
      
      Those of you in india and abroad don't miss the opportunity of studying with Shaikh Abdus Salaam.
      
      - Mohamed Mahmood, Student of Sheikh Abdus Salam`,
      imageUrl: Almuni1,
    },
  ];

  // Function to truncate text to 150 characters
  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  const openModal = (alumni) => {
    setSelectedAlumni(alumni);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedAlumni(null);
    setModalOpen(false);
  };

  // Add an event listener to close the modal when clicking outside
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        modalOpen &&
        !document.getElementById("modal-content").contains(e.target)
      ) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);
    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [modalOpen]);

  return (
    <Template>
      <div className={styles.fullW}>
        <h1 className="font-bold text-2xl text-center mb-10">Alumni</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
          {alumniData.map((alumni) => (
            <div
              key={alumni.id}
              className="bg-white rounded-md shadow-lg p-4 border border-black"
            >
              <img
                src={alumni.imageUrl}
                alt={alumni.name}
                className="w-full h-40 object-cover rounded-md cursor-pointer"
                onClick={() => openModal(alumni)}
              />
              <h2 className="text-lg font-semibold mt-2">{alumni.name}</h2>
              <p className="text-sm text-gray-600">
                {truncateText(alumni.description, 200)}
              </p>
              <button
                className="mt-2 bg-blue-500 text-white py-2 px-4 rounded-full hover:bg-blue-600"
                onClick={() => openModal(alumni)}
              >
                See More
              </button>
            </div>
          ))}
        </div>

        {/* Modal */}
        {modalOpen && selectedAlumni && (
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "fixed",
              top: 0,
              left: 0,
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              zIndex: 1000,
            }}
            className="flex items-center justify-center"
          >
            <div
              style={{ maxWidth: "500px" }}
              id="modal-content"
              className="mx-auto inset-0 z-50 flex  justify-center fixed bg-black bg-opacity-50 overflow-y-auto"
            >
              <div className="bg-White p-4 rounded-md transform scale-1 transition-transform border border-black max-w-[500px] overflow-y-auto">
                <img
                  style={{ width: "500px" }}
                  src={selectedAlumni.imageUrl}
                  alt={selectedAlumni.name}
                  className="w-full max-w-300px h-40 object-cover rounded-md"
                />
                <h2 className="text-lg font-semibold mt-2">
                  {selectedAlumni.name}
                </h2>
                <div className="max-h-[600px] overflow-y-auto">
                  <p className="text-sm text-gray-600">
                    {selectedAlumni.description}
                  </p>
                </div>
                <button
                  className="mt-2 bg-blue-500 text-white py-2 px-4 rounded-full hover:bg-blue-600"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Template>
  );
};

export default AlumniPage;
