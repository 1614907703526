import { faEnvelope, faInbox, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React, { useEffect, useState } from "react";
import Button from "./Button";
import TextInput from "./TextInput";
import { useDispatch, useSelector } from "react-redux";
import { loadScript } from "../lib/loadScript";
import { generateRazorpayOrder, placeOrder } from "../redux/actions/cartAction";

const ShippingAddress = (props) => {

    const user = useSelector(state => state.Auth);
    const cart = useSelector(state => state.Cart)
    const dispatch = useDispatch()
    const [shAddress, setShAddress] = useState({
        name: user.firstName + " " + user.lastName,
        email: user.email,
        mobileNumber: user.mobileNumber,
        pincode: "",
        address: "",
        flat: "",
        landmark: "",
        city: "",
        state: ""
    })

    const updateForm = (event) => {
        setShAddress({
            ...shAddress,
            [event.target.name]: event.target.value
        })
    }
    useEffect(() => {

        const { id, entity, amount, currency, receipt, status } = cart.order
        if (id != '') {
            const options = {
                key: "rzp_test_NfWnFiQ7aNLgfT", // Enter the Key ID generated from the Dashboard
                // key: 'rzp_test_NYwloMivu9l4rD', // Enter the Key ID generated from the Dashboard
                amount: amount.toString(),
                currency: currency,
                name: "Aspire",
                description: "Aspire",
                order_id: id,
                handler: async function (response) {
                    const payment = {
                        orderCreationId: id,
                        razorpayPaymentId: response.razorpay_payment_id,
                        razorpayOrderId: response.razorpay_order_id,
                        razorpaySignature: response.razorpay_signature,
                    };
                    props.setShowAddress(false)
                    dispatch(placeOrder({
                        shippingAddress: shAddress,
                        transactionId: response.razorpay_payment_id,
                        status: 'success'
                    }))
                },
                prefill: {
                    name: shAddress.name,
                    email: shAddress.email,
                    contact: shAddress.mobileNumber,
                },
                modal: {
                    ondismiss: function () {
                       
                    }
                },
                
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open()
        }
    }, [cart.order])
    const razorpayOrder = async () => {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }
        dispatch(generateRazorpayOrder());
    }
    return (
        
        <div className="bg-GrayBg rounded-2xl w-80 md:w-1/2 p-2">
              <style>
                {`
                  .input-field {
                    font-size: 0.625rem;
                    padding: 0.25rem;
                  }
                `}
              </style>
              <div className="grid grid-cols-3">
                <div className="cursor-pointer col-span-2">
                  <img src={`${process.env.PUBLIC_URL}/images/logo-dark.png`} style={{ width: '100px' }} />
                </div>
                <div className="cursor-pointer col-span-1" onClick={() => props.setShowAddress(false)}>
                  <p className="text-right">X</p>
                </div>
              </div>
              <div className="my-2">
                <p className="text-xl font-bold font-serif my-2">Add delivery address</p>
                <div>
                  <TextInput name="name" label="Name" type="text" value={shAddress.name} onChange={(event) => updateForm(event)} className="input-field" />
                </div>
                <div>
                  <TextInput name="email" label="Email" type="text" value={shAddress.email} onChange={(event) => updateForm(event)} className="input-field" />
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <TextInput name="mobileNumber" label="Phone number" type="text" value={shAddress.mobileNumber} onChange={(event) => updateForm(event)} className="input-field" />
                  </div>
                  <div>
                    <TextInput name="pincode" label="Pincode" type="text" value={shAddress.pincode} onChange={(event) => updateForm(event)} className="input-field" />
                  </div>
                </div>
                <div>
                  <TextInput type="address" name="address" label="Address" value={shAddress.address} onChange={(event) => updateForm(event)} className="input-field" />
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <TextInput type="text" name="flat" label="Flat/Door No" value={shAddress.flat} onChange={(event) => updateForm(event)} className="input-field" />
                  </div>
                  <div>
                    <TextInput type="text" name="landmark" label="Landmark" value={shAddress.landmark} onChange={(event) => updateForm(event)} className="input-field" />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <TextInput type="text" name="city" label="City" value={shAddress.city} onChange={(event) => updateForm(event)} className="input-field" />
                  </div>
                  <div>
                    <TextInput type="text" name="state" label="State" value={shAddress.state} onChange={(event) => updateForm(event)} className="input-field" />
                  </div>
                </div>
              </div>
              <div className="flex flex-row w-full justify-center items-center my-2">
                <div className="flex justify-center items-center">
                  <Button label="Proceed to Checkout" onClick={() => { razorpayOrder() }} />
                </div>
              </div>
            </div>
          );
        }
        
      

export default ShippingAddress
