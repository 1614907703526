import Api from "../../lib/api";
import { LMS_AUTH, LMS_AUTH_FAILURE } from "./types";

export const lmsLogin = (body) => async dispatch => {
    try {
        const response = await Api.post(body, '/auth/lms/login');
        if (response.success) {
            dispatch({
                type: LMS_AUTH,
                payload: response.data
            })
        } else {
            dispatch({
                type: LMS_AUTH_FAILURE,
                payload: response.data
            })
        }
    } catch (e) {
        dispatch({
            type: LMS_AUTH_FAILURE,
            payload: e.message
        })
    }

}