import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faBook,
  faCartShopping,
  faCoffee,
  faHamburger,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

import React from "react";
import HoverButton from "./HoverButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { doLogout } from "../redux/actions/authAction";
import Button from "./Button";

const Header = (props) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.Auth);
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(doLogout());
  };
  return (
    <div className="border-b border-indigo-600 mx-auto w-screen px-4 md:px-20 py-0 md:py-0 bg-White  shadow-lg shadow-cyan-500/50 top-0">
      <div className="grid grid-cols-4 md:grid-cols-3  flex items-center justify-center ">
        <div className="cursor-pointer" onClick={() => navigate("/")}>
          <img
            src={`${process.env.PUBLIC_URL}/images/logo-dark.png`}
            className={"hidden md:block w-12 md:w-6/12"}
          />

            <div className="block md:hidden">
              <Button 
                onClick={() => {
                  window.open("https://lms.aspirecollege.edu.in/", "_blank");
                }}
                icon={faUser}
                label="Start Learning"
                
              />
              </div>
        </div>
        <div>
          <div className="flex flex-row items-center  justify-evenly hidden md:flex">
            <HoverButton
              onClick={() => {
                navigate("/courses");
              }}
              icon={faBook}
              label="Courses"
            />

            <div className=" hidden md:block">
              <HoverButton
                onClick={() => {
                  window.location.href =
                    "https://lms.aspirecollege.edu.in/products";
                }}
                icon={faCartShopping}
                label="Shop"
              />
            </div>

            <HoverButton
              showLabelOnMobile={false}
              icon={faBars}
              label="Menu"
              onClick={() => props.setShowMenu(true)}
            />
          </div>
        </div>
        {/* Mobile View starts */}
        <div className="grid md:hidden col-span-2">
          <div className="flex flex-row justify-end">
            <HoverButton
              onClick={() => {
                navigate("/courses");
              }}
              icon={faBook}
              label="Courses"
            />
            <HoverButton
              showLabelOnMobile={false}
              onClick={() => props.setShowMenu(true)}
              icon={faBars}
              label="Menu"
            />
          </div>
        </div>
        {/* Mobile View Ends */}


        <div className="hidden md:grid flex justify-end">
              <HoverButton
                onClick={() => {
                  window.open("https://lms.aspirecollege.edu.in/login", "_blank");
                }}
                icon={faUser}
                label="Start Learning"
              />
            </div>

        {/* <div className="hidden md:grid flex justify-end">
          
          {auth.accessToken == "" ? (
            <HoverButton
              onClick={() => {
                props.setShowLogin(true);
              }}
              icon={faUser}
              label="Start Learning"
            />
          ) : (
            <HoverButton
              onClick={() => {
                logout();
              }}
              icon={faUser}
              label="Logout"
            />
          )}
        </div> */}
      </div>
    </div>
  );
};

export default Header;
