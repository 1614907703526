const {  ADD_ADMISSIONS, ADD_ADMISSIONS_FAILURE } = require("../actions/types")

const initialState = {
    
    studentName:"",
    email: "",
    mobileNumber: "",
    gender:"",
    location:"",
    courseId:"",
    queries:"",
    admissionMode: "",
    admissionError: ""
}

const AdmissionReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_ADMISSIONS:
            return Object.assign({}, state, {
                
                // studentName: action.payload.studentName,
                // email: action.payload.email,
                // mobileNumber: action.payload.mobileNumber,
                // gender: action.payload.gender,
                // location: action.payload.location,
                // courseId: action.payload.courseId,
                // queries: action.payload.queries,
                // admissionMode: action.payload.admissionMode,
                
                admissionError: "",
            })
        case ADD_ADMISSIONS_FAILURE:
            return Object.assign({}, state, {
                admissionError: action.payload.error || action.payload.message,
            })
        
        default:
            return state
    }
}

export default AdmissionReducer
// import { ADD_ADMISSIONS, ADD_ADMISSIONS_FAILURE } from "../actions/types";

// const initialState = {
//   studentName: "",
//   email: "",
//   mobileNumber: "",
//   gender: "",
//   location: "",
//   courseId: "",
//   queries: "",
//   admissionMode: "",
//   admissionError: "",
// };

// const AdmissionReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case ADD_ADMISSIONS:
//       return {
//         ...state,
//         studentName: action.payload.studentName,
//         email: action.payload.email,
//         mobileNumber: action.payload.mobileNumber,
//         gender: action.payload.gender,
//         location: action.payload.location,
//         courseId: action.payload.courseId,
//         queries: action.payload.queries,
//         admissionMode: action.payload.admissionMode,
//         admissionError: "",
//       };
//     case ADD_ADMISSIONS_FAILURE:
//       return {
//         ...state,
//         admissionError: action.payload.message,
//       };
//     default:
//       return state;
//   }
// };

// export default AdmissionReducer;

