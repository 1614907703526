import React from "react";
import TestimonialCard from "./TestimonialCard";
import styles from "./styles/testimonial.module.css";
import TestiIcon from "../static/images/testi-icon.png";
import TestiIcon2 from "../static/images/mus-icon.png";

const Testimonial = () => {
  const testimonials = [
    {
      image: TestiIcon2,
      name: "Hafeel Ahmed",
      qualification: "Bachelors in Islamic Studies",
      comment: `The best place where you can gain knowledge and tarbiyyah with authentic scholars is Aspire college. Personally, I really appreciate how my Arabic teachers have always been supportive and helped me improve my grasp of the language
            `,
    },
    {
      image: TestiIcon,
      name: "Sayema Ryan",
      qualification: "Bachelors in Psychology",
      comment: `I am very much delighted and really so so happy to see my results and it has been a great and a wonderful experience knowing and learning this particular course. It was a wonderful journey! Alhamdulillah `,
    },
    {
      image: TestiIcon,
      name: "K. Shehnaz",
      qualification: "Masters in Islamic Studies",
      comment: `Knowing something beneficial and correct but with no ability to stop something wrong is not useful. Aspire strengthened my knowledge and confidence and helped me to deal with my situations in the right way
            `,
    },
    {
      image: TestiIcon,
      name: "Ayesha Banu ",
      qualification: "Bachelors in Islamic Studies",
      comment: `Alhamdullilah, a great place to learn Islam. All the teachers are very friendly and supportive and the environment is quite safe for girls. Aspire has got a good system of discipline and character building.`,
    },
    {
      image: TestiIcon,
      name: "Noor Taslima ",
      qualification: "Bachelors in Islamic Studies",
      comment: `I found myself surrounded by those who were all-time encouraging and motivating. Apart from all that, we enjoyed it in a halal way, what else do we want other than this?
            Aspire has been the best place for me and I’m sure that it’s the best one always.
            `,
    },
    {
      image: TestiIcon,
      name: "Naveriya",
      qualification: "Bachelors in Islamic Studies, 1st year",
      comment: `I am Naveriya, an Aspirant and the representative of BAIS, 1st year. Alhamdulillah, this is my second year at Aspire as I’ve done my PUC here and I would like to share how Aspire has inspired me and my classmates too.
            This journey has not only led me toward the right path but, it has also made me realize many things! It has made me ponder over my religion and triggered me to act according to our religion. This is only because of Aspire and its teachers. It has taught me to have Tawakkul (trust) in Allah Jlejg dluw. Although I have studied in an Islamic school since my childhood, I hadn’t gained this much
            Knowledge and Akhlaaq ( good character ). I thank Allah alum for having guided me to join Aspire and my family who supported me.
            Jazakallahu khairan kaseerah!
            `,
    },
  ];

  const slicedTestimonial = testimonials.slice(0, 3);
  return (
    <div className={styles.fullW} style={{ padding: "50px 0" }}>
      <div className="mb-2">
        <p className="text-2xl font-serif font-bold">Testimonial</p>
      </div>
      <div className="mb-2">
        <span className="text-title py-3">Amazing feedback we got from</span>
      </div>

      <div className="mb-2 grid grid-flow-row-dense grid-cols-3 grid-rows-1 hidden md:grid">
        {slicedTestimonial.map((data, index) => (
          <TestimonialCard
            name={data.name}
            index={index}
            image={data.image}
            qualification={data.qualification}
            comment={data.comment}
            date={data.date}
          />
        ))}
      </div>

      <div className="mb-2 flex-row  md:hidden overflow-x-auto">
        {slicedTestimonial.map((data, index) => (
          <TestimonialCard
            name={data.name}
            index={index}
            image={data.image}
            qualification={data.qualification}
            comment={data.comment}
            date={data.date}
          />
        ))}
      </div>
      {/* <div className="mb-2 flex-row flex md:hidden overflow-x-auto">
        {testimonials.map((data, index) => (
          <TestimonialCard 
            name={data.name}
            index={index}
            image={data.image}
            qualification={data.qualification}
            comment={data.comment}
            date={data.date}
          />
        ))}
      </div> */}
    </div>
  );
};

export default Testimonial;
