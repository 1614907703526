import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const ReviewCard = ({ image, name, qualification, comment, date }) => {
  return (
    <div className={`border border-Stroke rounded-3xl p-4 mr-4 my-4 flex-shrink-0 w-[auto] md:w-[auto]`}>
      <div className="flex flex-row items-center">
        <div className="mr-3">
          <img src={image} style={{ width: 64, height: 64 }} className="rounded-2xl" alt={name} />
        </div>
        <div>
          <p className="text-title font-semibold ">{name}</p>
          <p className="text-title text-xs">{qualification}</p>
        </div>
      </div>
      <div className="mt-3  p-4 rounded-md ">
        <p className="text-title text-sm font-light text-justify">{comment}</p>
      </div>
      <div className="mt-8 flex flex-row items-center">
        {/* <p className="mr-4 font-bold">4.9</p> */}
        <FontAwesomeIcon icon={faStar} />
        <FontAwesomeIcon icon={faStar} />
        <FontAwesomeIcon icon={faStar} />
        <FontAwesomeIcon icon={faStar} />
        <FontAwesomeIcon icon={faStar} />
      </div>
      <div className="mt-3 ">
        <p className="text-title text-sm font-light">{date}</p>
      </div>
    </div>
  );
};


export default ReviewCard