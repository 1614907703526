import {
  faInbox,
  faLocation,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AdmissionForm from "../components/AdmissionForm";

import { openAdmission } from "../redux/actions/admissionAction";

const Footer = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="bg-GrayBg py-16">
      <div className="container mx-auto lg:px-16 py-4 md:px-8 px-4">
        <div className="cursor-pointer">
          <img
            src={`${process.env.PUBLIC_URL}/images/logo-dark.png`}
            style={{ width: "150px" }}
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-8">
          <div className="grid grid-cols-2 md:grid-cols-3  gap-4">
            <div>
              <div
                className="cursor-pointer"
                onClick={() => navigate("/courses")}
              >
                <p className="font-bold">Courses</p>
              </div>
              <div>
                <p
                  onClick={() => navigate("/courses")}
                  className="cursor-pointer text-title text-sm font-light my-4"
                >
                  All Courses
                </p>
                <p
                  onClick={() => navigate("/courses")}
                  className="cursor-pointer text-title text-sm font-light my-4"
                >
                  On Campus
                </p>
                <p
                  onClick={() => navigate("/courses")}
                  className="cursor-pointer text-title text-sm font-light my-4"
                >
                  Online Courses
                </p>
                <p
                  onClick={() => navigate("/courses")}
                  className="cursor-pointer text-title text-sm font-light my-4"
                >
                  Diploma
                </p>
                {/* <p
                  onClick={() => navigate("/puccourse")}
                  className="cursor-pointer text-title text-sm font-light my-4"
                >
                  +2 or PUC Courses
                </p> */}
                <p
                  onClick={() => navigate("/courses")}
                  className="cursor-pointer text-title text-sm font-light my-4"
                >
                  Free Courses
                </p>
                {/* <p
                  onClick={() => navigate("/scholarship")}
                  className="cursor-pointer text-title text-sm font-light my-4"
                >
                  Scholarship
                </p> */}
              </div>

              <div className="mt-8">
  <p className="font-bold">Admission</p>
</div>

<div className="cursor-pointer">
  <p
    onClick={() => navigate("/scholarship")}
    className="text-title text-sm font-light my-4"
  >
    Scholarship
  </p>
  
  <p
    onClick={() => navigate("/admission-form")}
    className="text-title text-sm font-light my-4"
  >
    Contact us
  </p>
</div>
            </div>
            <div>
              <div>
                <p className="font-bold">About</p>
              </div>
              <div>
                
              <p
                  onClick={() => navigate("/alumni")}
                  className="cursor-pointer text-title text-sm font-light my-4"
                >
                  Alumni
                </p>
                <p
                  onClick={() => navigate("/gallery")}
                  className="cursor-pointer text-title text-sm font-light my-4"
                >
                  Gallery
                </p>
                <p
                  onClick={() => navigate("/glance")}
                  className="cursor-pointer text-title text-sm font-light my-4"
                >
                  Aspire at a Glance
                </p>
                <p
                  onClick={() => navigate("/chairman")}
                  className="cursor-pointer text-title text-sm font-light my-4"
                >
                  Aspire Chairman
                </p>
                {/* <p
                  onClick={() => navigate("/leadership")}
                  className="cursor-pointer text-title text-sm font-light my-4"
                >
                  Aspire Leadership
                </p> */}
                <p
                  onClick={() => navigate("/faculties")}
                  className="cursor-pointer text-title text-sm font-light my-4"
                >
                  Aspire Faculties
                </p>
                

                <div className="mt-8">
                  <p className="font-bold">Community</p>
                </div>
                <p
                  onClick={() => navigate("/salam-centre-franchise")}
                  className="cursor-pointer text-title text-sm font-light my-4"
                >
                  Aspire Salam Centre Franchise
                </p>
               
                <p
                  onClick={() => navigate("w-power")}
                  className="cursor-pointer text-title text-sm font-light my-4"
                >
                  W Power
                </p>
                <p
                  onClick={() => navigate("/magazines")}
                  className="cursor-pointer text-title text-sm font-light my-4"
                >
                  Magazines
                </p>
                <p
                  onClick={() =>
                    (window.location.href =
                      "https://lms.aspirecollege.edu.in/forums")
                  }
                  className="cursor-pointer text-title text-sm font-light my-4"
                >
                  Forum
                </p>
                {/* <p
                  onClick={() => (window.location.href = "#")}
                  className="cursor-pointer text-title text-sm font-light my-4"
                >
                  Crowd Funding
                </p> */}
              </div>


                
              

            </div>
            <div className="">
              <div>
                <p className="font-bold">More</p>
              </div>
              <div>
                <p
                  onClick={() => {
                    window.location.href =
                      "https://lms.aspirecollege.edu.in/products";
                  }}
                  className="cursor-pointer text-title text-sm font-light my-4"
                >
                  Shop
                </p>
                
                <p
                  onClick={() => {
                    window.location.href =
                      "https://lms.aspirecollege.edu.in/blog";
                  }}
                  className="cursor-pointer text-title text-sm font-light my-4"
                >
                  Blog
                </p>
                <p
                  onClick={() => navigate("/admission-form")}
                  className="cursor-pointer text-title text-sm font-light my-4"
                >
                  Admission
                </p>

                

              </div>
            </div>
            
           
           
          </div>
          <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-4">
            <div>
              <iframe
                className=" w-full"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.031047077122!2d80.2345949287987!3d13.033694887675857!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267ac94b60ac5%3A0x16cc48d54d598d5f!2sAspire%20College%20of%20Excellence!5e0!3m2!1sen!2sin!4v1678630645942!5m2!1sen!2sin"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
              <div className="">
                <p className="font-bold my-4">Chennai</p>
                <div className="flex flex-row items-center">
                  <FontAwesomeIcon className="mr-4" icon={faLocation} />
                  <p className="text-title text-sm font-light my-4 text-wrap text-justify">
                    No. 51, Hindi Prachara Sabha Street, T. Nagar, Chennai,
                    Tamil Nadu, India - 600017
                  </p>
                </div>
                <div className="flex flex-row items-center">
                  <FontAwesomeIcon className="mr-4" icon={faPhone} />
                  <p className="text-title text-sm font-light my-4">
                    (+91) 98418 58847, (044) 4356 1212
                  </p>
                </div>
                <div className="flex flex-row items-center">
                  <FontAwesomeIcon className="mr-4" icon={faInbox} />
                  <p className="text-title text-sm font-light my-4">
                    info@aspirecollege.edu.in
                  </p>
                </div>
              </div>
            </div>
            <div>
              <iframe
                className="w-full"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.5846093240143!2d77.61426631534776!3d12.99839921780537!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae173ab0666dc7%3A0xb3111e8fab29c332!2sAspire%20College%20Of%20Excellence%20Bangalore!5e0!3m2!1sen!2sin!4v1678630815974!5m2!1sen!2sin"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
              <div className="">
                <p className="font-bold my-4">Bangalore</p>
                <div className="flex flex-row items-center">
                  <FontAwesomeIcon className="mr-4" icon={faLocation} />
                  <p className="text-title text-sm font-light my-4 text-wrap text-justify">
                    No. 52, Stephens Rd, Pulikeshi Nagar, Bengaluru, Karnataka
                    560005
                  </p>
                </div>
                <div className="flex flex-row items-center">
                  <FontAwesomeIcon className="mr-4" icon={faPhone} />
                  <p className="text-title text-sm font-light my-4">
                    (+91) 91085 98001, (080) 4865 9198
                  </p>
                </div>
                <div className="flex flex-row items-center">
                  <FontAwesomeIcon className="mr-4" icon={faInbox} />
                  <p className="text-title text-sm font-light my-4">
                    info@aspirecollege.edu.in
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="flex flex-row justify-start md:justify-end">
            <img
              className="mr-4"
              src={`${process.env.PUBLIC_URL}/images/facebook.svg`}
              width={42}
              height={42}
            />
            <img
              className="mr-4"
              src={`${process.env.PUBLIC_URL}/images/instagram.svg`}
              width={42}
              height={42}
            />
            <img
              className="mr-4"
              src={`${process.env.PUBLIC_URL}/images/youtube.svg`}
              width={42}
              height={46}
            />
            <img
              className="mr-4"
              src={`${process.env.PUBLIC_URL}/images/linkedin.svg`}
              width={42}
              height={42}
            />
          </div>
        </div>
        <br />
        <br />
        <p className="text-center text-xs text-lightText">
          © 2023 Aspire College of Excellence
        </p>
      </div>
    </div>
  );
};

export default Footer;
