import React from "react";
import Events from "../components/Events";
import Template from "../components/Template";

const AllEvents = () => {
    return(
       <Template>
        <div className='py-16 md:pt-10'>
         <Events />
         </div>
         
       </Template>
    )
}

export default AllEvents;