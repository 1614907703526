import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import Button from "./Button";
import { useDispatch, useSelector } from "react-redux";
import { removeFromCart, updateCart, viewCart, clearCart } from "../redux/actions/cartAction";

const Cart = (props) => {
    const dispatch = useDispatch()
    const cartItems = useSelector(state => state.Cart.items);
    const products = useSelector(state => state.Products.selectedProduct);
    console.log(cartItems)
    
    console.log(cartItems.length)
    useEffect(() => {
        dispatch(viewCart());
    }, [])
    function getTotal(accumulator, a) {
        return accumulator + (a.mrp * a.qty) ;
      }
      function getDiscount(accumulator, a) {
        return accumulator + (a.sellingPrice * a.qty);
      }
    const update = (product, operation) => {
        const qty = operation === 'add' ? product.qty + 1 : product.qty - 1
        if(qty == 0){
            dispatch(removeFromCart({cartId: product._id, productId: product.productId}))
        } else {
            dispatch(updateCart({cartId: product._id, productId: product.productId, qty}))
        }

        
    }

    
    return (
        <div className="bg-GrayBg rounded-2xl p-2" style={{ width: "600px" }}>
            <div className="grid grid-cols-4 py-4 border-b border-b-Stroke mb-4">
                <div className="cursor-pointer col-span-3 flex flex-row items-center">
                    <FontAwesomeIcon icon={faCartPlus} className={"text-AspireBrandBlue"} />
                    <p className="pl-4 text-AspireBrandBlue">Shopping Cart</p>
                </div>
                <div className="cursor-pointer col-span-1" onClick={() => props.setShowCart(false)}>
                    <p className="text-right teext-Stroke">{"Close >"}</p>
                </div>
            </div>
            {cartItems.length > 0 ? cartItems.map((product, index) => (
                <div className="px-4 border-b border-b-Stroke mb-4">

                    <div className="flex flex-row gap-4 mb-4" >
                        <div >
                            <img className="rounded-2xl" style={{ width: 106, height: 106, flexShrink: 0 }} src={product.image} />
                        </div>
                        <div className="flex flex-col justify-between">

                            <>
                                <div>
                                    <p className="text-lg font-bold">{products.productName}</p>
                                    <p className="text-sm break-words">{products.productDescription.trim().split(/\s+/).slice(0, 12).join(' ')}...</p>
                                </div>
                                <div className="flex flex-row items-center justify-between">

                                    <div className="flex flex-row item-center mt-2">
                                        <p className="text-base mr-2">₹{product.sellingPrice}</p>
                                        <p className="text-sm text-lightText line-through mr-2">₹{product.mrp}</p>
                                        <p className="text-sm text-AspireBrandBlue mr-2">(20% OFF)</p>
                                    </div>
                                    <div>
                                <div className="flex flex-row justify-center items-center">
                                    <div className="rounded-l-2xl bg-AspireBrandBlue px-4 cursor-pointer" onClick={() => update(product, 'sub')}><p className="text-White"> - </p></div>
                                    <div className="border-t-1 border-b-1 border-b-AspireBrandBlue border-t-AspireBrandBlue"> <p className="px-4 "> {product.qty} </p></div>
                                    <div className="rounded-r-2xl bg-AspireBrandBlue px-4 cursor-pointer" onClick={() => {(update(product, 'add'))}}> <p className="text-White"> + </p></div>
                                </div>
                            </div>
                                </div>
                            </>

                        </div>
                    </div>

                </div>
            )) : (<div><p className="text-xl text-center my-20">Cart is Empty</p></div>)}


            {cartItems.length > 0 && (
                <div className="">
                    <div className="text-2xl border-b-Stroke border-b pb-4">
                        <p>PRICE DETAILS</p>
                    </div>
                    <div className="flex flex-row justify-between items-center py-4">
                        <p className="text-sm">Price ({cartItems.length} items)</p>
                        <p>₹{cartItems.reduce(getTotal,0)}</p>
                    </div>
                    <div className="flex flex-row justify-between items-center pb-4">
                        <p className="text-sm">Discount</p>
                        <p className="text-sm">-₹{cartItems.reduce(getTotal,0) - cartItems.reduce(getDiscount,0)}</p>
                    </div>
                    <div className="flex flex-row justify-between items-center pb-4">
                        <p className="text-sm">Delivery Charges</p>
                        <p className="text-sm">₹0</p>
                    </div>
                    <div className="flex flex-row justify-between items-center pb-4 border-b-Stroke border-b border-b-Stroke border-b">
                        <p className="text-base">Total amount</p>
                        <p className="text-base">₹{cartItems.reduce(getDiscount,0)}</p>
                    </div>
                    <div>
                        <p className="text-sm text-AspireBrandBlue">You will save ₹{cartItems.reduce(getTotal,0) - cartItems.reduce(getDiscount,0)} on this order</p>
                    </div>
                    <div className="flex flex-row justify-center items-center my-4">
                        <Button label="ADD ADDRESS" onClick={() => { props.setShowCart(false); props.setShowAddress(true) }} />
                    </div>
                </div>
            )}

        </div>
    )
}

export default Cart