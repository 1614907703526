import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Cart from "../components/Cart";
import ProductCard from "../components/ProductCard";
import ShippingAddress from "../components/ShippingAddress";
import Template from "../components/Template";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../redux/actions/productsAction";

const Shop = () => {
    const [active, setActive] = useState('All')
    const [showCart, setShowCart] = useState(false)
    const [showAddress, setShowAddress] = useState(false)
    const products = useSelector(state => state.Products.products)
    const cartItems = useSelector(state => state.Cart.items);
    const cartItemCount = cartItems.length
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getProducts())
    }, [])
    return (
        <Template>
            <div onClick={() => { setShowCart(true) }} className='flex fixed justify-between items-center bg-AspireBrandBlue rounded-l-2xl py-3 px-4  cursor-pointer  right-0 top-[200px] z-[100]'>
                <FontAwesomeIcon icon={faCartShopping} className="text-White" />
                {cartItemCount > 0 && (
    <span className="absolute -top-2 -right-2  rounded-full px-2 py-1 text-White text-4xl">
      {cartItemCount}
    </span>
  )}<p className="text-sm text-White px-2">Cart</p>
            </div>
            <div className='px-5 md:px-72 py-16 md:pt-36'>
                <div className="flex flex-row justify-center item-center">
                    <div className={`${active === 'All' ? 'bg-Black' : 'bg-White'} px-2 py-2 br rounded-3xl`} onClick={() => setActive('All')}>
                        <p className={`${active === 'All' ? 'text-White' : 'text-Black'}`}> All Products</p>
                    </div>
                    <div className={`${active === 'Books' ? 'bg-Black' : 'bg-White'} px-2 py-2 br rounded-3xl`} onClick={() => setActive('Books')}>
                        <p className={`${active === 'Books' ? 'text-White' : 'text-Black'}`}> Books</p>
                    </div>
                    <div className={`${active === 'Others' ? 'bg-Black' : 'bg-White'} px-2 py-2 br rounded-3xl`} onClick={() => setActive('Others')}>
                        <p className={`${active === 'Others' ? 'text-White' : 'text-Black'}`}> Others</p>
                    </div>
                </div>
                <div className="mt-8">
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                        {products.map(product => (
                            <ProductCard product={product} />
                        ))}
                    </div>
                </div>
            </div>
            <div className={`fixed ${showCart ? "" : "hidden"} inset-0 bg-Black bg-opacity-50 overflow-y-auto h-full w-full`}>
                <div className='flex justify-center my-24 md:my-28'>
                    <Cart setShowCart={setShowCart} setShowAddress={setShowAddress} />
                </div>
            </div>
            <div className={`fixed ${showAddress ? "" : "hidden"} inset-0 bg-Black bg-opacity-50 overflow-y-auto h-full w-full`}>
                <div className='flex justify-center my-24 md:my-28'>
                    <ShippingAddress setShowAddress={setShowAddress} />
                </div>
            </div>
        </Template>
    )
}

export default Shop;
