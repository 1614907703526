import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const HoverButton = (props) => {
    const { icon, onClick, label, showLabelOnMobile = true } = props
    return (
        <div className="flex flex-row  items-center hover:bg-AspireBrandBlue rounded-3xl py-3 px-4 justify-center hover:text-White cursor-pointer w-fit" onClick={() => onClick()}>
            <FontAwesomeIcon icon={icon} className="mr-1 md:mr-2 hover:text-White" size="text-base" />
            <p className={`text-base hover:text-White ${showLabelOnMobile ? 'block' : 'hidden'} md:block`}>{label}</p>
        </div>
    )
}

export default HoverButton