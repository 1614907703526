import React from "react";


const TextInput = (props) => {
    const {label, name, type='text', onChange, value, lms=false } = props;

    return(
        <div className="my-2">
            <p className="text-sm my-4 px-4 font-bold">{label}</p>
            <input value={value} onChange={onChange} type={type} name={name} className={lms ? "h-[48px] rounded-xl bg-White w-full border-2 border-GrayBg pl-4" : "h-[48px] rounded-xl bg-White w-full pl-4"} />
        </div>
    )
}

export default TextInput;