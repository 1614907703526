import React from "react";
import Button from "../components/Button";
import Template from "../components/Template";
import styles from "../components/styles/Scholarship.module.css";
import { useNavigate } from "react-router-dom";

const Scholarship = (props) => {
  window.scrollTo(0, 0);
  const navigate = useNavigate();
  return (
    <Template>
      <div className="flex justify-center item-center bg-White bg-cover">
        <div className={styles.fullW}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
            <div className=" p-3 flex justify-center md:justify-start item-center mt-8 rounded-xl">
              <img
                src={`${process.env.PUBLIC_URL}/images/scholarship.png`}
                className={"w-full h-full md:w-fullmd:h-full rounded-xl"}
              />
            </div>
            <div className="mt-8">
              <p className="font-bold text-4xl">Scholarship</p>
              <br />
              <p className="text-base text-justify">
                In our mission to educate the Ummah, Aspire has opened its doors
                to the sincere seekers of knowledge to benefit from its
                scholarship program, which is provided only after proper
                verification. We offer opportunities for deserving students to
                pursue Islamic education. Our scholarships represent faith in
                your potential and commitment to Islamic education. Utilize them
                thoughtfully to advance knowledge and strengthen the Ummah.
              </p>
              <p className="mt-8 mb-4 text-sm font-bold">
                Contact us for more details.
              </p>

              <p className="mt-8 mb-4 text-sm font-bold">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    className=""
                    label="Contact Us"
                    onClick={() => navigate("/enquiry-form")}
                    style={{
                      transition: "background-color 0.3s",
                      marginRight: "10px",
                      padding: "14px 28px",
                      fontSize: "1.5rem",
                    }}
                  />
                  <span style={{ margin: "0 10px" }}>or</span>
                  <Button
                    className=""
                    label="Apply Now"
                    onClick={() =>
                      (window.location.href =
                        "https://docs.google.com/forms/d/e/1FAIpQLSenOg1IZ1d_lmZ6mXi_Ozcxy4Pca2I4Fo4iRwrqtQ2kgU9yDQ/viewform")
                    }
                    style={{
                      transition: "background-color 0.3s",
                      marginLeft: "10px",
                      padding: "14px 28px",
                      fontSize: "1.5rem",
                    }}
                  />
                </div>
              </p>
            </div>
          </div>

          <div style={{backgroundColor:"#E0F1FF", padding:"30px", marginTop:"30px"}} >
          <div className="text-center ">
            <h1 className="text-2xl font-bold">Great Motive</h1>
            <p className="text-xl text-gray-400 italic">
              Creating A Better Future
            </p>
            <p className="text-sm text-gray-700 font-bold mt-4">
              100’s of More Such Amazing Motives given by Students Applying For
              Scholarship
            </p>
          </div>

          <div className="mt-8 grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="bg-gray-200 p-4 rounded-lg">
              <p className="text-base text-justify">
                “Firstly, I would change myself and then my family member by
                teaching them Arabic and helping them connect with Quran and
                most importantly our culture and then InshaAllah carry forward
                this light to many people as far as possible by the will of
                Allah swt.”
              </p>
            </div>
            <div className="bg-gray-200 p-4 rounded-lg">
              <p className="text-base text-justify">
                “By the grace of Allah, before this program comes to an end, I
                will set up a madrasah where I will be teaching kids from the
                range of 4-12 ages Arabic language and Quran memorization.”
              </p>
            </div>
            <div className="bg-gray-200 p-4 rounded-lg">
              <p className="text-base text-justify">
                “In sha Allah I will do dawa through out my life & teach the
                ummah what ever I learnt to please Allah. I pray Allah to help
                me and guide me to spread what ever I have learnt and in sha
                Allah I will do it with out charging any fees.”
              </p>
            </div>
          </div>
          </div>       
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div class="p-4 mt-16">
              <h1 class="text-2xl font-bold mb-4">
                Who all can Apply for this Scholarship?
              </h1>
              <ul class="list-disc pl-4">
                <li className="text-xl">Financial Situation</li>
                <li className="text-xl">Un-Married/Divorced Women</li>
                <li className="text-xl">Orphans</li>
                <li className="text-xl">
                  Father/Husband Not Ready to Spend on Islamic Education{" "}
                </li>
              </ul>
            </div>
            <div class="p-4 mt-16">
              <h1 class="text-2xl font-bold mb-4">
                Contact Us for more details...
              </h1>
              <p className="text-2xl text-GrayBg">+91 98418 58847</p>
            </div>
          </div>
        </div>
      </div>
    </Template>
  );
};

export default Scholarship;
