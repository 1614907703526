import React from "react";

const TestimonialCard = (props) => {
    const {image, name, qualification,comment,date, index} = props
    return (
        <div className={`border border-Stroke rounded-3xl p-4 mr-4 my-4 flex-shrink-0  md:w-[auto]`}>
            <div className="flex flex-row items-center">
                <div className="mr-3">
                    <img src={image} style={{width:64, height:64}} className="rounded-2xl" />
                </div>
                <div>
                    <p className="text-title">{name}</p>
                    <p className="text-title">{qualification}</p>
                </div>
            </div>
            <div className="mt-3">
                <p className="text-title text-sm font-light text-justify">{comment}</p>
            </div>
            <div className="mt-3">
                <p className="text-title text-sm font-light">{date}</p>
            </div>
        </div>
    )
}

export default TestimonialCard