import { faEnvelope, faInbox, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import TextInput from "../components/TextInput";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authenticate } from '../redux/actions/authAction'
import Button from "../components/Button";
import { lmsLogin } from "../redux/actions/lmsAction";
import { pathOr } from "ramda";

const LmsLogin = () => {
  const isMobile = window.innerWidth <= 768; // Adjust the breakpoint as per your requirement
  const navigate = useNavigate()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const auth = useSelector(state => state.LMS.personalInfo)
  const dispatch = useDispatch();
  const doAuth = () => {
    dispatch(lmsLogin({ email, password }));
  }
  console.log(auth)
  // useEffect(() => {
  //     if(pathOr("",['id'],auth)!=""){
  //       navigate('/course/progress')
  //     }
  //   },[auth.id])

  return (
    <div className="flex flex-row justify-center items-center h-screen bg-black ">
      <div className="w-4/12 mx-16 p-8 ">
        <div className="flex justify-center items-center mb-2">
          <img src={`${process.env.PUBLIC_URL}/images/logo-dark.png`} style={{ width: '150px', height: '32px' }} />
        </div>
        <div className="my-2">
          <p className="text-xl text-center font-bold font-serif ">Welcome Back</p>
          <p className="text-xs text-center font-bold font-serif ">Login to continue</p>
          {auth.authError != '' ? <p className="text-sm text-center" style={{ color: 'red' }}>{auth.authError}</p> : null}
          <div>
            <TextInput lms name="email" label="Email" onChange={(event) => setEmail(event.target.value)} />
          </div>
          <div>
            <TextInput lms name="password" label="Password" type="password" onChange={(event) => setPassword(event.target.value)} />
          </div>

          <div className="my-2" onClick={() => { }}>
            <p className="text-sm my-4 px-4 text-AspireBrandBlue cursor-pointer text-right">{"Forgot Password"}</p>
          </div>

        </div>
        <div className="flex justify-center my-8">
          <Button lms label="Sign in" onClick={() => { doAuth() }} />
        </div>
        <div className="my-8" onClick={() => { }}>
          <p className="text-xs my-2 text-center">2023 - Aspire College. All Rights Reserved. Privacy Policy</p>
        </div>
      </div>
      <div className="w-6/12 p-12">
        <div className="flex justify-center items-center h-full p-8">
          <img src="https://images.pexels.com/photos/6084279/pexels-photo-6084279.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" className="w-[75%] h-[75%]"  />
        </div>
      </div>
    </div>
  )
}

export default LmsLogin;
