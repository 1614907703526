import React from "react";
import Template from "../components/Template";
import styles from "../components/styles/Scholarship.module.css";

import vision from "../static/images/vision.jpg";
import mission from "../static/images/mission.jpg";
import ourjourney from "../static/images/our-journey.jpg";
import Emp from "../static/images/emp.jpg";
import Health from "../static/images/health.jpg";
import Events1 from "../static/images/events-1.jpg";
import Events2 from "../static/images/events-2.jpg";
import Events3 from "../static/images/events-3.jpg";
import Events4 from "../static/images/events-4.jpg";
import Events5 from "../static/images/events-5.jpg";
import Events6 from "../static/images/events-6.jpg";
import Events7 from "../static/images/events-7.jpg";

const WPower = () => {
  window.scrollTo(0, 0);
  return (
    <Template>
      <div className={styles.fullW}>
        <div className="bg-gray-200 p-4">
          <p className="font-bold text-2xl text-center">W Power</p>
          <div className="container mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="w-full p-4">
                <div className="bg-gray-200 p-4  border border-gray-300 bg-gray-200 rounded-xl">
                  <img
                    src={vision}
                    alt="Card 1"
                    className="w-full h-auto rounded-xl"
                  />
                  <h2 className="text-xl font-semibold mt-4">Mission</h2>
                  <p className="text-gray-700 mt-2">
                    Our mission is to empower women around the world by
                    providing them with a supportive online platform that
                    fosters connection, knowledge-sharing, and personal growth.
                    We are dedicated to creating a safe and inclusive space
                    where women can network, inspire one another, and access
                    valuable resources to enhance their professional and
                    personal growth, all within the guidance of Islam.
                  </p>
                </div>
              </div>
              <div className="w-full p-4">
                <div className="bg-gray-200 p-4  border border-gray-300 bg-gray-200 rounded-xl">
                  <img
                    src={mission}
                    alt="Card 2"
                    className="w-full h-auto rounded-xl"
                  />
                  <h2 className="text-xl font-semibold mt-4">Vision</h2>
                  <p className="text-gray-700 mt-2">
                    Our vision is to be a global online community for women,
                    known for promoting unity, empowerment, and positive change.
                    We aspire to create a vibrant digital space where women from
                    diverse backgrounds come together to network, collaborate,
                    and support each other's aspirations. Through our platform,
                    we aim to foster a community of confident, empowered women
                    who excel in their careers, strengthen their faith, and
                    contribute positively to society.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="grid grid-cols-1 md:grid-cols-2 gap-4"
              style={{ backgroundColor: "#E0F1FF" }}
            >
              <div className="mt-8 p-8">
                <p className="font-bold text-2xl">Our Journey</p>
                <br />
                <p className="text-base text-justify">
                  <strong>Empowerment through Education</strong>
                </p>
                <p className="text-base text-justify mt-4 mb-4">
                  We've organized numerous webinars addressing contemporary
                  issues and challenges encountered by today's women, featuring
                  experts from various fields. Our webinars encompass a wide
                  range of topics, from addressing the emotional well-being of
                  the modern woman to discussions on financial empowerment. Our
                  goal is to provide women with the essential knowledge to
                  elevate their quality of life.
                </p>
              </div>
              <div className=" p-3 flex justify-center md:justify-start item-center mt-8 rounded-xl  p-8">
                <img
                  style={{ maxWidth: "100%" }}
                  src={ourjourney}
                  className={"w-full h-full md:w-full md:h-full rounded-xl"}
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="w-full p-4">
                <div className="bg-gray-200 p-4 rounded-lg">
                  <img
                    src={Emp}
                    alt="Card 1"
                    className="w-full h-auto rounded-xl"
                  />
                  <h2 className="text-xl font-semibold mt-4">
                    Empowerment through business and entrepreneurship
                  </h2>
                  <p className="text-gray-700 mt-2">
                    We've proudly organized a WPower gala, a celebration of
                    women's entrepreneurship, where we showcased small
                    businesses led by talented female entrepreneurs and hosted
                    engaging competitions to highlight their skills.
                  </p>
                </div>
              </div>
              <div className="w-full p-4">
                <div className="bg-gray-200 p-4 rounded-lg">
                  <img
                    src={Health}
                    alt="Card 2"
                    className="w-full h-auto rounded-xl"
                  />
                  <h2 className="text-xl font-semibold mt-4">
                    Empowerment through health and wellness
                  </h2>
                  <p className="text-gray-700 mt-2">
                    Additionally, we organized an exciting badminton tournament,
                    promoting an active and healthy lifestyle among women who
                    share a passion for sports.
                  </p>
                </div>
              </div>
            </div>

            <h1 className="font-bold text-2xl text-center mt-10">
              Past Events & Activities
            </h1>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
              <div className="w-full md:w-1/4 p-4 ">
                <a
                  href="https://www.youtube.com/live/TvKucD0wwIo"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="bg-gray-200 p-4 rounded-lg ">
                    {/* Add your image source here */}
                    <img src={Events1} alt="Card 1" className="w-full h-auto" />
                    <h2 className="text-xl font-semibold mt-4">
                      Balanced Living in Islam
                    </h2>
                    <p className="text-gray-700 mt-2">
                      {/* Add your content for column 1 here */}
                    </p>
                  </div>
                </a>
              </div>
              <div className="w-full md:w-1/4 p-4">
                <a
                  href="https://youtu.be/G1iZNQXf71w"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="bg-gray-200 p-4 rounded-lg">
                    {/* Add your image source here */}
                    <img src={Events2} alt="Card 2" className="w-full h-auto" />
                    <h2 className="text-xl font-semibold mt-4">
                      Managing Stress & Achieving Work-Life Balance
                    </h2>
                    <p className="text-gray-700 mt-2">
                      {/* Add your content for column 2 here */}
                    </p>
                  </div>
                </a>
              </div>
              <div className="w-full md:w-1/4 p-4">
                <a
                  href="https://youtube.com/live/3YIXoPfKb6E"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="bg-gray-200 p-4 rounded-lg">
                    {/* Add your image source here */}
                    <img src={Events3} alt="Card 3" className="w-full h-auto" />
                    <h2 className="text-xl font-semibold mt-4">
                      Exploring Psychology Under the Lens of Islam
                    </h2>
                    <p className="text-gray-700 mt-2">
                      {/* Add your content for column 3 here */}
                    </p>
                  </div>
                </a>
              </div>
              <div className="w-full md:w-1/4 p-4">
                <a
                  href="https://youtube.com/live/JdmFfcvsjH0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="bg-gray-200 p-4 rounded-lg">
                    {/* Add your image source here */}
                    <img src={Events4} alt="Card 4" className="w-full h-auto" />
                    <h2 className="text-xl font-semibold mt-4">
                      Webinar on Diabesity
                    </h2>
                    <p className="text-gray-700 mt-2">
                      {/* Add your content for column 4 here */}
                    </p>
                  </div>
                </a>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
              <div className="w-full md:w-1/4 p-4">
                <a
                  href="https://youtube.com/live/tMtIyFsk9nk"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="bg-gray-200 p-4 rounded-lg">
                    {/* Add your image source here */}
                    <img src={Events5} alt="Card 4" className="w-full h-auto" />
                    <h2 className="text-xl font-semibold mt-4">
                      Unlocking Freedom from Mobile Phone Addiction
                    </h2>
                    <p className="text-gray-700 mt-2">
                      {/* Add your content for column 4 here */}
                    </p>
                  </div>
                </a>
              </div>

              <div className="w-full md:w-1/4 p-4">
                <a
                  href="https://youtube.com/live/JdmFfcvsjH0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="bg-gray-200 p-4 rounded-lg">
                    {/* Add your image source here */}
                    <img src={Events6} alt="Card 4" className="w-full h-auto" />
                    <h2 className="text-xl font-semibold mt-4">
                      The Art of Forgiveness
                    </h2>
                    <p className="text-gray-700 mt-2">
                      {/* Add your content for column 4 here */}
                    </p>
                  </div>
                </a>
              </div>
              <div className="w-full md:w-1/4 p-4">
                <a
                  href="https://youtube.com/live/vdc4AqfP6Hk?feature=share"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="bg-gray-200 p-4 rounded-lg">
                    {/* Add your image source here */}
                    <img src={Events7} alt="Card 4" className="w-full h-auto" />
                    <h2 className="text-xl font-semibold mt-4">
                      Happy Muslim ‘Behind the Scenes’
                    </h2>
                    <p className="text-gray-700 mt-2">
                      {/* Add your content for column 4 here */}
                    </p>
                  </div>
                </a>
              </div>
              <div className="w-full md:w-1/4 p-4">
                <div className="bg-gray-200 p-4 rounded-lg">
                  {/* Add your image source here */}
                  {/* <img src={Events7} alt="Card 4" className="w-full h-auto" /> */}
                  {/* <h2 className="text-xl font-semibold mt-4">
                    Happy Muslim ‘Behind the Scenes’
                  </h2> */}
                  <p className="text-gray-700 mt-2">
                    {/* Add your content for column 4 here */}
                  </p>
                </div>
              </div>
            </div>

            <div
              className="grid grid-cols-1 gap-4"
              style={{ backgroundColor: "#E0F1FF" }}
            >
              <div className="mt-8 p-8">
                <p className="font-bold text-2xl">Our Community</p>
                <br />
                <p className="text-base text-justify">
                  <strong>Empowerment through Education</strong>
                </p>
                <p className="text-base text-justify mt-4 mb-4">
                  Our community is a dynamic gathering of women from diverse
                  backgrounds, fostering mutual support and collective growth.
                  Within our community, you'll find women who are professionals,
                  mothers, and married, each excelling in their respective
                  fields. Our strength lies in the unity of these remarkable
                  women, who collaborate and assist each other in a multitude of
                  life's challenges
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Template>
  );
};

export default WPower;
